import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useMapContext } from '../../../context/Map';
import { IMessage } from '../../../context/Socket';
import { folderApi } from '../../../redux/api/folderApi';
import { useUpdateRegionMutation } from '../../../redux/api/regionApi';
import {
  closeModal,
  openModal,
} from '../../../redux/features/modal/modal-slice';
import {
  setRegionToEditOrDelete,
  setSelectedPolygon,
} from '../../../redux/features/region/region-slice';
import { updateMessage } from '../../../redux/features/socket/socket-slice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { IRegionUpdateRequest } from '../../../types/API/Region';
import { replaceTextInQuotes } from '../../../utils/helpers';
import Button from '../../Common/Button';
import Modal from '../../Common/Modal';
import Spacer from '../../Common/Spacer';
import Tabs from '../../Common/Tabs';
import Tab from '../../Common/Tabs/Tab';
import { ButtonWrapper, Title } from '../ModalCommon.style';
import { ModalSx } from '../SaveRegionModal/style';
import { Wrapper } from '../UploadModal/EditRegion/style';
import Details from './Details';
import Overview from './Overview';

const UpdateRegionModal = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [updateRegion, { isLoading, reset, error }] = useUpdateRegionMutation();
  const [editRegionTab, setEditRegionTab] = useState('0');
  const { selectedPolygon, regionToEditOrDelete, userTiles } = useAppSelector(
    (state) => state.regionState
  );
  const { messages } = useAppSelector((state) => state.socketState);
  const { removeMapSelection } = useMapContext();

  const originalRegion = userTiles.find(
    (el) => el.id === regionToEditOrDelete?.id
  );

  useEffect(() => {
    if (error && 'data' in error) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
      toast.error((error.data as any).message, {
        toastId: 'update-region-error',
        autoClose: 3000,
      });
      reset();
    }
  }, [error, reset]);

  const handleOnSave = useCallback(async () => {
    if (!regionToEditOrDelete || !regionToEditOrDelete.name) return;

    if (regionToEditOrDelete.name.trim().length > 26) {
      toast.error(
        t('Region name is too long', {
          toastId: 'region-name-is-too-long',
          autoClose: 3000,
        })
      );
      dispatch(
        setRegionToEditOrDelete({
          ...regionToEditOrDelete,
          name: originalRegion?.name,
        })
      );
      return;
    }

    const updatedRegionData: IRegionUpdateRequest = {
      name: regionToEditOrDelete?.name,
      properties: {
        ...regionToEditOrDelete?.properties,
      },
      summary: regionToEditOrDelete?.summary,
      folderId:
        regionToEditOrDelete?.folderId === 'empty'
          ? null
          : regionToEditOrDelete?.folderId,
    };

    const data = await updateRegion({
      id: String(regionToEditOrDelete?.id),
      body: updatedRegionData,
    });

    if (!('error' in data)) {
      toast.success(
        t('Region updated successfully', {
          toastId: 'region-updated-successfully',
          autoClose: 3000,
        })
      );

      removeMapSelection();

      reset();
      if (regionToEditOrDelete.folderId) {
        dispatch(folderApi.util.invalidateTags(['Folder', 'Folders']));
      }
      dispatch(closeModal());
      const message = messages.find(
        (el) => el.payload.region?.id === data.data.id
      );
      if (message) {
        const updatedPayload = {
          ...message.payload,
          message: replaceTextInQuotes(
            message.payload.message,
            data.data.name || ''
          ),
          region: {
            ...message.payload.region,
            name: data.data.name,
          },
        };

        dispatch(
          updateMessage({
            ...message,
            payload: updatedPayload as IMessage['payload'],
          })
        );
      }

      if (selectedPolygon?.id === data.data.id) {
        dispatch(
          setSelectedPolygon({
            ...data.data,
            omitRefetch: true,
          })
        );
      }
    }
  }, [
    regionToEditOrDelete,
    originalRegion,
    updateRegion,
    t,
    dispatch,
    removeMapSelection,
    reset,
    messages,
    selectedPolygon?.id,
  ]);

  return (
    <Modal
      modalType="editRegion"
      additionalSx={ModalSx}
      dataTestId="update-region-modal"
    >
      <Title data-test-id="update-region-modal-title">
        {t('Edit Project Details')}
      </Title>
      <Spacer size="30px 0 0" />
      <Wrapper>
        <Tabs
          color="green"
          value={editRegionTab}
          setValue={setEditRegionTab}
          centered
        >
          <Tab title={t('Overview')}>
            <Overview />
          </Tab>
          <Tab title={t('Details')}>
            <Details />
          </Tab>
        </Tabs>
      </Wrapper>
      <ButtonWrapper>
        <Button
          variant="outline-purple"
          fullWidth
          onClick={() => dispatch(openModal('deleteRegion'))}
          dataTestId="update-region-modal-delete-region-button"
          disabled={isLoading}
        >
          {t('Delete Region')}
        </Button>
        <Button
          variant="purple"
          fullWidth
          dataTestId="update-region-modal-update-button"
          disabled={!regionToEditOrDelete?.name || isLoading}
          onClick={handleOnSave}
        >
          {t('Update')}
        </Button>
      </ButtonWrapper>
    </Modal>
  );
};

export default UpdateRegionModal;
