export type GeJSONType = 'FeatureCollection' | 'Feature' | 'Geometry';

export interface IProperties {
  name: string;
  timestamp: {
    agb: number[];
    forestCover: number[];
  };
}

export interface ITile {
  type: GeJSONType;
  geometry: GeoJSON.Polygon;
  properties: IProperties;
}

export interface IBiomassRequest {
  timeStart: string;
  timeEnd: string;
  polygon: GeoJSON.FeatureCollection<GeoJSON.Polygon>;
  type?: string;
}

export interface IBiomass {
  data: number[];
  dimensions_order: string[];
  times: string[];
  'unit of measurement': string;
}

export interface ISplitPolygon {
  feature: GeoJSON.Feature<GeoJSON.Polygon>;
  omitCache: boolean;
  withStateSet?: boolean;
}

export interface IDeforestationResponse {
  data: string[];
  times: string[];
  dimension: number;
  ratio: number;
}

export interface ISplitPolygonState {
  drawnDataOnTile: GeoJSON.FeatureCollection<GeoJSON.Polygon>;
  drawnDataOutsideTile: GeoJSON.FeatureCollection<GeoJSON.Polygon>;
}

export const SQ_METER_TO_KILOMETER_RATIO = 1000000;
export const TONNE_TO_MEGATONS_RATIO = 1000000;

export enum AreaUnitType {
  squareKilometre = 'sq-km',
  squareMile = 'sq-mile',
  hectare = 'hectare',
  acre = 'acre',
}

export enum AreaUnitTypeFullName {
  'sq-km' = 'Square Kilometres',
  'sq-mile' = 'Square Miles',
  hectare = 'Hectares',
  acre = 'Acres',
}

export enum AreaWeightEnum {
  ton = 'ton',
  kg = 'kg',
}

export enum AreaWeightFullName {
  ton = 'Tonne',
  kg = 'Kilogram',
}
