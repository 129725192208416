import styled from 'styled-components';
import { COMMON_ELEMENT_WIDTH, InputVariantMap } from '../../../constants/UI';
import { InputVariant } from '../../../types/Theme';

export const InputWrapper = styled.div<{
  noMargin: boolean;
}>`
  display: flex;
  flex-direction: column;

  input[type='number'] {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      appearance: none;
      margin: 0;
    }
  }
`;

export const InputSX = (
  variant: InputVariant,
  hasError: boolean,
  hasValue: boolean,
  width?: string
) => {
  const variantStyles = { ...InputVariantMap[variant] };

  return {
    ...variantStyles,
    width: width ? width : COMMON_ELEMENT_WIDTH,
    height: '46px',
    borderRadius: 'var(--borderRadius)',
    fontSize: '15px',
    lineHeight: '18px',
    padding: `${hasValue ? '14px 36px 14px 14px' : '14px'}`,
    transition: 'var(--transition)',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: hasError ? 'var(--cyclopsRed)' : variantStyles.borderColor,
    fontWeight: 500,
    input: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
        appearance: 'none',
        margin: 0,
      },
    },
    '&:before, &:after': {
      display: 'none',
    },
  };
};

export const LabelSx = {
  fontWeight: 700,
  fontFamily: 'var(--fontFamily)',
  marginBottom: '6px',
  color: 'var(--grey)',
  fontSize: '14px',
  lineHeight: '17px',
};

export const InputRelativeContainer = styled.div<{
  width?: string;
}>`
  position: relative;
  width: ${({ width }) => (width ? width : COMMON_ELEMENT_WIDTH)};
`;

export const ValueResetter = styled.button<{
  visible?: any;
}>`
  width: 20px;
  height: 20px;
  display: flex;
  position: absolute;
  padding: 0;
  right: 16px;
  top: 13px;
  border: 0;
  background-color: transparent;
  pointer-events: ${({ visible }) => (visible ? 'all' : 'none')};
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  transition: ${({ theme }) => theme.transition};
  cursor: pointer;

  div {
    transition: ${({ theme }) => theme.transition};
  }
`;

export const ErrorMsg = styled.p`
  color: ${({ theme }) => theme.colors.cyclopsRed};
  font-size: 13px;
  margin-top: 6px;
  font-weight: 600;
`;
