import { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Logo from '../../Common/Logo';
import {
  ContentWrapper,
  PageContentWrapper,
  PolicyHeader,
  PolicyLastUpdated,
  PolicyLayoutWrapper,
  PolicyLinkItem,
  PolicyLinks,
  PolicyTitle,
} from './style';

interface Props {
  children?: ReactNode;
  title: string;
  lastUpdated: Date;
}

const PolicyLayout = ({ children, lastUpdated, title }: Props) => {
  const { t } = useTranslation();

  useEffect(() => {
    document.body.style.overflowX = 'hidden';
  }, []);

  return (
    <PolicyLayoutWrapper>
      <PageContentWrapper>
        <PolicyHeader>
          <a href="/">
            <Logo dataTestId="policy-logo" />
          </a>
          <PolicyLinks>
            <PolicyLinkItem
              data-test-id="policy-link"
              active={window.location.pathname === '/terms-of-use'}
            >
              <a href="/terms-of-use">{t('Terms of Use')}</a>
            </PolicyLinkItem>
            <PolicyLinkItem
              data-test-id="policy-link"
              active={window.location.pathname === '/privacy-policy'}
            >
              <a href="/privacy-policy">{t('Privacy policy')}</a>
            </PolicyLinkItem>
          </PolicyLinks>
        </PolicyHeader>
        <PolicyTitle data-test-id="policy-title">{title}</PolicyTitle>
        <PolicyLastUpdated data-test-id="policy-title">
          {t('Last updated')} {new Date(lastUpdated).toLocaleDateString()}
        </PolicyLastUpdated>
        <ContentWrapper>{children}</ContentWrapper>
      </PageContentWrapper>
    </PolicyLayoutWrapper>
  );
};

export default PolicyLayout;
