import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { POLYGON_MAX_SQ_KM_SIZE } from '../../../../constants/profile';
import { useDrawHook } from '../../../../hooks/useDrawHook';
import { closeModal } from '../../../../redux/features/modal/modal-slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { theme } from '../../../../theme/Theme';
import { AreaUnitType } from '../../../../types/Geo';
import { convertSqKmTo } from '../../../../utils/units';
import Button from '../../../Common/Button';
import Modal from '../../../Common/Modal';
import Spacer from '../../../Common/Spacer';
import { ButtonWrapper, Subtitle, Title } from '../../ModalCommon.style';
import { ModalSx } from '../style';

const EditRegionTooLargeShapeModal = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { user } = useAppSelector((state) => state.userState);
  const { startOverFromModal } = useDrawHook();

  const maxLimit = useMemo(() => {
    const area = convertSqKmTo(
      POLYGON_MAX_SQ_KM_SIZE[user?.role || 'free'],
      user?.settings.unit
    );
    return `${new Intl.NumberFormat('en', {
      maximumFractionDigits: 2,
    }).format(area)} ${String(user?.settings.unit.replace('sq-', ''))}${
      area > 1 && user?.settings.unit !== AreaUnitType.squareKilometre
        ? 's'
        : ''
    }${
      user?.settings.unit === AreaUnitType.squareKilometre ||
      user?.settings.unit === AreaUnitType.squareMile
        ? '\u00B2'
        : ''
    }`;
  }, [user?.role, user?.settings.unit]);

  return (
    <Modal
      modalType="editRegionTooLargeShape"
      withCloseIcon={false}
      additionalSx={ModalSx}
      disableBackdropClick
      disableEscapeKeyDown
      dataTestId="edit-region-too-large-shape-modal"
    >
      <Title data-test-id="edit-region-too-large-shape-modal-title">
        {t('Your account must be upgraded to input a larger shape')}
      </Title>
      <Subtitle data-test-id="edit-region-too-large-shape-modal-subtitle">
        {t(
          `This account only supports regions of up to {{limitSize}} in area. You may adjust your input region, or contact us at `,
          {
            limitSize: maxLimit,
          }
        )}
        <a
          style={{
            color: theme.colors.purple,
          }}
          href={`mailto:${process.env.REACT_APP_EMAIL_UPGRADE_TO as string}`}
        >
          {`${process.env.REACT_APP_EMAIL_UPGRADE_TO as string}`}
        </a>
        {t(' to discuss your requirements.')}
      </Subtitle>
      <Spacer size={'30px 0 0'} />
      <ButtonWrapper>
        <Button
          variant="text-only-purple"
          fullWidth
          onClick={() => dispatch(closeModal())}
          dataTestId="edit-region-too-large-shape-modal-button-dismiss"
          additionalSx={{
            padding: 0,
            justifyContent: 'flex-start',
            fontSize: '14px',
            height: 'auto',
          }}
        >
          {t('Dismiss')}
        </Button>
        <Button
          variant="purple"
          fullWidth
          dataTestId="edit-region-too-large-shape-modal-button-start-over"
          onClick={startOverFromModal}
        >
          {t('Start Over')}
        </Button>
      </ButtonWrapper>
    </Modal>
  );
};

export default EditRegionTooLargeShapeModal;
