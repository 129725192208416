import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Header from '../../components/Layout/Header';
import SearchBar from '../../components/Portfolio/SearchBar';
import {
  Container,
  ContentWrapper,
  Wrapper,
} from '../../components/Portfolio/style';
import PortfolioSidebar from '../../components/Sidebar/PortfolioSidebar';
import { useAppSelector } from '../../redux/hooks';

const PortfolioPage = () => {
  const { user } = useAppSelector((state) => state.userState);
  const [searchValue, setSearchValue] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    if (user && user.role === 'free') {
      navigate('/');
    }
  }, [navigate, user]);

  return (
    <Wrapper>
      <Header />
      <Container>
        <PortfolioSidebar />
        <ContentWrapper>
          <SearchBar inputValue={searchValue} setInputValue={setSearchValue} />
          <Outlet />
        </ContentWrapper>
      </Container>
    </Wrapper>
  );
};

export default PortfolioPage;
