import { MouseEvent, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useMenu } from '../../../../hooks/useMenu';
import { useSignDocumentUrlMutation } from '../../../../redux/api/regionApi';
import {
  closeModal,
  openModal,
} from '../../../../redux/features/modal/modal-slice';
import { setDocumentToEditOrDelete } from '../../../../redux/features/region/region-slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { IRegionDocument } from '../../../../types/API/Region';
import { downloadPDF } from '../../../../utils/pdf';
import {
  DropdownMenu,
  DropdownToggle,
} from '../../../Admin/PublicProjects/style';
import Icon from '../../../Common/Icon';
import { MenuItem } from '../../../SavedRegions/style';
import { EmptyParagraph, EmptyWrapper } from '../../Folder/EmptyTemplate/style';
import {
  Document,
  DocumentActions,
  DocumentList,
  FileNameAndType,
  HeaderContainer,
  TitleAndDetails,
  UploadButton,
  Wrapper,
} from './style';

const ProjectDocuments = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { selectedPolygon } = useAppSelector((state) => state.regionState);
  const { menuToShow, menuRef, handleOpenMenu, handleCloseMenu } = useMenu();

  const [getSignedUrl, { error }] = useSignDocumentUrlMutation({});

  const handleLinkClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      handleCloseMenu();
    },
    [handleCloseMenu]
  );

  const handleDownload = useCallback(
    async (e: MouseEvent<HTMLButtonElement>, document: IRegionDocument) => {
      handleLinkClick(e);
      const response = await getSignedUrl({
        regionId: String(selectedPolygon?.id),
        filename: document.name,
      });

      if (response && 'data' in response && response.data.signedUrl) {
        downloadPDF(response.data.signedUrl);
        dispatch(closeModal());
      }
    },
    [handleLinkClick, dispatch, getSignedUrl, selectedPolygon]
  );

  useEffect(() => {
    if (error && 'data' in error) {
      toast.error(t('Error downloading document'), {
        toastId: 'download-document-error',
        autoClose: 3000,
      });
    }
  }, [error, t]);

  const handleRename = useCallback(
    (e: MouseEvent<HTMLButtonElement>, document: IRegionDocument) => {
      handleLinkClick(e);
      dispatch(setDocumentToEditOrDelete(document));
      dispatch(openModal('renameDocument'));
    },
    [handleLinkClick, dispatch]
  );

  const handleDelete = useCallback(
    (e: MouseEvent<HTMLButtonElement>, document: IRegionDocument) => {
      handleLinkClick(e);
      dispatch(setDocumentToEditOrDelete(document));
      dispatch(openModal('deleteDocument'));
    },
    [handleLinkClick, dispatch]
  );

  const sortedDocuments = useMemo(
    () =>
      [...(selectedPolygon?.documents || [])].sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      ),
    [selectedPolygon?.documents]
  );

  return (
    <Wrapper>
      <HeaderContainer>
        <TitleAndDetails>
          <h2>Project Documentation</h2>
          <span>
            {selectedPolygon?.documents?.length || 0} {t('Documents')}
          </span>
        </TitleAndDetails>
        <UploadButton onClick={() => dispatch(openModal('uploadDocument'))}>
          <Icon color="silver" variant="UPLOAD" size={24} />
          {t('Upload Document')}
        </UploadButton>
      </HeaderContainer>
      {selectedPolygon?.documents?.length === 0 && (
        <EmptyWrapper>
          <EmptyParagraph>{t('No documents found')}</EmptyParagraph>
        </EmptyWrapper>
      )}
      <DocumentList>
        {sortedDocuments.map((document, index) => (
          <Document key={index}>
            <FileNameAndType>
              <h3>{document.name.split('.')[0]}</h3>
              <span>{document.type}</span>
            </FileNameAndType>
            <DocumentActions ref={menuRef}>
              <DropdownToggle onClick={(e) => handleOpenMenu(e, index)}>
                <Icon variant="THREE_DOTS_HORIZONTAL" size={24} color="white" />
              </DropdownToggle>
              <DropdownMenu
                position="right"
                active={menuToShow === index}
                style={{
                  right: '25px',
                }}
              >
                <MenuItem onClick={(e) => handleDownload(e, document)}>
                  {t('Download')}
                </MenuItem>
                <MenuItem onClick={(e) => handleRename(e, document)}>
                  {t('Rename')}
                </MenuItem>
                <MenuItem onClick={(e) => handleDelete(e, document)}>
                  {t('Delete')}
                </MenuItem>
              </DropdownMenu>
            </DocumentActions>
          </Document>
        ))}
      </DocumentList>
    </Wrapper>
  );
};

export default ProjectDocuments;
