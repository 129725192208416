import { Page, Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { CarbonAccountingData } from '../../../../context/Polygon/types';
import { AreaUnitType, AreaWeightFullName } from '../../../../types/Geo';
import {
  convertUnitToFullName,
  returnWeightByUnit,
} from '../../../../utils/units';
import CarbonChart from '../../Components/Charts/CarbonChart';
import Footer from '../../Components/Footer';
import { globalStyles } from '../../style';

interface Props {
  carbonAboveGroundBiomassData: CarbonAccountingData[];
  carbonAboveGroundBiomassDataArea: CarbonAccountingData[];
  areaUnit: AreaUnitType;
  regionName?: string;
}

const CarbonAboveGroundBiomassPage = ({
  carbonAboveGroundBiomassData,
  carbonAboveGroundBiomassDataArea,
  areaUnit,
  regionName,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Page style={globalStyles.pageWrapper}>
      <View style={globalStyles.chartsWrapper}>
        <Text
          style={{ ...globalStyles.headlineSmall, ...globalStyles.noMarginTop }}
        >
          {t('Above Ground Biomass (Data)')}
        </Text>
        <Text style={globalStyles.headlineTiny}>{t('Megatons')}</Text>
        <CarbonChart
          width={550}
          height={300}
          data={carbonAboveGroundBiomassData}
        />
        <Text style={globalStyles.headlineSmall}>
          {t('Above Ground Biomass (Data / Area)')}
        </Text>
        <Text style={globalStyles.headlineTiny}>
          {`${
            AreaWeightFullName[returnWeightByUnit(areaUnit)]
          } / ${convertUnitToFullName(areaUnit)}`}
        </Text>
        <CarbonChart
          width={550}
          height={300}
          data={carbonAboveGroundBiomassDataArea}
        />
      </View>
      <Footer regionName={regionName} />
    </Page>
  );
};

export default CarbonAboveGroundBiomassPage;
