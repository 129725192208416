import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ContinentType,
  EnumProjectType,
  IRegionPolygonProperties,
} from '../../../../../types/API/PublicProjects';
import Input from '../../../../Common/Input';
import Select, { Props as SelectProps } from '../../../../Common/Select';
import Spacer from '../../../../Common/Spacer';

export interface IErrors {
  name?: string;
  country?: string;
  continent?: string;
  projectType?: string;
}

interface Props {
  metadata: IRegionPolygonProperties;
  setMetadata: Dispatch<SetStateAction<IRegionPolygonProperties>>;
  name: string;
  setName: (value: string) => void;
  errors: IErrors;
}

export const projectTypeOptions: SelectProps['options'] = [
  {
    label: 'Select project type',
    value: 'empty',
    isPlaceholder: true,
  },
  {
    label: EnumProjectType.ARR,
    value: EnumProjectType.ARR,
  },
  {
    label: EnumProjectType.IFM,
    value: EnumProjectType.IFM,
  },
  {
    label: EnumProjectType['REDD+'],
    value: EnumProjectType['REDD+'],
  },
];

export const continentOptions: SelectProps['options'] = [
  {
    label: ContinentType.AFRICA,
    value: ContinentType.AFRICA,
  },
  {
    label: ContinentType.ANTARCTICA,
    value: ContinentType.ANTARCTICA,
  },
  {
    label: ContinentType.ASIA,
    value: ContinentType.ASIA,
  },
  {
    label: ContinentType.AUSTRALIA,
    value: ContinentType.AUSTRALIA,
  },
  {
    label: ContinentType.EUROPE,
    value: ContinentType.EUROPE,
  },
  {
    label: ContinentType.NORTH_AMERICA,
    value: ContinentType.NORTH_AMERICA,
  },
  {
    label: ContinentType.SOUTH_AMERICA,
    value: ContinentType.SOUTH_AMERICA,
  },
];

const MetadataStep = ({
  metadata,
  setMetadata,
  name,
  setName,
  errors,
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Input
        value={name}
        required
        label={String(t('Project name'))}
        name="public-project-name"
        id="public-project-name"
        onChange={setName}
        onBlur={() => {
          setName(name.trim());
        }}
        dataTestId="create-public-project-metadata-name"
        noMargin
        maxLength={50}
        error={errors.name}
        fullWidth
      />
      <Spacer size={'20px 0 0'} />
      <Select
        value={metadata.continent || ContinentType.AFRICA}
        onChange={(value: ContinentType) =>
          setMetadata({ ...metadata, continent: value })
        }
        label={String(t('Continent'))}
        options={continentOptions}
        error={errors.continent}
        fullWidth
        autoWidth={false}
        additionalPaperSx={{
          ul: {
            padding: 0,
          },
        }}
      />
      <Spacer size={'20px 0 0'} />
      <Input
        value={metadata.country || ''}
        required
        label={String(t('Country'))}
        name="public-project-country"
        id="public-project-country"
        onChange={(value) => setMetadata({ ...metadata, country: value })}
        dataTestId="create-public-project-metadata-country"
        noMargin
        maxLength={50}
        fullWidth
        error={errors.country}
        onBlur={() => {
          setMetadata({
            ...metadata,
            country: metadata.country?.trim(),
          });
        }}
      />
      <Spacer size={'20px 0 0'} />
      <Select
        value={metadata.projectType}
        onChange={(value: EnumProjectType) =>
          setMetadata({ ...metadata, projectType: value })
        }
        label={String(t('Project type'))}
        options={projectTypeOptions}
        error={errors.projectType}
        fullWidth
        autoWidth={false}
        additionalPaperSx={{
          ul: {
            padding: 0,
          },
        }}
      />
    </>
  );
};

export default MetadataStep;
