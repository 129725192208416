import { Page, Text } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { IDeforestationResponse } from '../../../../types/Geo';
import Footer from '../../Components/Footer';
import ImageGrid from '../../Components/ImageGrid/ImageGrid';
import ImageScale from '../../Components/ImageScale/ImageScale';
import { globalStyles } from '../../style';

interface Props {
  deforestationData: IDeforestationResponse;
  regionName: string;
}

const DeforestationImagesPage = ({ deforestationData, regionName }: Props) => {
  const { t } = useTranslation();

  return (
    <Page style={globalStyles.pageWrapper}>
      <Text
        style={{
          ...globalStyles.headlineBig,
          ...globalStyles.noMarginLeft,
        }}
        fixed
      >
        {t('Visualisation of Change in Forest Cover')}
      </Text>
      <Text
        style={{
          ...globalStyles.headlineTiny,
          ...globalStyles.noMarginLeft,
        }}
        fixed
      >
        {`From: ${deforestationData.times[0]}    To: ${
          deforestationData.times[deforestationData.times.length - 1]
        }`}
      </Text>
      <ImageScale />
      <ImageGrid {...deforestationData} />
      <Footer regionName={regionName} />
    </Page>
  );
};

export default DeforestationImagesPage;
