import styled from 'styled-components';
import { IconWrapper } from '../../../Common/Icon/style';

export const FoldersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 100%;
  margin-top: 32px;
`;

export const FolderUnfoldDiv = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  column-gap: 8px;

  > div ${IconWrapper} {
    transition: ${({ theme }) => theme.transition};
    transform: ${({ active }) => (active ? 'rotate(90deg)' : 'rotate(0)')};
  }
`;

export const FolderItem = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  column-gap: 8px;
  padding: 10px;
  cursor: pointer;

  span {
    font-size: 20px;
    font-weight: 500;
    transition: ${({ theme }) => theme.transition};
    color: ${({ active, theme }) =>
      active ? theme.colors.green : theme.colors.silver};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover {
    span {
      color: ${({ theme }) => theme.colors.green};
      transform: translateX(4px);
    }
  }
`;

export const FolderProjects = styled.ul<{ unfolded?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-left: 32px;
  width: 100%;
  margin-top: ${({ unfolded }) => (unfolded ? '8px' : '0')};
  margin-bottom: ${({ unfolded }) => (unfolded ? '8px' : '0')};
  list-style: none;
  transition: ${({ theme }) => theme.transition};
  opacity: ${({ unfolded }) => (unfolded ? '1' : '0')};
  height: ${({ unfolded }) => (unfolded ? 'auto' : '0')};
  transform: ${({ unfolded }) =>
    unfolded ? 'translateY(0)' : 'translateY(-100%)'};
  pointer-events: ${({ unfolded }) => (unfolded ? 'all' : 'none')};
`;

export const FolderProject = styled.li<{ active?: boolean }>`
  font-size: 16px;
  line-height: 22px;
  row-gap: 15px;
  font-weight: 500;
  color: ${({ active, theme }) =>
    active ? theme.colors.green : theme.colors.silver};
  cursor: pointer;
  transition: ${({ theme }) => theme.transition};

  &:hover {
    color: ${({ theme }) => theme.colors.green};
  }
`;
