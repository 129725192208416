import { IApiKeyResponse, IUserUpdateResponse } from '../../types/API/User';
import { IUserUpdate } from '../../types/User';
import { logout, setUser } from '../features/user/user-slice';
import { baseApi } from './index';
import { IUser } from './types';

export const userApi = baseApi
  .enhanceEndpoints({
    addTagTypes: ['IUsers'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getFromCookie: builder.query<IUser, void>({
        keepUnusedDataFor: 0,
        query() {
          return {
            url: '/user/me',
            method: 'GET',
          };
        },
        transformResponse: (response: { user: IUser }) => {
          return response.user;
        },
        // providesTags to user via id
        providesTags: (result) => [{ type: 'IUser', id: result?.id }],
        async onQueryStarted(args, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;
            dispatch(setUser(data));
          } catch (error) {
            //
          }
        },
      }),
      // handle providesTags
      getAllUsers: builder.query<
        {
          data: IUser[];
          total: number;
        },
        {
          itemsPerPage?: number;
          page?: number;
        }
      >({
        query({ itemsPerPage = 10, page = 1 }) {
          return {
            url: '/user/all',
            method: 'GET',
            params: {
              itemsPerPage,
              page,
            },
          };
        },
        transformResponse: (response: { data: IUser[]; total: number }) => {
          const transformedData = response.data.sort((a, b) => {
            const fullNameA = `${a.firstName || ''} ${a.lastName || ''}`;
            const fullNameB = `${b.firstName || ''} ${b.lastName || ''}`;
            // compare by full names
            // if full names are equal, compare by email
            return (
              fullNameA.localeCompare(fullNameB) ||
              a.email.localeCompare(b.email)
            );
          });
          return {
            data: transformedData,
            total: response.total,
          };
        },
        providesTags: ['IUsers'],
      }),
      findUsers: builder.query<IUser[], { query: string; userId: string }>({
        query({ query }) {
          return {
            url: `/user/find/${query}`,
            method: 'GET',
          };
        },
        transformResponse: (response: IUser[], _, args) => {
          // filter out current user
          return response.filter((user) => user.id !== args.userId);
        },
      }),
      getUser: builder.query<IUser, string>({
        query(id: string) {
          return {
            url: `/user/${id}/data`,
            method: 'GET',
          };
        },
        providesTags: (result) => [{ type: 'IUser', id: result?.id }],
        transformResponse: (response: { user: IUser }) => {
          return response.user;
        },
      }),
      setUserRole: builder.mutation<IUser, { id: string; role: string }>({
        query({ id, role }) {
          return {
            url: `/user/${id}/role/${role}`,
            method: 'PUT',
          };
        },
        transformResponse: (response: { user: IUser }) => {
          return response.user;
        },
        invalidatesTags: ['IUsers'],
      }),
      setUserActiveStatus: builder.mutation<
        IUser,
        {
          id: string;
          body: {
            active: boolean;
          };
        }
      >({
        query({ id, body }) {
          return {
            url: `/user/${id}/activate`,
            method: 'PUT',
            body,
          };
        },
        transformResponse: (response: { user: IUser }) => {
          return response.user;
        },
        invalidatesTags: ['IUsers'],
      }),
      generateUserKey: builder.mutation<
        IApiKeyResponse,
        {
          id: string;
        }
      >({
        query({ id }) {
          return {
            url: `/user/${id}/api-key`,
            method: 'PUT',
          };
        },
      }),
      updateUser: builder.mutation<IUserUpdateResponse, IUserUpdate>({
        query(body) {
          return {
            url: '/user',
            method: 'POST',
            body,
          };
        },
        invalidatesTags: (result) => [{ type: 'IUser', id: result?.user?.id }],
      }),
      deleteAccount: builder.mutation<{ success: boolean }, { id: string }>({
        query() {
          return {
            url: '/user',
            method: 'DELETE',
          };
        },
        invalidatesTags: (_, __, { id }) => [{ type: 'IUser', id }],
        async onQueryStarted(_, { queryFulfilled, dispatch }) {
          await queryFulfilled;
          dispatch(logout());
        },
      }),
      deleteAccountById: builder.mutation<{ success: boolean }, { id: string }>(
        {
          query({ id }) {
            return {
              url: `/user/${id}`,
              method: 'DELETE',
            };
          },
          invalidatesTags: ['IUsers'],
        }
      ),
    }),
  });

export const {
  useGetFromCookieQuery,
  useGetAllUsersQuery,
  useFindUsersQuery,
  useSetUserRoleMutation,
  useSetUserActiveStatusMutation,
  useGenerateUserKeyMutation,
  useUpdateUserMutation,
  useDeleteAccountMutation,
  useDeleteAccountByIdMutation,
} = userApi;
