import { ReactNode } from 'react';
import { useAppSelector } from '../../redux/hooks';
import MapTools from '../MapTools';
import Header from './Header';
import { Wrapper } from './style';

interface Props {
  children?: ReactNode;
  isLoading?: boolean;
}

const Layout = ({ children, isLoading }: Props) => {
  const { user } = useAppSelector((state) => state.userState);

  return (
    <Wrapper isLoading={isLoading}>
      <Header />
      {user ? <MapTools /> : null}
      {children}
    </Wrapper>
  );
};

export default Layout;
