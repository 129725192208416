import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LngLat } from 'mapbox-gl';
import { ISplitPolygonState } from '../../../types/Geo';

interface DrawState {
  drawnFeatures: GeoJSON.Feature<GeoJSON.Polygon>[];
  drawnFeatureName: string;
  drawLngLat?: LngLat | null;
  isDrawing: boolean;
  numberOfPoints?: number | null;
  splitDrawnPolygon?: ISplitPolygonState;
}

const initialState: DrawState = {
  drawnFeatures: [],
  drawnFeatureName: '',
  drawLngLat: null,
  isDrawing: false,
  numberOfPoints: null,
  splitDrawnPolygon: undefined,
};

export const drawSlice = createSlice({
  name: 'draw',
  initialState,
  reducers: {
    setDrawLngLat: (state, action: PayloadAction<LngLat | null>) => {
      state.drawLngLat = action.payload;
    },
    setIsDrawing: (state, action: PayloadAction<boolean>) => {
      state.isDrawing = action.payload;
    },
    setDrawnFeatureName: (state, action: PayloadAction<string>) => {
      state.drawnFeatureName = action.payload;
    },
    setNumberOfPoints: (state, action: PayloadAction<number | null>) => {
      state.numberOfPoints = action.payload;
    },
    setSplitDrawnPolygon: (
      state,
      action: PayloadAction<ISplitPolygonState | undefined>
    ) => {
      state.splitDrawnPolygon = action.payload;
    },
    onDrawCreate: (
      state,
      action: PayloadAction<GeoJSON.Feature<GeoJSON.Polygon>[]>
    ) => {
      state.drawnFeatures = [action.payload[0]];
    },
    onDrawUpdate: (
      state,
      action: PayloadAction<GeoJSON.Feature<GeoJSON.Polygon>[]>
    ) => {
      state.drawnFeatures = [action.payload[0]];
    },
    resetDrawSliceState: (state) => {
      state.drawnFeatures = [];
      state.drawnFeatureName = '';
      state.drawLngLat = null;
      state.isDrawing = false;
      state.numberOfPoints = null;
      state.splitDrawnPolygon = undefined;
    },
  },
});

export default drawSlice.reducer;
export const {
  setDrawLngLat,
  setIsDrawing,
  setDrawnFeatureName,
  setNumberOfPoints,
  setSplitDrawnPolygon,
  onDrawCreate,
  onDrawUpdate,
  resetDrawSliceState,
} = drawSlice.actions;
