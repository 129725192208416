import Badge from '@mui/material/Badge';
import { styled as muiStyled } from '@mui/material/styles';
import styled from 'styled-components';

export const PaperSX = (version: 'normal' | 'admin') => ({
  overflow: 'visible',
  width: version === 'normal' ? 350 : 140,
  backgroundColor: 'rgba(18, 22, 36, 0.80)',
  marginTop: '10px',
  backdropFilter: 'blur(5px)',
  transform:
    version === 'normal'
      ? 'translateX(110px) !important'
      : 'translateX(10px) !important',

  ul: {
    padding: '30px',
    button: {
      marginTop: '10px',
    },
    li: {
      display: 'flex',
      alignItems: 'inherit',
      justifyContent: 'inherit',
      cursor: 'pointer',

      '&:hover': {
        opacity: 0.6,
      },
    },
    a: {
      '&:hover': {
        opacity: 0.6,
      },
    },
  },
  borderRadius: '10px',
});

export const proCss = {
  backgroundColor: 'var(--purple)',
};

export const adminCss = {
  backgroundColor: 'var(--purple)',
};

export const freeCss = {
  backgroundColor: 'var(--green)',
};

export const commonCss = {
  color: 'var(--blackI)',
  fontSize: '16px',
  fontWeight: 700,
  width: '40px',
  height: '40px',
};

export const Wrapper = styled.div<{ isFree?: boolean }>`
  border: 1px solid ${({ theme }) => theme.colors.darkGray};
  border-radius: 100px;
  padding: 5px 5px 5px 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  button {
    padding: 0;

    &:hover {
      background-color: transparent;
    }
  }

  span p {
    color: ${({ theme, isFree }) =>
      isFree ? theme.colors.green : theme.colors.purple};
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin: auto 10px auto 0;
  }
`;

export const StyledBadge = muiStyled(Badge)(() => ({
  '& .MuiBadge-badge': {
    backgroundColor: 'var(--purple)',
    color: 'var(--purple)',
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

export const AvatarSX = (role: string) => {
  switch (role) {
    case 'free': {
      return {
        ...commonCss,
        ...freeCss,
      };
    }
    case 'pro': {
      return {
        ...commonCss,
        ...proCss,
      };
    }

    case 'admin': {
      return {
        ...commonCss,
        ...adminCss,
      };
    }
    default: {
      return {
        ...commonCss,
        ...freeCss,
      };
    }
  }
};

export const UserInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const UserAvatar = styled.div<{ isFree?: boolean }>`
  width: 60px;
  height: 60px;
  background-color: ${({ theme, isFree }) =>
    isFree ? theme.colors.green : theme.colors.purple};
  color: ${({ theme }) => theme.colors.blackI};
  font-size: 20px;
  font-weight: 700;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  text-transform: uppercase;
`;

export const UserName = styled.p`
  font-size: 20px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.silver};
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

export const UserRole = styled.p<{ isFree?: boolean }>`
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme, isFree }) =>
    isFree ? theme.colors.green : theme.colors.purple};
  margin-bottom: 30px;
  letter-spacing: 1.4px;
  text-transform: uppercase;
`;

export const MenuItemSx = {
  color: 'var(--white) ',
  textAlign: 'center',
  transition: 'var(--transition)',
  fontSize: '16px',
  lineHeight: '26px',
  padding: 0,
  position: 'relative',
  marginBottom: '20px',

  '&:hover': {
    backgroundColor: 'transparent',
    color: 'var(--white)',
  },
};
