import { Text, View } from '@react-pdf/renderer';
import { styles } from './ImageScale.styles';

const ColorMapPalette = [
  'rgb(253, 231, 37)',
  'rgb(248, 230, 33)',
  'rgb(241, 229, 29)',
  'rgb(236, 229, 27)',
  'rgb(229, 228, 25)',
  'rgb(223, 227, 24)',
  'rgb(216, 226, 25)',
  'rgb(208, 225, 28)',
  'rgb(202, 225, 31)',
  'rgb(194, 223, 35)',
  'rgb(189, 223, 38)',
  'rgb(181, 222, 43)',
  'rgb(173, 220, 48)',
  'rgb(168, 219, 52)',
  'rgb(160, 218, 57)',
  'rgb(155, 217, 60)',
  'rgb(147, 215, 65)',
  'rgb(142, 214, 69)',
  'rgb(134, 213, 73)',
  'rgb(127, 211, 78)',
  'rgb(122, 209, 81)',
  'rgb(115, 208, 86)',
  'rgb(110, 206, 88)',
  'rgb(103, 204, 92)',
  'rgb(96, 202, 96)',
  'rgb(92, 200, 99)',
  'rgb(86, 198, 103)',
  'rgb(82, 197, 105)',
  'rgb(76, 194, 108)',
  'rgb(72, 193, 110)',
  'rgb(66, 190, 113)',
  'rgb(61, 188, 116)',
  'rgb(58, 186, 118)',
  'rgb(53, 183, 121)',
  'rgb(50, 182, 122)',
  'rgb(46, 179, 124)',
  'rgb(42, 176, 127)',
  'rgb(40, 174, 128)',
  'rgb(37, 172, 130)',
  'rgb(36, 170, 131)',
  'rgb(34, 167, 133)',
  'rgb(32, 164, 134)',
  'rgb(31, 162, 135)',
  'rgb(31, 160, 136)',
  'rgb(31, 158, 137)',
  'rgb(30, 155, 138)',
  'rgb(31, 153, 138)',
  'rgb(31, 150, 139)',
  'rgb(32, 147, 140)',
  'rgb(32, 146, 140)',
  'rgb(33, 143, 141)',
  'rgb(34, 141, 141)',
  'rgb(35, 138, 141)',
  'rgb(36, 135, 142)',
  'rgb(37, 133, 142)',
  'rgb(38, 130, 142)',
  'rgb(38, 129, 142)',
  'rgb(39, 126, 142)',
  'rgb(40, 124, 142)',
  'rgb(41, 121, 142)',
  'rgb(42, 118, 142)',
  'rgb(43, 116, 142)',
  'rgb(44, 113, 142)',
  'rgb(45, 112, 142)',
  'rgb(46, 109, 142)',
  'rgb(48, 106, 142)',
  'rgb(49, 104, 142)',
  'rgb(50, 101, 142)',
  'rgb(51, 99, 141)',
  'rgb(52, 96, 141)',
  'rgb(54, 93, 141)',
  'rgb(55, 91, 141)',
  'rgb(56, 88, 140)',
  'rgb(57, 85, 140)',
  'rgb(59, 82, 139)',
  'rgb(60, 80, 139)',
  'rgb(61, 77, 138)',
  'rgb(62, 73, 137)',
  'rgb(63, 71, 136)',
  'rgb(65, 68, 135)',
  'rgb(66, 65, 134)',
  'rgb(67, 62, 133)',
  'rgb(68, 58, 131)',
  'rgb(69, 56, 130)',
  'rgb(70, 52, 128)',
  'rgb(70, 50, 126)',
  'rgb(71, 46, 124)',
  'rgb(71, 44, 122)',
  'rgb(72, 40, 120)',
  'rgb(72, 36, 117)',
  'rgb(72, 33, 115)',
  'rgb(72, 29, 111)',
  'rgb(72, 27, 109)',
  'rgb(72, 23, 105)',
  'rgb(71, 19, 101)',
  'rgb(71, 16, 99)',
  'rgb(70, 11, 94)',
  'rgb(70, 8, 92)',
  'rgb(69, 4, 87)',
  'rgb(68, 1, 84)',
];

const ImageScale = () => (
  <View style={styles.container} fixed>
    <View style={styles.colors}>
      {ColorMapPalette.map((color, index) => (
        <View
          key={`deforestation-image-scale-color-${index}`}
          style={{
            ...styles.number,
            backgroundColor: color,
          }}
        />
      ))}
    </View>
    <View style={styles.numbers}>
      {ColorMapPalette.map((_, index) =>
        index % 10 === 0 ? (
          <Text
            style={styles.numbersText}
            key={`deforestation-image-scale-text-${index}`}
          >
            {index < 10 ? `0${index}` : index}
          </Text>
        ) : null
      )}
      <Text
        style={styles.numbersText}
        key={`deforestation-image-scale-text-100`}
      >
        100
      </Text>
    </View>
  </View>
);

export default ImageScale;
