import { MouseEvent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { openModal } from '../../../redux/features/modal/modal-slice';
import { setRegionToEditOrDelete } from '../../../redux/features/region/region-slice';
import { toggleDeforestationComponent } from '../../../redux/features/ui/ui-slice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import Button from '../../Common/Button';
import Skeleton from '../../Common/Skeleton';
import { ButtonWrapper } from './style';

const DataBoardFooter = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    selectedPolygon,
    deforestationLoading,
    carbonLoading,
    forestCoverLoading,
    forestCover,
    carbonAccounting,
  } = useAppSelector((state) => state.regionState);
  const { showDeforestationComponent } = useAppSelector(
    (state) => state.uiState
  );

  // added check if there is data already, to prevent unnecessary content jumping
  const isDataLoading = useMemo(
    () =>
      (carbonLoading || forestCoverLoading) &&
      !forestCover &&
      !carbonAccounting,
    [carbonAccounting, carbonLoading, forestCover, forestCoverLoading]
  );

  const handleDeforestationToggle = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      dispatch(toggleDeforestationComponent());
    },
    [dispatch]
  );

  const handleSaveUpdateButtonClick = useCallback(() => {
    if (selectedPolygon?.userId) {
      dispatch(setRegionToEditOrDelete(selectedPolygon));
      dispatch(openModal('editRegion'));
    } else {
      dispatch(openModal('saveRegion'));
    }
  }, [dispatch, selectedPolygon]);

  const handleDownloadPDD = useCallback(() => {
    if (
      selectedPolygon &&
      selectedPolygon.properties &&
      selectedPolygon.properties.pdfUrl
    ) {
      window.open(String(selectedPolygon.properties.pdfUrl), '_blank');
    }
  }, [selectedPolygon]);

  return (
    <ButtonWrapper>
      {isDataLoading || deforestationLoading ? (
        <Skeleton
          width={200}
          height={46}
          dataTestId="data-board-skeleton-deforestation"
        />
      ) : (
        <Button
          disabled={isDataLoading || deforestationLoading}
          fullWidth
          dataTestId="data-board-deforestation-button"
          variant={'outline-green'}
          onClick={handleDeforestationToggle}
        >
          {showDeforestationComponent ? t('Hide Heatmap') : t('View Heatmap')}
        </Button>
      )}
      {isDataLoading ? (
        <Skeleton
          width={210}
          height={46}
          dataTestId="data-board-skeleton-save-update"
        />
      ) : !selectedPolygon?.properties?.pdfUrl ? (
        <Button
          disabled={isDataLoading}
          fullWidth
          variant="green"
          dataTestId="data-board-save-update-region-button"
          onClick={handleSaveUpdateButtonClick}
        >
          {selectedPolygon?.userId ? t('Update Region') : t('Save Region')}
        </Button>
      ) : (
        <Button
          disabled={isDataLoading}
          fullWidth
          dataTestId="data-board-download-pdd-button"
          variant={'green'}
          onClick={handleDownloadPDD}
          iconName={'DOWNLOAD'}
          iconSize={24}
          iconPosition={'start'}
          iconColor={'gray'}
        >
          {t('Download PDD')}
        </Button>
      )}
    </ButtonWrapper>
  );
};

export default DataBoardFooter;
