import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { VALIDATION_ERRORS } from '../../../../constants/validation';
import { useUpdateUserMutation } from '../../../../redux/api/userApi';
import { openModal } from '../../../../redux/features/modal/modal-slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { IUserUpdate } from '../../../../types/User';
import { validateEmail } from '../../../../utils/validation';
import Button from '../../../Common/Button';
import Input from '../../../Common/Input';
import PhoneInputComponent from '../../../Common/PhoneInput';
import Spacer from '../../../Common/Spacer';
import { InputGroup } from '../../../Layout/AuthLayout/style';
import ChangePasswordModal from '../../ChangePasswordModal';
import EmailChangeModal from '../../EmailChangeModal';
import { AccountFooterWrapper, AccountSectionTitle } from '../style';
import {
  AccountUpdateFormWrapper,
  ButtonSeparator,
  ButtonsWrapper,
} from './style';

const AccountDetails = () => {
  const dispatch = useAppDispatch();

  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const { t } = useTranslation();
  const [isEmailChanged, setIsEmailChanged] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [telephone, setTelephone] = useState('');
  const [company, setCompany] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const { user } = useAppSelector((state) => state.userState);

  const [updateUser, { isLoading, isSuccess, error, reset }] =
    useUpdateUserMutation();

  const handleSetFirstName = (value: string) => {
    setFirstName(value);
  };

  const handleSetLastName = (value: string) => {
    setLastName(value);
  };

  const handleSetTelephone = (value: string) => {
    setTelephone(value);
  };

  const handleSetCompany = (value: string) => {
    setCompany(value);
  };

  const handleSetEmail = (value: string) => {
    if (value.length > 0 && !validateEmail(value)) {
      setEmailError(VALIDATION_ERRORS(t).EMAIL);
    } else {
      setEmailError('');
    }

    setEmail(value);
  };

  const updateAccountDetails = useCallback(async () => {
    const updatedUser: IUserUpdate = {
      firstName,
      lastName,
      telephone,
      company,
    };

    // check if any user details have changed, if not, don't update
    if (
      user?.firstName !== firstName ||
      user?.lastName !== lastName ||
      user?.telephone !== telephone ||
      user?.company !== company
    ) {
      if (user?.email !== email) {
        updatedUser.email = email;
      }

      const data = await updateUser(updatedUser);

      if ('error' in data) {
        setShowEmailModal(false);
      }

      if ('data' in data && showEmailModal) {
        setIsEmailChanged(true);
      }
    }
  }, [
    firstName,
    lastName,
    telephone,
    company,
    user?.firstName,
    user?.lastName,
    user?.telephone,
    user?.company,
    user?.email,
    email,
    updateUser,
    showEmailModal,
  ]);

  const handleOnSubmit = useCallback(
    async (e: SyntheticEvent) => {
      e.preventDefault();

      if (user?.email !== email) {
        setShowEmailModal(true);
      } else {
        await updateAccountDetails();
      }
    },
    [updateAccountDetails, user, email]
  );

  useEffect(() => {
    if (user) {
      setFirstName(user.firstName || '');
      setLastName(user.lastName || '');
      setTelephone(user.telephone || '');
      setCompany(user.company || '');
      setEmail(user.email);
    }
  }, [user]);

  useEffect(() => {
    if (isSuccess) {
      toast.success(t('Account details updated'), {
        autoClose: 3000,
        toastId: 'account-details-updated',
      });
      reset();
    }
  }, [reset, isSuccess, t]);

  useEffect(() => {
    if (error && 'data' in error) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
      toast.error((error.data as any).message, {
        toastId: 'account-details-error',
        autoClose: 3000,
      });
      reset();
    }
  }, [error, reset]);

  return (
    <>
      <AccountUpdateFormWrapper>
        <AccountSectionTitle>{t('Details')}</AccountSectionTitle>
        <form onSubmit={handleOnSubmit}>
          <InputGroup>
            <Input
              value={firstName}
              onChange={handleSetFirstName}
              label={String(t('First name'))}
              maxLength={50}
              dataTestId="account-update-form-first-name"
              width="190px"
            />
            <Input
              value={lastName}
              onChange={handleSetLastName}
              maxLength={50}
              label={String(t('Last name'))}
              dataTestId="account-update-form-last-name"
              width="190px"
            />
          </InputGroup>
          <InputGroup>
            <PhoneInputComponent
              value={telephone}
              onChange={handleSetTelephone}
              label={String(t('Telephone'))}
              dataTestId="account-update-form-telephone"
              maxLength={15}
              width="190px"
            />
            <Input
              value={company}
              onChange={handleSetCompany}
              label={String(t('Company'))}
              maxLength={50}
              dataTestId="account-update-form-company"
              width="190px"
            />
          </InputGroup>
          <Input
            value={email}
            onChange={handleSetEmail}
            type="email"
            label={String(t('Email address'))}
            error={emailError}
            required
            maxLength={50}
            dataTestId="account-update-form-email"
          />
          <Spacer size={'20px 0 0'} />
          <Input
            value="****************"
            type="password"
            toggle={false}
            onChange={() => {}}
            readOnly
            label={String(t('Password'))}
            dataTestId="account-update-form-password"
          />
          <ButtonsWrapper>
            <Button
              disabled={isLoading}
              fullWidth
              variant="text-only-purple"
              onClick={() => setShowPasswordModal(true)}
              dataTestId="account-update-form-change-password-button"
              additionalSx={{
                padding: 0,
                height: 'auto',
                fontSize: '14px',
              }}
            >
              {t('Update password')}
            </Button>
            <ButtonSeparator />
            <Button
              disabled={isLoading}
              fullWidth
              variant="text-only-purple"
              onClick={() => dispatch(openModal('deleteAccount'))}
              dataTestId="account-update-form-remove-account-button"
              additionalSx={{
                padding: 0,
                height: 'auto',
                fontSize: '14px',
              }}
            >
              {t('Delete account')}
            </Button>
          </ButtonsWrapper>
          <EmailChangeModal
            loading={isLoading}
            isEmailChanged={isEmailChanged}
            open={showEmailModal}
            onClose={() => {
              setShowEmailModal(false);
              if (user?.email) setEmail(user?.email);
            }}
            onSubmit={updateAccountDetails}
          />
          <ChangePasswordModal
            open={showPasswordModal}
            onClose={() => setShowPasswordModal(false)}
          />
        </form>
      </AccountUpdateFormWrapper>
      <AccountFooterWrapper>
        <Button
          disabled={isLoading}
          fullWidth
          variant="purple"
          onClick={handleOnSubmit}
          dataTestId="account-update-form-submit-button"
          additionalSx={{
            width: '190px',
            marginLeft: 'auto',
          }}
        >
          {t('Save Changes')}
        </Button>
      </AccountFooterWrapper>
    </>
  );
};

export default AccountDetails;
