import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  coverWrapper: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '50px',
  },
  coverTitle: {
    fontSize: '30px',
    fontFamily: 'Manrope',
    fontWeight: 800,
  },
  coverDescription: {
    marginTop: '15px',
    fontFamily: 'Manrope',
    color: '#565B69',
    fontSize: '14px',
  },
});
