import Button from '../Button';
import Icon, { IconVariant } from '../Icon';
import {
  BannerType,
  ContentWrapper,
  InnerWrapper,
  Subtitle,
  Title,
  Wrapper,
} from './style';

export interface Props {
  title: string;
  message: string;
  buttonLabel: string;
  onClick: () => void;
  icon?: IconVariant;
  type?: BannerType;
  dataTestId?: string;
}

const Banner = ({
  title,
  message,
  buttonLabel,
  onClick,
  icon,
  dataTestId,
}: Props) => {
  return (
    <Wrapper data-test-id={dataTestId}>
      <InnerWrapper>
        <ContentWrapper>
          <Title data-test-id={dataTestId && `${dataTestId}-title`}>
            {icon ? (
              <Icon
                data-test-id={dataTestId && `${dataTestId}-icon`}
                variant={icon}
                color="white"
                size={24}
              />
            ) : null}
            {title}
          </Title>
          <Subtitle data-test-id={dataTestId && `${dataTestId}-subtitle`}>
            {message}
          </Subtitle>
        </ContentWrapper>
        <Button
          data-test-id={dataTestId && `${dataTestId}-button`}
          variant="outline-purple"
          onClick={onClick}
          additionalSx={{
            width: '170px',
          }}
        >
          {buttonLabel}
        </Button>
      </InnerWrapper>
    </Wrapper>
  );
};

export default Banner;
