import { ButtonVariantMap } from '../../../constants/UI';
import { ButtonVariant } from '../../../types/Theme';

export const ButtonSX = (variant: ButtonVariant) => {
  const variantStyles = { ...ButtonVariantMap[variant] };

  return {
    borderRadius: 'var(--borderRadius)',
    textAlign: 'center',
    fontSize: '16px',
    lineHeight: '21px',
    fontWeight: 700,
    height: 46,
    padding: '12.5px',
    textTransform: 'none',
    transition: 'var(--transition)',
    ...variantStyles,

    '&:disabled': {
      opacity: 0.6,
    },
  };
};
