import { SyntheticEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useForgotPasswordMutation } from '../../../redux/api/authApi';
import Button from '../../Common/Button';
import Input from '../../Common/Input';
import LinkButton from '../../Common/LinkButton';
import Spacer from '../../Common/Spacer';

interface Props {
  onSuccess: () => void;
}

const ForgotPasswordForm = ({ onSuccess }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [submit, { isLoading }] = useForgotPasswordMutation();
  const [email, setEmail] = useState('');

  const handleBackToLogin = useCallback(() => {
    navigate('/login');
  }, [navigate]);

  const handleOnSubmit = useCallback(
    async (e: SyntheticEvent) => {
      e.preventDefault();
      const data = await submit({
        email,
      });
      if (!('error' in data)) {
        onSuccess();
      }
    },
    [email, onSuccess, submit]
  );

  return (
    <form onSubmit={handleOnSubmit}>
      <Spacer size={'0 0 30px'} />
      <Input
        value={email}
        onChange={setEmail}
        type="email"
        label={String(t('Email address'))}
        dataTestId="forgot-password-form-email"
        required
        fullWidth
      />
      <Spacer size="30px 0 0" />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <LinkButton
          text={t('Back to Log In')}
          onClick={handleBackToLogin}
          dataTestId={'forgot-password-form-back-to-login'}
          additionalSx={{
            margin: 0,
          }}
        />
        <Button
          disabled={isLoading}
          fullWidth
          variant="purple"
          dataTestId="forgot-password-form-submit-btn"
          type="submit"
          additionalSx={{
            width: '190px',
          }}
        >
          {t('Send')}
        </Button>
      </div>
    </form>
  );
};

export default ForgotPasswordForm;
