import { t } from 'i18next';
import { AccountSectionTitle, APIKeyText, Link } from '../style';

const AccountSupport = () => {
  return (
    <>
      <AccountSectionTitle>{t('Contact Us')}</AccountSectionTitle>
      <APIKeyText data-test-id="account-support-subtitle">
        {t(
          "Please email our team and we'll get back to you as soon as possible."
        )}
      </APIKeyText>
      <Link
        data-test-id="account-support-link"
        href={`mailto:${process.env.REACT_APP_EMAIL_SUPPORT_PAGE as string}`}
      >
        {process.env.REACT_APP_EMAIL_SUPPORT_PAGE as string}
      </Link>
    </>
  );
};

export default AccountSupport;
