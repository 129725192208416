import styled from 'styled-components';

export const ModalSx = {
  alignItems: 'flex-start',
  justifyContent: 'space-between',
};

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: ${({ theme }) => theme.colors.grey};
    margin-bottom: 6px;
  }

  .MuiSelect-select:before {
    top: 14px !important;
  }
`;

export const Subtitle = styled.h4`
  color: ${({ theme }) => theme.colors.grey};
  margin-top: 8px;
  font-size: 16px;
  line-height: 19px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
