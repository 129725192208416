import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  disclaimerTitle: {
    fontSize: '10px',
    fontFamily: 'Manrope',
    fontWeight: 700,
    color: '#030818',
  },
  disclaimerText: {
    marginTop: '15px',
    fontSize: '10px',
    fontFamily: 'Manrope',
    color: '#030818',
    fontWeight: 400,
  },
});
