import { PaperProps, SelectProps } from '@mui/material';
import FormLabel from '@mui/material/FormLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useCallback, useMemo } from 'react';
import { PaperSX } from '../../Layout/Header/Menu/style';
import { LabelSx } from '../Input/style';
import {
  ErrorMsg,
  MenuItemSx,
  SelectRelativeContainer,
  SelectSx,
  SelectWrapper,
} from './style';

export interface Props {
  value: SelectProps['value'];
  onChange: (val: any) => void;
  id?: string;
  name?: string;
  label?: string;
  required?: boolean;
  error?: string;
  fullWidth?: boolean;
  additionalSx?: any;
  additionalPaperSx?: any;
  autoWidth?: boolean;
  optionWidth?: string;
  inheritSelectorWidth?: boolean;
  options: {
    value: string;
    label: string;
    isPlaceholder?: boolean;
  }[];
  dataTestId?: string;
  multiple?: boolean;
}

const SelectComponent = ({
  value,
  onChange,
  id,
  name,
  required = false,
  error,
  options,
  additionalSx = {},
  autoWidth = true,
  optionWidth,
  dataTestId,
  additionalPaperSx = {},
  label,
  multiple,
}: Props) => {
  const SX = useMemo(
    () => SelectSx(error && (value as string | string[]).length > 0),
    [error, value]
  );

  const handleOnChange = useCallback(
    (event: SelectChangeEvent) => {
      onChange(event.target.value);
    },
    [onChange]
  );

  return (
    <SelectWrapper>
      <FormLabel
        htmlFor={id}
        sx={LabelSx}
        data-test-id={dataTestId && `${dataTestId}-label`}
      >
        {label}
      </FormLabel>
      <SelectRelativeContainer>
        <Select
          value={value as string}
          onChange={handleOnChange}
          id={id}
          autoWidth={autoWidth}
          name={name}
          required={required}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          sx={{ ...SX, ...additionalSx }}
          data-test-id={dataTestId}
          multiple={multiple}
          MenuProps={{
            PaperProps: {
              sx: {
                ...PaperSX('normal'),
                ...(additionalPaperSx as PaperProps<any>),
                backgroundColor: 'rgba(31, 42, 52, 1)',
                width: optionWidth ? optionWidth : 'auto',
                '&:before': {
                  display: 'none',
                },
                transform: 'none',
                maxHeight: '350px',
                overflowY: 'auto',
              },
            },
          }}
        >
          {options.map((option) => (
            <MenuItem
              disabled={option.isPlaceholder}
              key={option.value}
              sx={MenuItemSx}
              value={option.value}
              data-test-id={
                dataTestId && `${dataTestId}-option-${option.value}}`
              }
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </SelectRelativeContainer>
      {error ? (
        <ErrorMsg data-test-id={dataTestId && `${dataTestId}-error`}>
          {error}
        </ErrorMsg>
      ) : null}
    </SelectWrapper>
  );
};

export default SelectComponent;
