export const ModalSx = {
  '*': {
    textAlign: 'left',
  },
  h3: {
    fontSize: '30px',
    lineHeight: '36px',
    fontWeight: 'bold',
    marginBottom: '12px',
  },
  '> p': {
    fontSize: '15px',
    lineHeight: '26px',
  },
  form: {
    margin: '20px -50px -50px',
    backgroundColor: 'var(--navy)',
    padding: '30px 30px 40px',
    '[data-test-id="contact-us-modal-button"]': {
      marginTop: '32px',
    },
  },
};
