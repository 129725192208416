import { Autocomplete, InputAdornment, Paper, TextField } from '@mui/material';
import { ChangeEvent, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetUserRegionsQuery } from '../../../redux/api/regionApi';
import { IRegionResponse } from '../../../types/API/Region';
import Icon from '../../Common/Icon';
import {
  AutoCompleteSX,
  InputSX,
  OptionItem,
  SearchBarPaperSX,
  SearchBarSX,
} from './style';

interface IProps {
  inputValue: string;
  setInputValue: (value: string) => void;
}

const SearchBar = ({ inputValue, setInputValue }: IProps) => {
  const { data: searchResults = [] } = useGetUserRegionsQuery({
    skipCallback: true,
    sorting: 'name',
    sortingDirection: 'asc',
  });
  const navigate = useNavigate();
  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value);
    },
    [setInputValue]
  );

  const handleOptionSelect = useCallback(
    (region: IRegionResponse) => {
      navigate(
        `/portfolio/folder/${region.folderId || 'unassigned'}/project/${String(
          region.id || ''
        )}`
      );
      setInputValue('');
    },
    [navigate, setInputValue]
  );

  return (
    <Autocomplete
      freeSolo
      id="portfolio-search-bar"
      sx={AutoCompleteSX}
      open={!!inputValue}
      openOnFocus
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        return option?.name || '';
      }}
      options={
        searchResults.map((option) => ({
          name: option.name,
          id: option.id,
          folderId: option.folderId,
        })) as IRegionResponse[]
      }
      inputValue={inputValue}
      renderInput={(params) => (
        <TextField
          {...params}
          name="search"
          variant="standard"
          fullWidth
          sx={SearchBarSX}
          InputProps={{
            ...params.InputProps,
            sx: InputSX(!!inputValue),
            startAdornment: (
              <InputAdornment position="start">
                <Icon variant="SEARCH" color="silver" size={24} />
              </InputAdornment>
            ),
          }}
          placeholder="Search"
          onInput={handleInputChange}
        />
      )}
      disablePortal
      PaperComponent={(defaultProps) => (
        //Overide Default Paper
        <Paper {...defaultProps} sx={SearchBarPaperSX} elevation={0} />
      )}
      renderOption={(_, option) => {
        return (
          <OptionItem
            key={option.id}
            onClick={() => handleOptionSelect(option)}
          >
            {option.name}
          </OptionItem>
        );
      }}
    />
  );
};

export default SearchBar;
