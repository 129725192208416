import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { USER_SOURCE_NAME } from '../../../constants/map';
import { useMapContext } from '../../../context/Map';
import { usePolygonContext } from '../../../context/Polygon';
import { useSidebarContext } from '../../../context/Sidebar';
import { useGetSplitPolygonDataMutation } from '../../../redux/api/biomassApi';
import { useAddRegionMutation } from '../../../redux/api/regionApi';
import {
  resetDrawSliceState,
  setSplitDrawnPolygon,
} from '../../../redux/features/draw/draw-slice';
import { openModal } from '../../../redux/features/modal/modal-slice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { IRegionRequest } from '../../../types/API/Region';
import { ISplitPolygonState } from '../../../types/Geo';
import {
  checkPolygonIntersection,
  checkRegionSize,
} from '../../../utils/helpers';
import Button from '../../Common/Button';
import { ButtonWrapper } from '../../DataBoard/Footer/style';

const Footer = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { handleTileClick, removeMapSelection } = useMapContext();
  const { resetPolygonData } = usePolygonContext();
  const { onClose } = useSidebarContext();

  const { drawnFeatures, drawnFeatureName } = useAppSelector(
    (state) => state.drawState
  );
  const { user } = useAppSelector((state) => state.userState);

  const [addRegion, { isLoading, isError, reset }] = useAddRegionMutation();
  const [
    verifySplitPolygon,
    { data: verifySplitData, isLoading: isVerifyLoading, reset: resetSplit },
  ] = useGetSplitPolygonDataMutation();

  const handleRegionSave = useCallback(
    async (splitPolygonData?: ISplitPolygonState) => {
      if (!isLoading) {
        const elToGet = Object.values(drawnFeatures)[0];
        const polygonData: IRegionRequest = {
          name: drawnFeatureName,
          polygon: {
            type: elToGet.type,
            geometry: elToGet.geometry,
            properties:
              elToGet.properties as IRegionRequest['polygon']['properties'],
          },
        };
        const dates = splitPolygonData?.drawnDataOnTile.features
          .filter((el) => el.properties?.last_modified)
          .map((el) => new Date(String(el.properties?.last_modified)));

        if (dates) {
          polygonData.dataUpdatedAt = dates.reduce((a, b) => (a > b ? a : b));
        }
        const data = await addRegion(polygonData);
        if (!('error' in data)) {
          removeMapSelection();
          resetPolygonData();
          dispatch(resetDrawSliceState());
          handleTileClick(data.data, USER_SOURCE_NAME);
          reset();
        }
      }
    },
    [
      addRegion,
      dispatch,
      drawnFeatureName,
      drawnFeatures,
      handleTileClick,
      isLoading,
      removeMapSelection,
      reset,
      resetPolygonData,
    ]
  );

  useEffect(() => {
    if (isError) {
      toast.error(
        t('We were unable to save your region. Please try again later', {
          toastId: 'save-region-error-toast',
          autoClose: 3000,
        })
      );
      reset();
    }
  }, [isError, reset, t]);

  const checkName = useCallback(() => {
    if (drawnFeatureName.length === 0) {
      window.postMessage({
        type: 'region-name-error',
        payload: {
          message: t('Please provide a name for the region'),
        },
      });
      return false;
    }

    if (drawnFeatureName.length > 25) {
      window.postMessage({
        type: 'region-name-error',
        payload: {
          message: t('Name cannot exceed 25 characters'),
        },
      });
      return false;
    }
    return true;
  }, [drawnFeatureName.length, t]);

  const verifyData = useCallback(async () => {
    if (!isVerifyLoading) {
      const elToGet = Object.values(drawnFeatures)[0];

      // check if region size is correct
      const isRegionSizeCorrect = checkRegionSize(
        elToGet,
        user?.role || 'free'
      );
      if (!isRegionSizeCorrect) {
        dispatch(openModal('editRegionTooLargeShape'));
        return;
      }
      // check if polygon is valid
      const isIntercepting = checkPolygonIntersection(
        elToGet.geometry.coordinates[0]
      );

      if (isIntercepting) {
        dispatch(openModal('editRegionInvalidShape'));
        return;
      }

      // check if polygon has data
      await verifySplitPolygon({
        feature: elToGet,
        omitCache: true,
        withStateSet: false,
      });
    }
  }, [
    dispatch,
    drawnFeatures,
    isVerifyLoading,
    user?.role,
    verifySplitPolygon,
  ]);

  useEffect(() => {
    if (verifySplitData) {
      resetSplit();

      if (verifySplitData.drawnDataOnTile.features.length === 0) {
        dispatch(openModal('editRegionNoCoverage'));
        return;
      }

      if (
        verifySplitData.drawnDataOnTile.features.length > 0 &&
        verifySplitData.drawnDataOutsideTile.features.length > 0
      ) {
        dispatch(setSplitDrawnPolygon(verifySplitData));
        dispatch(openModal('editRegionMissingData'));
        return;
      }

      if (
        verifySplitData.drawnDataOnTile.features.length > 0 &&
        verifySplitData.drawnDataOutsideTile.features.length === 0
      ) {
        dispatch(setSplitDrawnPolygon(verifySplitData));
        void handleRegionSave(verifySplitData);
      }
    }
  }, [dispatch, handleRegionSave, resetSplit, verifySplitData]);

  const handleOnSave = useCallback(() => {
    const isNameCorrect = checkName();
    if (isNameCorrect) {
      void verifyData();
    }
  }, [checkName, verifyData]);

  return (
    <ButtonWrapper>
      <Button
        fullWidth
        dataTestId="edit-region-cancel-button"
        variant={'outline-blue'}
        onClick={onClose}
        disabled={isLoading}
      >
        {t('Cancel')}
      </Button>
      <Button
        fullWidth
        variant="green"
        dataTestId="edit-region-save-button"
        onClick={handleOnSave}
        disabled={isLoading}
      >
        {t('Save')}
      </Button>
    </ButtonWrapper>
  );
};

export default Footer;
