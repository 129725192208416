import MUICheckbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ChangeEvent, InputHTMLAttributes, ReactNode } from 'react';
import { CheckboxContainer } from './style';

export interface Props {
  checked: boolean;
  disabled?: boolean;
  id?: string;
  label: ReactNode;
  name?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  dataTestId?: string;
  filledColor?: string;
  unfilledColor?: string;
}

const Checkbox = ({
  label,
  checked,
  onChange,
  name,
  id,
  disabled = false,
  required = false,
  dataTestId,
  filledColor,
  unfilledColor,
}: Props) => {
  return (
    <CheckboxContainer filledColor={filledColor} unfilledColor={unfilledColor}>
      <FormControlLabel
        control={
          <MUICheckbox
            name={name}
            id={id}
            checked={checked}
            onChange={onChange}
            disabled={disabled}
            required={required}
            inputProps={
              {
                'data-test-id': dataTestId,
              } as InputHTMLAttributes<HTMLInputElement>
            }
          />
        }
        label={label}
        data-test-id={dataTestId && `${dataTestId}-label`}
      />
    </CheckboxContainer>
  );
};

export default Checkbox;
