import styled from 'styled-components';
import { IconWrapper } from '../../Common/Icon/style';

export const SliderWrapper = styled.div<{
  active?: boolean;
}>`
  position: fixed;
  z-index: 10;
  bottom: 24px;
  left: calc(-100vw + 664px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100vw - 696px);
  opacity: ${({ active }) => (active ? 1 : 0)};
  transition: ${({ theme }) => theme.transition};
  transform: ${({ active }) =>
    active ? 'translate3d(0, 0, 0)' : 'translate3d(0, 50px, 0)'};
  pointer-events: ${({ active }) => (active ? 'all' : 'none')};

  &:before {
    content: '';
    position: fixed;
    bottom: -24px;
    left: -32px;
    width: calc(100vw - 632px);
    height: 100%;
    z-index: 0;
    background: linear-gradient(180deg, rgba(25, 43, 52, 0) 0%, #192b34 76.56%);
    transition: ${({ theme }) => theme.transition};
    opacity: ${({ active }) => (active ? 1 : 0)};
    transform: ${({ active }) =>
      active ? 'translate3d(0, 0, 0)' : 'translate3d(0, 50px, 0)'};
    transition-delay: 300ms;
  }
`;

export const SliderSlides = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 14px;
  position: relative;
  width: calc(100vw - 696px);
  overflow-x: scroll;
  overflow-y: visible;
  height: 293px;
  padding-bottom: 46px;
  margin-bottom: -60px;

  &::-webkit-scrollbar {
    width: 8px;
    height: 12px;
    background-color: rgba(217, 217, 217, 0.2);
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #b5b5b5;
    border-radius: 30px;
    height: 10px;
  }

  &:before {
    content: '';
    position: fixed;
    bottom: 98px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.lightBlueTransparent};
  }
`;

export const SliderSlide = styled.div<{
  active?: boolean;
  timelineText: string;
  isLoading?: boolean;
}>`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: ${({ active }) => (active ? '234px' : '70px')};
  height: ${({ active }) => (active ? '234px' : '70px')};
  border: 1px solid #404f55;
  background-color: #000;
  border-radius: 8px;
  margin: 0 ${({ active }) => (active ? '2px' : 0)};
  position: relative;
  transition: ${({ theme }) => theme.transition};
  overflow-y: visible;
  cursor: pointer;

  ${({ isLoading }) =>
    isLoading &&
    `
    opacity: 0.2;
    cursor: not-allowed;
    pointer-events: none;
  `}

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    top: 0;
    left: 0;
  }

  &:hover {
    border-color: ${({ theme, active }) =>
      active ? theme.colors.green : theme.colors.white};
  }

  &:before {
    content: ${({ timelineText }) => `'${timelineText}'`};
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    color: ${({ theme }) => theme.colors.lightBlueTransparent};
    position: absolute;
    bottom: -35px;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  }
  &:after {
    content: '';
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.white};
    position: absolute;
    bottom: -17.5px;
    left: calc(50% - 2.5px);
  }
`;

export const SliderNavigationWrapper = styled.div<{
  active?: boolean;
  disabled?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 82px;
  width: 100%;
  opacity: ${({ active }) => (active ? 1 : 0)};
  transition: ${({ theme }) => theme.transition};
  transform: ${({ active }) =>
    active ? 'translate3d(0, 0, 0)' : 'translate3d(0, 50px, 0)'};
  transition-delay: 300ms;
  // if disabled - pointer-events: none;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
`;

export const SliderButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

export const SliderControlButton = styled.button<{
  rotateIcon?: boolean;
  isPlay?: boolean;
}>`
  width: 32px;
  height: 32px;
  background: ${({ theme }) => theme.colors.newGrey};
  border: 2px solid rgba(255, 255, 255, 0.08);
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: ${({ theme }) => theme.transition};
  transform: ${({ rotateIcon }) =>
    rotateIcon ? 'rotate(180deg)' : 'rotate(0deg)'};

  &:hover {
    ${IconWrapper} {
      opacity: 0.6;
    }
  }

  ${IconWrapper} {
    transition: ${({ theme }) => theme.transition};
    margin-left: ${({ isPlay }) => (isPlay ? '3px' : 0)};
    mask-size: cover;
  }
`;

export const SliderCTA = styled.button`
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  background-color: transparent;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #e1e1e1;
  width: auto;
  height: 30px;
  white-space: nowrap;
  cursor: pointer;
  transition: ${({ theme }) => theme.transition};
  overflow: visible;
  z-index: 1;

  &:hover {
    opacity: 0.6;
  }

  ${IconWrapper} {
    margin-right: 10px;
  }
`;
