import { useCallback, useEffect, useState } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import { DrawInfo, DrawInfoCoordinates } from './style';

const DrawInfoComponent = () => {
  const [elPosition, setElPosition] = useState({ top: 0, left: 0 });
  const { drawLngLat, isDrawing } = useAppSelector((state) => state.drawState);

  const handleSetTooltipPosition = useCallback((e: MouseEvent) => {
    setElPosition({
      top: e.pageY + 10,
      left: e.pageX + 10,
    });
  }, []);

  useEffect(() => {
    if (isDrawing) {
      document.addEventListener('mousemove', handleSetTooltipPosition);
    } else {
      document.removeEventListener('mousemove', handleSetTooltipPosition);
    }

    return () =>
      document.removeEventListener('mousemove', handleSetTooltipPosition);
  }, [isDrawing, handleSetTooltipPosition]);

  return (
    <DrawInfo
      data-test-id="draw-info"
      style={{
        top: `${elPosition.top}px`,
        left: `${elPosition.left}px`,
      }}
    >
      {drawLngLat ? (
        <DrawInfoCoordinates
          data-test-id={'draw-info-coordinates'}
        >{`${new Intl.NumberFormat('en', {
          maximumFractionDigits: 2,
        }).format(drawLngLat?.lng)}, ${new Intl.NumberFormat('en', {
          maximumFractionDigits: 2,
        }).format(drawLngLat.lat)}`}</DrawInfoCoordinates>
      ) : null}
    </DrawInfo>
  );
};

export default DrawInfoComponent;
