import styled, { createGlobalStyle, css } from 'styled-components';

export const themeColors = css`
  ${({ theme: { colors } }) => {
    let colorsString = '';
    for (const [key, value] of Object.entries(colors)) {
      colorsString = colorsString.concat(`--${key}: ${String(value)};`);
    }
    return colorsString;
  }}
`;

export const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
    font-family: ${({ theme }) => theme.fontFamily} !important;
    -webkit-font-smoothing: antialiased;
    scroll-behavior: smooth;
  }

  :root {
    ${themeColors};
    --toastify-color-error: var(--red);
    --toastify-color-success: #04905F;
    --toastify-toast-width: 600px;
    --fontFamily: 'Inter', sans-serif;
    --letterSpacing: -0.02em;
    --transition: all 300ms ease;
    --borderRadius: 8px;
  }

  .Toastify__toast-container--bottom-center {
    width: auto !important;

    .Toastify__toast-theme--colored.Toastify__toast--default,
    .Toastify__toast-theme--colored.Toastify__toast--default .Toastify__toast-body {
      background-color: transparent !important;
      padding: 0 !important;
      margin: 0 !important;
    }
  }

  html, body {
    font-family: 'Inter', sans-serif;
  }

  html {
    display: block;
    height: 100%;
    margin: 0 auto;
    padding: 0;
  }

  p, h1, h2, h3, h4, h5, h6, span, a {
    margin: 0;
    padding: 0;
  }

  body {
    min-height: 100vh;
    margin: 0;
    transition: all 300ms ease;
  }


  .recharts-xAxis .recharts-cartesian-axis-tick {
    transform: translate(0, 10px);
  }

  .mapboxgl-ctrl.mapboxgl-ctrl-group {
    background-color: transparent;
    margin: 0 20px 20px 0;
    transition: all 300ms ease;
  }

  .deforestation .mapboxgl-ctrl.mapboxgl-ctrl-group {
    margin: 0 20px 240px 0;
  }

  .deforestation .style-change-container {
    margin-bottom: 220px;
  }

  .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in {
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important;
  }

  .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out {
    border-bottom-left-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
  }

  .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in,
  .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out {
    background-color: var(--black) !important;
    transition: all 300ms ease;
  }

  .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in:hover,
  .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out:hover {
    background-color: var(--black) !important;
    opacity: 0.8;
  }

  .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon,
  .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
    background-image: none;
    background-color: var(--grey);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 16px;
    transition: all 300ms ease;
  }

  .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
    mask-image: url('/assets/icons/plus.svg');
  }

  .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
    mask-image: url('/assets/icons/minus.svg');
  }

  .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in, .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out {
    width: 40px;
    height: 40px;
    border: 0;
  }

  .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in {
    border-bottom: 1px solid var(--grey);
  }

  .mapboxgl-ctrl-logo, .mapboxgl-ctrl.mapboxgl-ctrl-attrib {
    display: none !important;
  }

  .Toastify__toast-theme--colored.Toastify__toast--default {
    background-color: transparent !important;
    padding: 0 !important;
  }

  .Toastify__toast-container--top-center {
    top: 93px !important;
    padding: 0;
    width: auto;
  }

  ::placeholder {
    color: var(--darkGray);
    font-weight: 500;
  }
  
  .rs-picker-popup {
      z-index: 1301;
      background-color: var(--blackIII);
      margin-top: -5px;
  }
  
  .rs-picker-daterange-header span {
      color: var(--white);
  }

  .rs-picker-toolbar.rs-stack button.rs-btn-primary {
      background-color: var(--purple);
      color: var(--blackI);
      font-weight: 700;
      width: 100px;
  }

  .rs-calendar-table-cell-selected .rs-calendar-table-cell-content, .rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover {
      background-color: var(--purple) !important;
  }
  button.rs-calendar-header-title {
      color: var(--white);
  }
  button.rs-calendar-header-title-date {
      color: var(--purple);
  }
  .rs-btn-subtle:focus, .rs-btn-subtle:hover {
      background-color: transparent;
      color: var(--purple);
  }
  .rs-picker-toolbar-ranges .rs-btn-link {
      color: var(--purple);
  }
  .rs-calendar-month-dropdown {
      background-color: var(--blackIV);
  }
  .rs-calendar-month-dropdown-year.rs-calendar-month-dropdown-year-active {
      color: var(--purple);
  }
  .rs-calendar-month-dropdown-cell-active .rs-calendar-month-dropdown-cell-content {
      background-color: var(--purple);
      color: var(--blackI);
  }
  .rs-calendar-btn-close {
      background-color: var(--blackIV);
  }
  .rs-calendar-header-title.rs-calendar-header-title-date.rs-btn.rs-btn-subtle.rs-btn-xs {
      color: var(--purple);
  }
  
  .rs-btn-subtle:focus, .rs-btn-subtle:hover {
      background-color: transparent;
      color: var(--purple);
  }
  .rs-calendar-table-cell-in-range:before  {
      background-color: var(--purple);
      opacity: 0.1;
  }
  .rs-picker-popup .rs-calendar .rs-calendar-month-dropdown-cell-content:hover, .rs-picker-popup .rs-calendar .rs-calendar-table-cell:hover .rs-calendar-table-cell-content {
      background-color: var(--blackII);
      color: #fff;
  }

  .rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
      box-shadow: inset 0 0 0 1px var(--purple);
  }
`;

export const ContentWrapper = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;
  display: flex;
`;

export const ReCaptchaWrapper = styled.div`
  margin: 20px 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RegistrationTokenDisclaimer = styled.p`
  font-size: 16px;
  color: var(--white);
  margin-bottom: 30px;
  text-align: center;

  a {
    color: var(--purple);
  }
`;
