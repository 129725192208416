import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface MapState {
  sources: string[];
}

const initialState: MapState = {
  sources: ['default', 'user', 'public'],
};

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setSources: (state, action: PayloadAction<string[]>) => {
      state.sources = action.payload;
    },
  },
});
export default mapSlice.reducer;
export const { setSources } = mapSlice.actions;
