import styled from 'styled-components';
import { IconWrapper } from '../Common/Icon/style';

export const Wrapper = styled.div<{ open?: boolean }>`
  // if the wrapper is over 1440px, the map tools will be fixed to the max width minus the sidebar width
  position: fixed;
  top: 123px;
  left: 32px;
  border-radius: 8px;
  width: 230px;
  z-index: 99;
  box-shadow: 0 4px 4px rgba(0, 0, 0, ${({ open }) => (open ? '0.25' : '0')});
  @media screen and (min-width: 1440px) {
    left: calc((100vw - 1440px) / 2);
  }
`;

export const Toggle = styled.button<{ open?: boolean }>`
  cursor: pointer;
  border-radius: ${({ open }) => (open ? '10px 10px 0 0' : '10px')};
  border: 0;
  border-bottom: ${({ open }) => (open ? '1px' : '0')} solid
    rgba(208, 247, 255, 0.2);
  width: 100%;
  background-color: ${({ theme }) => theme.colors.blackII};
  padding: 20px;
  color: ${({ theme }) => theme.colors.silver};
  font-size: 18px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  transition: ${({ theme }) => theme.transition};

  ${IconWrapper} {
    transform: ${({ open }) => (open ? 'rotate(180deg)' : 'rotate(0deg)')};
    transition: ${({ theme }) => theme.transition};
    background-color: ${({ theme }) => theme.colors.gray};
  }
`;

export const Options = styled.div<{ open?: boolean }>`
  border-radius: 0 0 8px 8px;
  background-color: rgba(18, 22, 36, 0.8);
  backdrop-filter: blur(5px);
  padding: ${({ open }) => (open ? '20px' : '0')};
  opacity: ${({ open }) => (open ? '1' : '0')};
  pointer-events: ${({ open }) => (open ? 'all' : 'none')};
  transition: ${({ theme }) => theme.transition};
  height: ${({ open }) => (open ? 'auto' : '0')};
`;

export const OptionsSection = styled.div`
  display: flex;
  flex-direction: column;

  &:first-of-type {
    margin-bottom: 24px;
  }

  .MuiFormControlLabel-root {
    margin: 0;
    display: flex;
    align-items: center;

    .MuiButtonBase-root {
      margin: 0 12px 0 0;
    }

    .MuiFormControlLabel-label {
      color: ${({ theme }) => theme.colors.white};
      white-space: nowrap;
      font-weight: normal;
    }
  }
`;

export const OptionsSectionTitle = styled.h4`
  color: ${({ theme }) => theme.colors.gray};
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
`;

export const OptionsButton = styled.button<{ isForPro?: boolean }>`
  width: 190px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 46px;
  background-color: ${({ theme }) => theme.colors.silver};
  border-radius: 8px;
  font-size: 16px;
  border: 0;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.blackI};
  cursor: pointer;
  position: relative;

  &:first-of-type {
    margin-bottom: 10px;
  }

  &:before {
    display: ${({ isForPro }) => (isForPro ? 'block' : 'none')};
    content: 'PRO';
    position: absolute;
    top: -5px;
    right: -15px;
    color: ${({ theme }) => theme.colors.blue};
    font-size: 12px;
    font-weight: bold;
    line-height: 14px;
    padding: 4px 8px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.gold};
  }

  ${IconWrapper} {
    margin-right: 10px;
    background-color: ${({ theme }) => theme.colors.newGrey};
  }

  &:disabled {
    background-color: rgba(220, 233, 245, 0.6);
    color: rgba(17, 26, 27, 0.6);
    cursor: not-allowed;

    > div {
      opacity: 0.6;
    }
  }
`;
