import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUser } from '../../../types/User';
import { getCookie } from '../../../utils/helpers';

interface UserState {
  adminUserPickedToEdit: IUser | null;
  apiKey: string | null;
  user?: IUser | null;
  isAuthenticated: boolean;
}

const initialState: UserState = {
  adminUserPickedToEdit: null,
  apiKey: null,
  user: null,
  isAuthenticated: getCookie('authorized') === 'true',
};
export const userSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: () => {
      localStorage.clear();
      return initialState;
    },
    setUser: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
    },
    setApiKey: (state, action: PayloadAction<string>) => {
      state.apiKey = action.payload;
    },
    setAdminUserPickedToEdit: (state, action: PayloadAction<IUser>) => {
      state.adminUserPickedToEdit = action.payload;
    },
  },
});

export default userSlice.reducer;
export const { logout, setUser, setAdminUserPickedToEdit, setApiKey } =
  userSlice.actions;
