import { TFunction } from 'i18next';
import { IFolderResponse } from '../types/API/Folder';

export const FOLDER_OPTIONS = (
  foldersData: IFolderResponse[],
  t: TFunction<'translation', undefined>
) =>
  [
    {
      value: 'empty',
      label: String(t('Unassigned')),
    },
  ].concat(
    foldersData.map((folder) => ({
      value: folder.id,
      label: folder.name,
      isPlaceholder: false,
    }))
  );

export const STATUS_OPTIONS = (t: TFunction<'translation', undefined>) => [
  {
    value: 'empty',
    label: String(t('Select Status')),
    isPlaceholder: true,
  },
  {
    value: 'active',
    label: String(t('Active')),
    isPlaceholder: false,
  },
  {
    value: 'inactive',
    label: String(t('Inactive')),
    isPlaceholder: false,
  },
];

export const SUPPORTED_SHAPEFILE_EXTENSIONS = '.geojson,.shp,.kml,.json';
