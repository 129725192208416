import styled from 'styled-components';

export const FileInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 75px 20px 20px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.purple};
  position: relative;
`;

export const FileInfoTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 5px;
`;

export const FileInfoSubtitle = styled.span`
  display: block;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.grey};
`;

export const FileResetButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  border: 0;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.purple};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.blackI};
  font-size: 11px;
  font-weight: bold;
  padding: 5px 10px;
`;

export const StepWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StepCircle = styled.div<{ active: boolean }>`
  font-size: 20px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.purple};
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  opacity: ${({ active }) => (active ? 1 : 0.5)};
  transition: ${({ theme }) => theme.transition};
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: calc(50% - 1px);
    height: 1px;
    width: 10px;
    right: -10px;
    transition: ${({ theme }) => theme.transition};
    background-color: ${({ theme }) => theme.colors.purple};
    transform: ${({ active }) =>
      active ? 'translate3d(0,0,0)' : 'translate3d(-100%,0,0)'};
    opacity: ${({ active }) => (active ? 1 : 0)};
  }
  &:last-of-type {
    &:after {
      display: none;
    }
  }
`;
