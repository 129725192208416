import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { SUPPORTED_SHAPEFILE_EXTENSIONS } from '../../../../constants/region';
import { useDropZone } from '../../../../hooks/useDropZone/useDropZone';
import { useExtractPolygonsFromFileMutation } from '../../../../redux/api/regionApi';
import { useAppSelector } from '../../../../redux/hooks';
import { checkRegionSize } from '../../../../utils/helpers';
import InformationBanner from '../../../Common/InformationBanner';
import { IItem } from '../index';
import { UploadItemsList } from '../style';
import UploadItemComponent from '../UploadItem';

interface IUploadStepProps {
  regionsToSave: IItem[];
  setRegionsToSave: Dispatch<SetStateAction<IItem[]>>;
  polygonsWithoutDataParts: IItem[];
  setPolygonsWithoutDataParts: Dispatch<SetStateAction<IItem[]>>;
  polygonsWithDataParts: IItem[];
  setPolygonsWithDataParts: Dispatch<SetStateAction<IItem[]>>;
  hasMissingData: boolean;
  reportMissingData: boolean;
  setReportMissingData: Dispatch<SetStateAction<boolean>>;
  polygonMessage: string;
  setPolygonMessage: Dispatch<SetStateAction<string>>;
}

const UploadStep = ({
  regionsToSave,
  setRegionsToSave,
  polygonsWithoutDataParts,
  polygonsWithDataParts,
  setPolygonsWithDataParts,
  setPolygonsWithoutDataParts,
  hasMissingData,
  reportMissingData,
  setReportMissingData,
  polygonMessage,
  setPolygonMessage,
}: IUploadStepProps) => {
  const { t } = useTranslation();
  const { user } = useAppSelector((state) => state.userState);
  const [extractPolygonsFromFile, { data, isSuccess: isExtracted, reset }] =
    useExtractPolygonsFromFileMutation();

  const handleToggleReportMissingData = useCallback(() => {
    setReportMissingData((wasSent) => !wasSent);
  }, [setReportMissingData]);

  const uploadFileAndPrepareData = useCallback((files: FileList) => {
    const form = new FormData();
    form.append('file', files[0]);
    return form;
  }, []);

  const { renderUploadSection, filename, inputRef, formData, setFormData } =
    useDropZone({
      prepareData: uploadFileAndPrepareData,
      accept: SUPPORTED_SHAPEFILE_EXTENSIONS,
    });

  const createElementsToRender = useCallback(
    (polygons: GeoJSON.Feature<GeoJSON.Polygon>[], name: string): IItem[] =>
      polygons.map((polygon) => {
        return {
          data: polygon,
          name: '',
          filename: name,
        };
      }),
    []
  );

  const filterOutPolygonsWithSameGeometry = useCallback(
    (polygons: IItem[]): IItem[] | null => {
      const newRegionsList = polygons.filter(
        (polygon) =>
          !regionsToSave.some(
            (el) =>
              JSON.stringify(el.data.geometry) ===
              JSON.stringify(polygon.data.geometry)
          )
      );

      if (newRegionsList.length === 0) {
        toast.error(
          t('Regions from this file are already added to list', {
            toastId: 'upload-regions-already-on-list',
            autoClose: 3000,
          })
        );
        return null;
      }

      return newRegionsList;
    },
    [regionsToSave, t]
  );

  useEffect(() => {
    if (formData) {
      void extractPolygonsFromFile(formData);
    }
  }, [extractPolygonsFromFile, formData]);

  useEffect(() => {
    if (isExtracted && data) {
      const polygonsToSave = createElementsToRender(data.polygons, filename);
      const newRegionsList = filterOutPolygonsWithSameGeometry(polygonsToSave);

      if (newRegionsList) {
        const polygonsLessThanMaxSize = newRegionsList.filter((polygon) =>
          checkRegionSize(polygon.data, user?.role || 'free')
        );

        if (polygonsLessThanMaxSize.length === 0) {
          toast.error(
            t('All Regions from this file are too big to be added', {
              toastId: 'upload-regions-all-too-big',
              autoClose: 3000,
            })
          );
          return;
        }

        if (polygonsLessThanMaxSize.length < newRegionsList.length) {
          toast.warning(
            t('Some Regions from this file are too big to be added', {
              toastId: 'upload-regions-too-big',
              autoClose: 3000,
            })
          );
        }

        setRegionsToSave((prevRegions) => [
          ...prevRegions,
          ...polygonsLessThanMaxSize,
        ]);
      }

      if (inputRef.current) inputRef.current.value = '';
      setFormData(null);
      reset();
    }
  }, [
    createElementsToRender,
    data,
    filename,
    filterOutPolygonsWithSameGeometry,
    inputRef,
    isExtracted,
    reset,
    setFormData,
    setRegionsToSave,
    t,
    user?.role,
  ]);

  const onRemovePolygon = useCallback(
    (item: IItem) => {
      // remove from polygonsWithoutDataParts if it's there
      const newPolygonsWithoutDataParts = polygonsWithoutDataParts.filter(
        (el) => JSON.stringify(el) !== JSON.stringify(item)
      );
      setPolygonsWithoutDataParts(newPolygonsWithoutDataParts);

      // do the same with polygonsWithDataParts
      const newPolygonsWithDataParts = polygonsWithDataParts.filter(
        (el) => JSON.stringify(el) !== JSON.stringify(item)
      );
      setPolygonsWithDataParts(newPolygonsWithDataParts);
      const newRegionsList = regionsToSave.filter(
        (el) => JSON.stringify(el) !== JSON.stringify(item)
      );
      setRegionsToSave(newRegionsList);
    },
    [
      polygonsWithDataParts,
      polygonsWithoutDataParts,
      regionsToSave,
      setPolygonsWithDataParts,
      setPolygonsWithoutDataParts,
      setRegionsToSave,
    ]
  );

  return (
    <div id="upload-modal-upload-step">
      {renderUploadSection()}
      {regionsToSave.length > 0 ? (
        <UploadItemsList>
          {hasMissingData ? (
            <InformationBanner
              checked={reportMissingData}
              onToggle={handleToggleReportMissingData}
              text={t(
                'At least one of your regions does not have data available on your current tier. Send a message to our team to request data for this region'
              )}
              message={polygonMessage}
              setMessage={setPolygonMessage}
            />
          ) : null}
          {regionsToSave.map((region) => (
            <UploadItemComponent
              data-test-id="upload-modal-upload-item"
              onRemove={onRemovePolygon}
              region={region}
              isIncorrect={polygonsWithoutDataParts.some(
                (el) =>
                  JSON.stringify(el.data.geometry) ===
                  JSON.stringify(region.data.geometry)
              )}
              key={JSON.stringify(region.data.geometry)}
            />
          ))}
        </UploadItemsList>
      ) : null}
    </div>
  );
};

export default UploadStep;
