import {
  ChangeEvent,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
// eslint-disable-next-line import/no-named-as-default
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { VALIDATION_ERRORS } from '../../../constants/validation';
import { useRegisterMutation } from '../../../redux/api/authApi';
import {
  ReCaptchaWrapper,
  RegistrationTokenDisclaimer,
} from '../../../styles/Global';
import { ISignupBody } from '../../../types/API/Auth';
import { validateEmail, validatePassword } from '../../../utils/validation';
import Button from '../../Common/Button';
import Checkbox from '../../Common/Checkbox';
import Input from '../../Common/Input';
import PasswordInputComponent from '../../Common/PasswordInput';
import PhoneInputComponent from '../../Common/PhoneInput';
import Spacer from '../../Common/Spacer';
import { InputGroup } from '../../Layout/AuthLayout/style';
import { CheckboxContainer } from './style';

interface Props {
  onSuccess: () => void;
}

const RegistrationForm = ({ onSuccess }: Props) => {
  const { t } = useTranslation();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [telephone, setTelephone] = useState('');
  const [company, setCompany] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [registrationToken, setRegistrationToken] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);

  const formRef = useRef<HTMLFormElement>(null);
  const captchaRef = useRef<ReCAPTCHA>(null);

  const [registerUser, { isLoading, error, reset }] = useRegisterMutation();

  const handleSetFirstName = (value: string) => {
    setFirstName(value);
  };

  const handleSetLastName = (value: string) => {
    setLastName(value);
  };

  const handleSetTelephone = (value: string) => {
    setTelephone(value);
  };

  const handleSetCompany = (value: string) => {
    setCompany(value);
  };

  const handleSetEmail = (value: string) => {
    if (value.length > 0 && !validateEmail(value)) {
      setEmailError(VALIDATION_ERRORS(t).EMAIL);
    } else {
      setEmailError('');
    }

    setEmail(value);
  };

  const handleSetPassword = (value: string) => {
    if (value.length > 0 && !validatePassword(value)) {
      setPasswordError(VALIDATION_ERRORS(t).PASSWORD);
    } else {
      setPasswordError('');
    }

    setPassword(value);
  };

  const handleSetRegistrationToken = (value: string) => {
    setRegistrationToken(value);
  };

  const onSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    const body: ISignupBody = {
      email,
      password,
    };

    if (firstName) {
      body.firstName = firstName;
    }

    if (lastName) {
      body.lastName = lastName;
    }

    if (telephone) {
      body.telephone = telephone;
    }

    if (company) {
      body.company = company;
    }

    if (passwordError.length > 0 || emailError.length > 0 || !isChecked) {
      toast.error(VALIDATION_ERRORS(t).GENERIC);
      return;
    }

    if (!window.Cypress) {
      if (!recaptchaToken) {
        toast.error(t('Please complete the reCAPTCHA'), {
          toastId: 'recaptcha-error',
          autoClose: 3000,
        });
        return;
      } else {
        body.recaptchaToken = recaptchaToken;
      }
    }

    if (
      registrationToken !== process.env.REACT_APP_REGISTRATION_TOKEN &&
      process.env.REACT_APP_ENV === 'production'
    ) {
      toast.error(
        t('Invalid registration token', {
          toastId: 'registration-token-error',
          autoClose: 3000,
        })
      );
      return;
    }
    const data = await registerUser(body);
    if (!('error' in data)) {
      onSuccess();
    }
  };

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  useEffect(() => {
    if (error && 'data' in error) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
      toast.error((error.data as any).message, {
        toastId: 'registration-error',
        autoClose: 3000,
      });
      reset();
    }
  }, [error, reset]);

  return (
    <form onSubmit={onSubmit} ref={formRef}>
      {process.env.REACT_APP_ENV === 'production' ? (
        <>
          <RegistrationTokenDisclaimer>
            {t('Do not have a registration token? ')}
            <a
              href={`mailto:${String(process.env.REACT_APP_EMAIL_UPGRADE_TO)}`}
            >
              {t('Reach out')}
            </a>
            {t(' to our')}
            <br />
            {t(' team to get started with free access.')}
          </RegistrationTokenDisclaimer>
          <Input
            value={registrationToken}
            dataTestId="registration-form-registration-token"
            onChange={handleSetRegistrationToken}
            label={String(t('Registration token'))}
            required
            fullWidth
          />
          <Spacer size={'20px 0 0'} />
        </>
      ) : null}
      <Input
        value={email}
        onChange={handleSetEmail}
        type="email"
        dataTestId="registration-form-email"
        label={String(t('Email address'))}
        fullWidth
        error={emailError}
        required
        maxLength={50}
      />
      <Spacer size={'20px 0 0'} />
      <PasswordInputComponent
        value={password}
        onChange={handleSetPassword}
        error={passwordError}
        dataTestId="registration-form-password"
        label={String(t('Create a password'))}
        required
        width="190px"
      />
      <Spacer size={'20px 0 0'} />
      <InputGroup>
        <Input
          value={firstName}
          dataTestId="registration-form-first-name"
          onChange={handleSetFirstName}
          label={String(t('First name'))}
          maxLength={50}
          width="190px"
        />
        <Input
          value={lastName}
          dataTestId="registration-form-last-name"
          onChange={handleSetLastName}
          label={String(t('Last name'))}
          maxLength={50}
          width="190px"
        />
      </InputGroup>
      <InputGroup>
        <PhoneInputComponent
          value={telephone}
          dataTestId="registration-form-telephone"
          onChange={handleSetTelephone}
          label={String(t('Phone'))}
          maxLength={15}
          width="190px"
        />
        <Input
          value={company}
          dataTestId="registration-form-company"
          onChange={handleSetCompany}
          label={String(t('Company'))}
          maxLength={50}
          width="190px"
        />
      </InputGroup>
      <CheckboxContainer>
        <Checkbox
          checked={isChecked}
          dataTestId="registration-form-terms"
          onChange={handleCheckboxChange}
          required
          label={
            <p>
              {t('I have read and agree to Cyclops')}{' '}
              <a href="/terms-of-use" target="_blank">
                {t('Terms of Use')}
              </a>{' '}
              {t('and')}{' '}
              <a href="/privacy-policy" target="_blank">
                {t('Privacy Policy')}
              </a>
            </p>
          }
        />
      </CheckboxContainer>
      {!window.Cypress ? (
        <ReCaptchaWrapper>
          <ReCAPTCHA
            ref={captchaRef}
            sitekey={process.env.REACT_APP_RECAPTCHA_PAGE_KEY as string}
            size="normal"
            theme="dark"
            onChange={(token) => {
              setRecaptchaToken(token);
            }}
            onExpired={() => {
              setRecaptchaToken(null);
            }}
            data-test-id="registration-form-recaptcha"
          />
        </ReCaptchaWrapper>
      ) : null}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button
          dataTestId="registation-form-submit-btn"
          disabled={isLoading}
          variant="purple"
          type="submit"
          additionalSx={{
            width: '190px',
            color: 'var(--blackI)',
          }}
        >
          {t('Sign up')}
        </Button>
      </div>
    </form>
  );
};

export default RegistrationForm;
