import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSendProMailMutation } from '../../../../redux/api/mailApi';
import {
  useDeleteAccountByIdMutation,
  useGenerateUserKeyMutation,
  useSetUserActiveStatusMutation,
  useSetUserRoleMutation,
} from '../../../../redux/api/userApi';
import {
  closeModal,
  openModal,
} from '../../../../redux/features/modal/modal-slice';
import { setApiKey } from '../../../../redux/features/user/user-slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { IDynamicTemplateBody } from '../../../../types/API/Mail';
import Button from '../../../Common/Button';
import Modal from '../../../Common/Modal';
import Select from '../../../Common/Select';
import Spacer from '../../../Common/Spacer';
import { ButtonWrapper, Title } from '../../ModalCommon.style';
import {
  ButtonsWrapper,
  ContentWrapper,
  ModalSx,
  SelectWrapper,
  Subtitle,
} from './style';

const EditUserModal = () => {
  const dispatch = useAppDispatch();
  const { adminUserPickedToEdit } = useAppSelector((state) => state.userState);
  const { t } = useTranslation();

  const [
    generateUserKey,
    {
      isSuccess: isSuccessApiKey,
      isLoading: isGenerateKeyLoading,
      data,
      reset: resetApiKey,
    },
  ] = useGenerateUserKeyMutation();
  const [
    toggleActiveUserStatus,
    {
      isSuccess: isSuccessToggleActive,
      isLoading: isToggleLoading,
      reset: resetActiveStatus,
    },
  ] = useSetUserActiveStatusMutation();
  const [
    changeUserRole,
    {
      isSuccess: isSuccessChangeRole,
      isLoading: isChangeRoleLoading,
      reset: resetChangeRole,
    },
  ] = useSetUserRoleMutation();
  const [
    deleteUserById,
    {
      isSuccess: isSuccessDeleteUser,
      isLoading: isDeleteByIdLoading,
      reset: resetDeleteUser,
    },
  ] = useDeleteAccountByIdMutation();
  const [sendProMail] = useSendProMailMutation();
  const [role, setRole] = useState('free');
  const isLoading = useMemo(
    () =>
      isToggleLoading ||
      isGenerateKeyLoading ||
      isChangeRoleLoading ||
      isDeleteByIdLoading,
    [
      isToggleLoading,
      isGenerateKeyLoading,
      isChangeRoleLoading,
      isDeleteByIdLoading,
    ]
  );
  const isSuccess = useMemo(
    () =>
      isSuccessToggleActive ||
      isSuccessApiKey ||
      isSuccessChangeRole ||
      isSuccessDeleteUser,
    [
      isSuccessToggleActive,
      isSuccessApiKey,
      isSuccessChangeRole,
      isSuccessDeleteUser,
    ]
  );
  useEffect(() => {
    if (adminUserPickedToEdit?.role) setRole(adminUserPickedToEdit.role);
  }, [adminUserPickedToEdit]);

  const handleRemoveUser = useCallback(async () => {
    if (adminUserPickedToEdit?.id) {
      await deleteUserById({ id: adminUserPickedToEdit.id });
    }
  }, [deleteUserById, adminUserPickedToEdit?.id]);

  const handleRoleChange = useCallback((newRole: string) => {
    setRole(newRole);
  }, []);

  const handleOnSave = useCallback(async () => {
    if (adminUserPickedToEdit?.id && adminUserPickedToEdit?.role !== role) {
      await changeUserRole({
        id: adminUserPickedToEdit.id,
        role,
      });
    }
  }, [
    role,
    changeUserRole,
    adminUserPickedToEdit?.role,
    adminUserPickedToEdit?.id,
  ]);

  const handleGenerateApiKey = useCallback(async () => {
    if (adminUserPickedToEdit?.id) {
      await generateUserKey({
        id: adminUserPickedToEdit.id,
      });
    }
  }, [generateUserKey, adminUserPickedToEdit?.id]);

  const handleActivateDeactivateUser = useCallback(async () => {
    if (adminUserPickedToEdit?.id) {
      await toggleActiveUserStatus({
        id: adminUserPickedToEdit.id,
        body: {
          active: !adminUserPickedToEdit.active,
        },
      });
    }
  }, [
    adminUserPickedToEdit?.id,
    adminUserPickedToEdit?.active,
    toggleActiveUserStatus,
  ]);

  const resetData = useCallback(() => {
    resetApiKey();
    resetActiveStatus();
    resetChangeRole();
    resetDeleteUser();
  }, [resetActiveStatus, resetApiKey, resetChangeRole, resetDeleteUser]);

  useEffect(() => {
    const resetAndClose = () => {
      resetData();
      dispatch(closeModal());
    };
    const sendEmail = async () => {
      if (
        adminUserPickedToEdit?.email &&
        adminUserPickedToEdit?.firstName &&
        adminUserPickedToEdit?.lastName
      ) {
        const body: IDynamicTemplateBody = {
          to: adminUserPickedToEdit?.email,
          from: String(process.env.REACT_APP_EMAIL_CONTACT_US_FROM),
          dynamicTemplateData: {
            full_name:
              adminUserPickedToEdit.firstName && adminUserPickedToEdit.lastName
                ? `${adminUserPickedToEdit.firstName} ${adminUserPickedToEdit.lastName}`
                : adminUserPickedToEdit.email,
            cyclops_url: String(process.env.REACT_APP_APP_URL),
            support_email: String(process.env.REACT_APP_EMAIL_SUPPORT_PAGE),
          },
        };
        await sendProMail(body);
        resetAndClose();
      }
    };

    if (isSuccessApiKey && data) {
      dispatch(setApiKey(data.apiKey));
      dispatch(openModal('apiKey'));
      resetData();
      return;
    }

    if (isSuccessChangeRole) {
      void sendEmail();
      return;
    }

    if (isSuccess) {
      resetAndClose();
    }
  }, [
    data,
    dispatch,
    isSuccess,
    isSuccessApiKey,
    isSuccessChangeRole,
    resetData,
    sendProMail,
    adminUserPickedToEdit?.email,
    adminUserPickedToEdit?.firstName,
    adminUserPickedToEdit?.lastName,
  ]);

  return (
    <Modal
      additionalSx={ModalSx}
      dataTestId="admin-edit-user-modal"
      modalType="adminEditUser"
    >
      <ContentWrapper>
        <Title data-test-id="admin-edit-user-modal-title">
          {t('Edit user')}
        </Title>
        <Subtitle data-test-id="admin-edit-user-modal-subtitle">
          {adminUserPickedToEdit?.email}
        </Subtitle>
      </ContentWrapper>
      <SelectWrapper>
        <p>{t('Role')}</p>
        <Select
          additionalSx={{
            border: 0,
            backgroundColor: 'var(--navy)',
            height: '46px',
          }}
          dataTestId="admin-edit-user-modal-role-select"
          value={role}
          fullWidth
          autoWidth={false}
          onChange={handleRoleChange}
          options={[
            {
              value: 'free',
              label: t('Free'),
            },
            {
              value: 'pro',
              label: t('Pro'),
            },
            {
              value: 'admin',
              label: t('Admin'),
            },
          ]}
        />
      </SelectWrapper>
      <Spacer size="30px 0 0" />
      <ButtonsWrapper>
        <ButtonWrapper>
          <Button
            variant="purple"
            fullWidth
            onClick={handleOnSave}
            disabled={isLoading}
            dataTestId="admin-edit-user-modal-save-btn"
          >
            {t('Save')}
          </Button>
        </ButtonWrapper>
        <ButtonWrapper>
          <Button
            variant="purple"
            fullWidth
            onClick={handleGenerateApiKey}
            disabled={isLoading}
            dataTestId="admin-edit-user-modal-generate-api-key-btn"
            additionalSx={{
              padding: 0,
              alignSelf: 'flex-start',
            }}
          >
            {t('API Key')}
          </Button>
          <Button
            variant={adminUserPickedToEdit?.active ? 'red' : 'green'}
            fullWidth
            onClick={handleActivateDeactivateUser}
            disabled={isLoading}
            dataTestId="admin-activate-user-modal-remove-user-btn"
          >
            {adminUserPickedToEdit?.active ? t('Deactivate') : t('Activate')}
          </Button>
          <Button
            variant="red"
            fullWidth
            onClick={handleRemoveUser}
            disabled={isLoading}
            dataTestId="admin-edit-user-modal-remove-user-btn"
          >
            {t('Remove')}
          </Button>
        </ButtonWrapper>
      </ButtonsWrapper>
    </Modal>
  );
};

export default EditUserModal;
