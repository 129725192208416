import { ErrorBoundary, init } from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import 'rsuite/dist/rsuite.min.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import App from './App';
import './i18n';
import { store } from './redux/store';

init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  environment: process.env.REACT_APP_ENV,

  // It is recommended adjusting this value in production, or using tracesSampler for finer control
  tracesSampleRate: process.env.REACT_APP_ENV === 'production' ? 0.2 : 1.0,
});

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <StrictMode>
    <ErrorBoundary fallback={<p>An error has occurred</p>}>
      <Provider store={store}>
        <App />
      </Provider>
    </ErrorBoundary>
  </StrictMode>
);
export { SUPPORTED_SHAPEFILE_EXTENSIONS } from './constants/region';
