import { useEffect } from 'react';

interface Props {
  id: string;
  data: string;
  dataTestId?: string;
  onReady?: () => void; // Callback for when the canvas image is ready
}

const DeforestationImage = ({ id, data, dataTestId, onReady }: Props) => {
  useEffect(() => {
    if (onReady) {
      onReady();
    }
  }, [data, onReady]);

  return (
    <img
      alt={dataTestId}
      crossOrigin="anonymous"
      src={data}
      data-test-id={dataTestId}
      width="100%"
      height="100%"
      id={id}
    />
  );
};

export default DeforestationImage;
