import {
  CreatePublicProjectDto,
  PublicProject,
  UpdatePublicProjectDto,
} from '../../types/API/PublicProjects';
import { setPublicProjects } from '../features/public-projects/public-projects-slice';
import { baseApi } from './index';

export const publicProjectsApi = baseApi
  .enhanceEndpoints({
    addTagTypes: ['PublicProjectsAdmin', 'PublicProjects'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getPublicProjects: builder.query<
        {
          data: PublicProject[];
          total: number;
        },
        {
          itemsPerPage?: number;
          page?: number;
          sorting?: string;
          sortingDirection?: 'asc' | 'desc';
        }
      >({
        query({ itemsPerPage = 10, page = 1, sorting, sortingDirection }) {
          return {
            url: '/public-projects',
            method: 'GET',
            params: {
              itemsPerPage,
              page,
              sorting,
              sortingDirection,
            },
          };
        },
        providesTags: ['PublicProjectsAdmin'],
      }),
      getPublicProjectsWithBiomassData: builder.query<PublicProject[], void>({
        query() {
          return {
            url: '/public-projects/data',
            method: 'GET',
          };
        },
        providesTags: ['PublicProjects'],
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          const { data } = await queryFulfilled;
          dispatch(setPublicProjects(data));
        },
      }),
      uploadPDFFile: builder.mutation<
        {
          url: string;
        },
        FormData
      >({
        query(body) {
          return {
            url: '/public-projects/upload-file',
            method: 'POST',
            body,
          };
        },
      }),
      deletePDFFileFromProject: builder.mutation<
        void,
        {
          id: string;
        }
      >({
        query({ id }) {
          return {
            url: `/public-projects/${id}/delete-file`,
            method: 'DELETE',
          };
        },
        invalidatesTags: ['PublicProjectsAdmin'],
      }),
      updatePDFFileForProject: builder.mutation<
        {
          url: string;
        },
        {
          body: FormData;
          id: string;
        }
      >({
        query({ body, id }) {
          return {
            url: `/public-projects/${id}/update-file`,
            method: 'PATCH',
            body,
          };
        },
        invalidatesTags: ['PublicProjectsAdmin'],
      }),
      createPublicProject: builder.mutation<
        PublicProject,
        CreatePublicProjectDto
      >({
        query(body) {
          return {
            url: '/public-projects/add',
            method: 'POST',
            body,
          };
        },
        invalidatesTags: ['PublicProjectsAdmin'],
      }),
      deletePublicProject: builder.mutation<
        PublicProject,
        {
          id: string;
        }
      >({
        query({ id }) {
          return {
            url: `/public-projects/delete/${id}`,
            method: 'DELETE',
          };
        },
        invalidatesTags: ['PublicProjectsAdmin'],
      }),
      updatePublicProject: builder.mutation<
        PublicProject,
        {
          id: string;
          body: UpdatePublicProjectDto;
        }
      >({
        query({ body, id }) {
          return {
            url: `/public-projects/update/${id}`,
            method: 'PATCH',
            body,
          };
        },
        invalidatesTags: ['PublicProjectsAdmin'],
      }),
    }),
  });

export const {
  useGetPublicProjectsQuery,
  useGetPublicProjectsWithBiomassDataQuery,
  useUpdatePDFFileForProjectMutation,
  useUploadPDFFileMutation,
  useDeletePDFFileFromProjectMutation,
  useCreatePublicProjectMutation,
  useDeletePublicProjectMutation,
  useUpdatePublicProjectMutation,
} = publicProjectsApi;
