import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { MAP_TOOLS_STEPS } from '../../constants/joyride';
import { HINT_DISMISSED_LS } from '../../constants/user';
import { useMapContext } from '../../context/Map';
import { usePolygonContext } from '../../context/Polygon';
import useJoyride from '../../hooks/useJoyride';
import {
  resetDrawSliceState,
  setIsDrawing,
} from '../../redux/features/draw/draw-slice';
import { setSources } from '../../redux/features/map/map-slice';
import { openModal } from '../../redux/features/modal/modal-slice';
import { resetUIState } from '../../redux/features/ui/ui-slice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { theme } from '../../theme/Theme';
import Checkbox from '../Common/Checkbox';
import Icon from '../Common/Icon';
import {
  Options,
  OptionsButton,
  OptionsSection,
  OptionsSectionTitle,
  Toggle,
  Wrapper,
} from './style';

const MapTools = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { resetPolygonData } = usePolygonContext();
  const { removeMapSelection } = useMapContext();

  const [isOpen, setIsOpen] = useState(true);

  const { user } = useAppSelector((state) => state.userState);
  const { publicProjects } = useAppSelector(
    (state) => state.publicProjectsState
  );
  const { sources } = useAppSelector((state) => state.mapState);
  const { isDrawing } = useAppSelector((state) => state.drawState);
  const { userTiles } = useAppSelector((state) => state.regionState);

  const onSourcesClick = useCallback(
    (source: 'user' | 'default' | 'public') => {
      const newSources = sources.includes(source)
        ? sources.filter((s) => s !== source)
        : [...sources, source];
      dispatch(setSources(newSources));
    },
    [dispatch, sources]
  );

  const dismissToasts = useCallback(() => {
    ['hint-draw-first', 'hint', 'hint-draw-second', 'hint'].forEach(
      toast.dismiss
    );
  }, []);

  const resetUI = useCallback(() => {
    dispatch(resetUIState());
    dispatch(resetDrawSliceState());
    localStorage.setItem(HINT_DISMISSED_LS, 'true');
  }, [dispatch]);

  const onUploadClick = useCallback(() => {
    dismissToasts();
    if (user?.role !== 'free') {
      resetUI();
      toast.dismiss('hint');
      dispatch(openModal('uploadShapefile'));
    }
  }, [dismissToasts, dispatch, resetUI, user?.role]);

  const onOpenEditMode = useCallback(() => {
    resetUI();
    dismissToasts();

    if (!isDrawing) {
      resetPolygonData(true);
      dispatch(setIsDrawing(true));
      removeMapSelection();
    } else {
      resetPolygonData(false);
    }
  }, [
    resetUI,
    dismissToasts,
    isDrawing,
    resetPolygonData,
    dispatch,
    removeMapSelection,
  ]);

  useJoyride(MAP_TOOLS_STEPS(t));

  return (
    <Wrapper open={isOpen} id="map-tools">
      <Toggle open={isOpen} onClick={() => setIsOpen((wasOpen) => !wasOpen)}>
        {t('Map Tools')}
        <Icon variant="CARET" color="gray" size={24} />
      </Toggle>
      <Options open={isOpen}>
        <OptionsSection id="map-tools-layers">
          <OptionsSectionTitle>{t('View')}</OptionsSectionTitle>
          <Checkbox
            checked={sources.includes('default')}
            onChange={() => onSourcesClick('default')}
            label={t('Default regions')}
            dataTestId="map-tools-checkbox-default-regions"
            unfilledColor={theme.colors.green}
            filledColor={theme.colors.green}
          />
          <Checkbox
            checked={sources.includes('public') && publicProjects.length > 0}
            onChange={() => onSourcesClick('public')}
            label={t('Public projects')}
            dataTestId="map-tools-checkbox-public-projects"
            unfilledColor={theme.colors.purple}
            filledColor={theme.colors.purple}
            disabled={publicProjects.length === 0}
          />
          <Checkbox
            checked={sources.includes('user') && userTiles.length > 0}
            onChange={() => onSourcesClick('user')}
            label={t('My saved regions')}
            dataTestId="map-tools-checkbox-saved-regions"
            unfilledColor={theme.colors.blueNavy}
            filledColor={theme.colors.blueNavy}
            disabled={userTiles.length === 0}
          />
        </OptionsSection>
        <OptionsSection id="map-tools-buttons">
          <OptionsSectionTitle>
            {t('Create geospatial region')}
          </OptionsSectionTitle>
          <OptionsButton
            onClick={onOpenEditMode}
            data-test-id={'map-tools-upload-button'}
          >
            <Icon size={24} color="blackI" variant="DRAW_BOLD" />
            {!isDrawing ? t('Draw region') : t('Cancel drawing')}
          </OptionsButton>
          <OptionsButton
            onClick={onUploadClick}
            data-test-id={'map-tools-upload-button'}
            disabled={!user || user?.role === 'free'}
            isForPro={!user || user?.role === 'free'}
          >
            <Icon size={24} color="blackI" variant="UPLOAD_BOLD" />
            {t('Upload GIS file')}
          </OptionsButton>
        </OptionsSection>
      </Options>
    </Wrapper>
  );
};

export default MapTools;
