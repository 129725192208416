import { Text, View } from '@react-pdf/renderer';
import { ITableHeaderItem, TableHeader } from '../../types';
import setStyles from './TableGrid.styles';

type TableGridProps = {
  headers: TableHeader;
  data: Array<Array<string | number>>;
};

function TableGrid({ headers, data }: TableGridProps) {
  const styles = setStyles(headers.length);

  return (
    <View style={styles.table}>
      <>
        <View style={styles.tableHeader}>
          <>
            {headers.map((headerItem: ITableHeaderItem, index: number) => {
              return (
                <View
                  style={
                    styles[
                      index === 0
                        ? 'tableColFirst'
                        : index === headers.length - 1
                        ? 'tableColLast'
                        : 'tableCol'
                    ]
                  }
                  key={index}
                >
                  <Text style={styles.tableHeaderCell}>{headerItem.title}</Text>
                  {headerItem.subtitle ? (
                    <Text style={styles.tableHeaderCellSubtitle}>
                      {headerItem.subtitle}
                    </Text>
                  ) : null}
                </View>
              );
            })}
          </>
        </View>
        {data.map((item: any[], index: number) => {
          return (
            <View
              style={{
                ...styles.tableRow,
                ...{
                  backgroundColor:
                    index % 2 !== 0 ? 'white' : 'rgba(142, 148, 162, 0.10)',
                },
              }}
              key={index}
            >
              {item.map((value: any, valueIndex: number) => {
                return (
                  <View
                    style={
                      styles[
                        valueIndex === 0
                          ? 'tableColFirst'
                          : valueIndex === item.length - 1
                          ? 'tableColLast'
                          : 'tableCol'
                      ]
                    }
                    key={valueIndex}
                  >
                    <Text style={styles.tableCell}>
                      {valueIndex > 0
                        ? new Intl.NumberFormat('en-US', {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                          }).format(Number(value))
                        : value}
                    </Text>
                  </View>
                );
              })}
            </View>
          );
        })}
      </>
    </View>
  );
}

export default TableGrid;
