import styled from 'styled-components';
import { Wrapper } from '../../Common/Message/style';

export const AuthWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  &:before {
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: ${({ theme }) => theme.colors.navy};
    opacity: 0.9;
    z-index: 1;
  }

  &:after {
    content: '';
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url(/assets/map-bg.webp);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  > img {
    position: absolute;
    top: 20px;
    left: calc(50% - 88px);
    z-index: 2;
  }
`;

export const IconCircleWrapper = styled.div`
  display: flex;
  padding: 8px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.green};
  justify-content: center;
  align-items: center;
  margin: 0 auto 10px;
  width: 40px;
  height: 40px;
`;

export const FormWrapper = styled.div<{
  withHorizontalPadding: boolean;
  withMessage?: boolean;
}>`
  width: 500px;
  height: auto;
  max-height: calc(100vh - 200px);
  overflow-y: scroll;
  margin: 183px 0 0;
  background-color: ${({ theme }) => theme.colors.blackII};
  border-radius: 10px;
  padding: 50px;
  display: flex;
  flex-direction: column;
  z-index: 1;
  position: absolute;
  top: ${({ withMessage }) => (withMessage ? '100px' : '0')};
  left: calc(50% - 250px);
  box-shadow: 0 2.3245px 5.70055px 0 rgba(0, 0, 0, 0.03),
    0 5.58609px 13.69921px 0 rgba(0, 0, 0, 0.04),
    0 10.5181px 25.7944px 0 rgba(0, 0, 0, 0.05),
    0 18.7625px 46.0128px 0 rgba(0, 0, 0, 0.07),
    0 35.09319px 86.06187px 0 rgba(0, 0, 0, 0.08),
    0 84px 206px 0 rgba(0, 0, 0, 0.11);

  &::-webkit-scrollbar {
    display: none;
  }

  .MuiTabPanel-root {
    padding: 0;
  }

  .MuiTabs-flexContainer .MuiButtonBase-root {
    min-width: unset;
    padding: 0;
    margin-right: 20px;
  }

  ${Wrapper} {
    width: 100%;
  }
`;

export const Title = styled.h1`
  font-weight: 700;
  font-size: 20px;
  color: ${({ theme }) => theme.colors.silver};
  text-align: center;
  margin-bottom: 30px;
`;

export const Subtitle = styled.p`
  font-size: 16px;
  line-height: 26px;
  color: ${({ theme }) => theme.colors.silver};
  text-align: center;
`;

export const TabsSx = {
  padding: '50px 30px 0',
  margin: '-50px auto 30px',
  minHeight: '105px',
  backgroundColor: 'transparent',
};

export const InputGroup = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
`;

export const TopBanner = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.green};
  border-radius: 10px;
  padding: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  top: 98px;
  min-width: 1000px;
  left: calc(50vw - 500px);

  p {
    color: ${({ theme }) => theme.colors.green};
    margin-left: 20px;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
  }

  ${IconCircleWrapper} {
    margin: 0;
  }
`;
