import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { VALIDATION_ERRORS } from '../../../constants/validation';
import { useResetPasswordMutation } from '../../../redux/api/authApi';
import { validatePassword } from '../../../utils/validation';
import Button from '../../Common/Button';
import LinkButton from '../../Common/LinkButton';
import PasswordInputComponent from '../../Common/PasswordInput';
import Spacer from '../../Common/Spacer';

const ResetPasswordForm = () => {
  const { resetToken } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [repeatPasswordError, setRepeatPasswordError] = useState('');

  const [submit, { isLoading, reset, error }] = useResetPasswordMutation();

  const handleBackToLogin = useCallback(() => {
    navigate('/login');
  }, [navigate]);

  const handleSetPassword = (value: string) => {
    if (value.length > 0 && !validatePassword(value)) {
      setPasswordError(VALIDATION_ERRORS(t).PASSWORD);
    } else {
      setPasswordError('');
    }

    setPassword(value);
  };

  const handleSetRepeatPassword = (value: string) => {
    if (value.length > 0) {
      if (!validatePassword(value)) {
        setRepeatPasswordError(VALIDATION_ERRORS(t).PASSWORD);
      }

      if (value !== password) {
        setRepeatPasswordError(VALIDATION_ERRORS(t).SAME_PASSWORD);
      } else {
        setRepeatPasswordError('');
      }
    } else {
      setRepeatPasswordError('');
    }

    setRepeatPassword(value);
  };

  const handleOnSubmit = useCallback(
    async (e: SyntheticEvent) => {
      e.preventDefault();

      if (passwordError.length > 0 || repeatPasswordError.length > 0) {
        toast.error(VALIDATION_ERRORS(t).GENERIC, {
          toastId: 'reset-password-form-validation-error',
          autoClose: 3000,
        });
        return;
      }

      if (resetToken) {
        const data = await submit({
          password,
          resetToken,
        });
        if (!('error' in data)) {
          navigate('/login?resetSuccess=true');
        }
      }
    },
    [
      passwordError.length,
      repeatPasswordError.length,
      resetToken,
      t,
      submit,
      password,
      navigate,
    ]
  );

  useEffect(() => {
    if (error && 'data' in error) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
      toast.error((error.data as any).message, {
        toastId: 'reset-password-error',
        autoClose: 3000,
      });
      reset();
    }
  }, [error, reset]);

  return (
    <form onSubmit={handleOnSubmit}>
      <Spacer size={'0 0 30px'} />
      <PasswordInputComponent
        value={password}
        onChange={handleSetPassword}
        label={String(t('Create Password'))}
        error={passwordError}
        dataTestId="reset-password-form-password"
        required
        fullWidth
      />
      <Spacer size={'0 0 20px'} />
      <PasswordInputComponent
        value={repeatPassword}
        onChange={handleSetRepeatPassword}
        label={String(t('Repeat new password'))}
        error={repeatPasswordError}
        dataTestId="reset-password-form-repeat-password"
        required
        fullWidth
      />
      <Spacer size="30px 0 0" />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <LinkButton
          dataTestId="reset-password-form-back-btn"
          text={t('Back to Log In')}
          onClick={handleBackToLogin}
          additionalSx={{
            margin: 0,
          }}
        />
        <Button
          disabled={isLoading}
          fullWidth
          variant="purple"
          dataTestId="reset-password-form-submit-btn"
          type="submit"
          additionalSx={{
            width: '190px',
          }}
        >
          {t('Update password')}
        </Button>
      </div>
    </form>
  );
};

export default ResetPasswordForm;
