import { unparse } from 'papaparse';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useOnClickOutside } from 'usehooks-ts';
import Icon from '../../../components/Common/Icon';
import SwitchComponent from '../../../components/Common/Switch';
import { COMMON_FEATURE_BODY_TYPE } from '../../../constants/data-board';
import { usePolygonContext } from '../../../context/Polygon';
import {
  CarbonAccountingData,
  ForestCoverData,
  ForestDataType,
  ForestTemporalType,
} from '../../../context/Polygon/types';
import { useUpdateRegionMutation } from '../../../redux/api/regionApi';
import { openModal } from '../../../redux/features/modal/modal-slice';
import {
  setRegionToEditOrDelete,
  setSelectedPolygon,
} from '../../../redux/features/region/region-slice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { AreaUnitType } from '../../../types/Geo';
import { returnWeightByUnit } from '../../../utils/units';
import {
  Button,
  ContentWrapper,
  Section,
  SubscribeDescription,
  SubscribeDetails,
  SubscribeTitle,
  ToggleWrapper,
} from './style';

const ActionsMenu = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const menuRef = useRef<HTMLDivElement>(null);

  const { selectedPolygon } = useAppSelector((state) => state.regionState);
  const { user } = useAppSelector((state) => state.userState);

  const [visible, setVisible] = useState(false);
  useOnClickOutside(menuRef, () => setVisible(false));

  const [updateRegion, { reset }] = useUpdateRegionMutation();

  const { getPolygonCarbonAccountingData, getPolygonForestCoverData } =
    usePolygonContext();

  const handleSubscribe = useCallback(async () => {
    if (selectedPolygon && selectedPolygon.id) {
      if (selectedPolygon?.isSubscribed) {
        dispatch(openModal('unsubscribeRegion'));
      } else {
        const data = await updateRegion({
          id: String(selectedPolygon?.id),
          body: {
            isSubscribed: true,
          },
        });

        if (!('error' in data)) {
          dispatch(setSelectedPolygon(data.data));
          reset();
        } else {
          toast.error(
            t('We were not able to unsubscribe your region at this time'),
            {
              toastId: 'subscribe-region-error',
              autoClose: 3000,
            }
          );
          reset();
        }
      }
    }
  }, [dispatch, reset, selectedPolygon, t, updateRegion]);

  const handleDownloadGeoJson = useCallback(() => {
    if (!selectedPolygon) return;
    // get GeoJSON from selectedPolygon
    const GeoJSON = {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: selectedPolygon.properties,
          geometry: selectedPolygon.geometry,
        },
      ],
    };
    // create a blob from the GeoJSON
    const blob = new Blob([JSON.stringify(GeoJSON)], {
      type: 'application/json',
    });

    // create a URL for the blob
    const url = URL.createObjectURL(blob);

    // create a link and click it to download the file
    const link = document.createElement('a');
    link.href = url;
    link.download = `${
      String(selectedPolygon.name) ||
      String(selectedPolygon.properties?.name) ||
      'Cyclops-Region'
    }-${new Date().toLocaleDateString()}.geojson`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [selectedPolygon]);

  const handleDownloadCSV = useCallback(async () => {
    if (!selectedPolygon) return;

    const query = COMMON_FEATURE_BODY_TYPE(selectedPolygon);

    const promises = [
      getPolygonCarbonAccountingData(
        query,
        'above-ground-biomass',
        'data',
        true
      ),
      getPolygonCarbonAccountingData(
        query,
        'above-ground-biomass',
        'data/area',
        true
      ),
      getPolygonForestCoverData(
        query,
        ForestTemporalType.LINEAR,
        ForestDataType.AREA,
        true
      ),
      getPolygonForestCoverData(
        query,
        ForestTemporalType.LINEAR,
        ForestDataType.PERCENTAGE,
        true
      ),
    ];

    const [carbonAGB, carbonAGBArea, forestArea, forestPercent] =
      await Promise.all(promises);

    // Flatten data and organize by time
    const dataMap: any = {};

    const addDataToMap = (
      data: CarbonAccountingData[] | ForestCoverData[],
      dataType: string,
      dataField: string
    ) => {
      data.forEach(({ time, ...rest }) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (!dataMap[time]) {
          // Add time as an item on the object and convert it to <Year> <Quarter>
          const timeParts = time.split('-');
          const year = timeParts[0];
          // Parse the month as an integer
          const month = parseInt(timeParts[1], 10);
          // Calculate the quarter
          const quarter = Math.ceil(month / 3);

          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/restrict-template-expressions
          dataMap[time] = { Timestamp: `${year} Q${quarter}` };
        }
        // rest can be CarbonAccountingData or ForestCoverData
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        dataMap[time][dataType] = (rest as any)[dataField];
      });
    };

    // Define CSV headers
    const headers = {
      time: 'Timestamp',
      'above-ground-biomass-area': `${t(
        'Mean Above Ground Biomass'
      )} (${returnWeightByUnit(user?.settings.unit as AreaUnitType)}/${
        user?.settings.unit as AreaUnitType
      })`,
      'above-ground-biomass': `${t('Total Above Ground Biomass')} (megatonnes)`,
      'forest-cover-area': `${t('Total Forested Area')} (${
        user?.settings.unit as AreaUnitType
      })`,
      'non-forest-cover-area': `${t('Total Non-forested Area')} (${
        user?.settings.unit as AreaUnitType
      })`,
      'forest-cover-percentage': t('Forested Area Ratio'),
    };

    if (carbonAGB)
      addDataToMap(carbonAGB, headers['above-ground-biomass'], 'carbon');
    if (carbonAGBArea)
      addDataToMap(
        carbonAGBArea,
        headers['above-ground-biomass-area'],
        'carbon'
      );
    if (forestArea)
      addDataToMap(forestArea, headers['forest-cover-area'], 'forest');
    if (forestArea)
      addDataToMap(
        forestArea,
        headers['non-forest-cover-area'],
        'deforestation'
      );
    if (forestPercent)
      addDataToMap(forestPercent, headers['forest-cover-percentage'], 'forest');

    // Convert JSON data to CSV
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const csv = unparse(Object.values(dataMap), {
      header: true,
      columns: Object.values(headers),
    });

    // Create a Blob from the CSV
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

    // Create a link element
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'data.csv');
    link.style.visibility = 'hidden';

    // Append the link to the document body and trigger the download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [
    t,
    user?.settings.unit,
    selectedPolygon,
    getPolygonCarbonAccountingData,
    getPolygonForestCoverData,
  ]);

  return (
    <div ref={menuRef}>
      {user?.role !== 'free' || selectedPolygon?.userId ? (
        <ToggleWrapper
          data-test-id="actions-menu-toggle"
          onClick={() => setVisible((wasActive) => !wasActive)}
        >
          <Icon variant="THREE_DOTS_HORIZONTAL" size={32} color="white" />
        </ToggleWrapper>
      ) : null}
      <ContentWrapper active={visible} data-test-id="actions-menu-content">
        {selectedPolygon?.userId && user?.role !== 'free' ? (
          <Section isSubscribe>
            <SubscribeDetails>
              <SubscribeTitle data-test-id="actions-menu-section-title">
                {t('Subscribe to Data Notifications')}
              </SubscribeTitle>
              <SubscribeDescription data-test-id="actions-menu-section-description">
                {t(
                  'Receive emails when new data for this region becomes available. You are currently'
                )}{' '}
                <strong>
                  {selectedPolygon?.isSubscribed
                    ? t('subscribed')
                    : t('not subscribed')}
                </strong>
              </SubscribeDescription>
            </SubscribeDetails>
            <SwitchComponent
              checked={selectedPolygon?.isSubscribed || false}
              onToggle={handleSubscribe}
              dataTestId={'actions-menu-toggle-subscribe'}
            />
          </Section>
        ) : null}
        <Section>
          <Button
            data-test-id="actions-menu-export-csv"
            onClick={handleDownloadCSV}
          >
            {t('Export Data (CSV)')}
          </Button>
        </Section>
        {user?.role !== 'free' ? (
          <>
            <Section>
              <Button
                data-test-id="actions-menu-generate-pdf"
                onClick={() => dispatch(openModal('pdfReport'))}
              >
                {t('Generate PDF')}
              </Button>
            </Section>
            <Section>
              <Button
                data-test-id="actions-menu-download-geojson"
                onClick={handleDownloadGeoJson}
              >
                {t('Download GeoJSON')}
              </Button>
            </Section>
          </>
        ) : null}
        {selectedPolygon?.userId ? (
          <Section>
            <Button
              data-test-id="actions-menu-delete-region"
              onClick={() => {
                dispatch(setRegionToEditOrDelete(selectedPolygon));
                dispatch(openModal('deleteRegion'));
              }}
              isRed
            >
              {t('Delete Region')}
            </Button>
          </Section>
        ) : null}
      </ContentWrapper>
    </div>
  );
};

export default ActionsMenu;
