import { setTiles } from '../features/region/region-slice';
import { baseApi } from './index';

export const tilesApi = baseApi
  .enhanceEndpoints({
    addTagTypes: ['Tiles'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getTiles: builder.query<
        GeoJSON.Feature<GeoJSON.Polygon>[],
        {
          returnArray?: boolean;
        }
      >({
        query({ returnArray = false }) {
          return {
            url: '/tiles',
            method: 'GET',
            params: {
              returnArray,
            },
          };
        },
        providesTags: ['Tiles'],
        transformResponse: (
          response: GeoJSON.FeatureCollection<GeoJSON.Polygon>
        ) => {
          return response.features;
        },
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          const { data } = await queryFulfilled;
          dispatch(setTiles(data));
        },
      }),
    }),
  });

export const { useGetTilesQuery } = tilesApi;
