import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { COMMON_FEATURE_BODY_TYPE } from '../../../constants/data-board';
import { usePolygonContext } from '../../../context/Polygon';
import {
  CarbonDataType,
  CarbonDisplayPerType,
  ForestDataType,
  ForestTemporalType,
} from '../../../context/Polygon/types';
import {
  setCarbonDataType,
  setCarbonDisplayPer,
  setForestDataType,
  setForestTemporalType,
} from '../../../redux/features/region/region-slice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { returnWeightByUnit } from '../../../utils/units';
import Select, { Props } from '../../Common/Select';

const Controls = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { getPolygonCarbonAccountingData, getPolygonForestCoverData } =
    usePolygonContext();

  const {
    carbonDataType,
    carbonDisplayPer,
    forestDataType,
    forestTemporalType,
    selectedPolygon,
  } = useAppSelector((state) => state.regionState);
  const { dataBoardTab } = useAppSelector((state) => state.uiState);
  const { user } = useAppSelector((state) => state.userState);

  type CustomSelectProps = {
    value: Props['value'];
    onChange: Props['onChange'];
    dataTestId: Props['dataTestId'];
    label: string;
    options: Props['options'];
    optionWidth?: string;
    additionalSx?: any;
  };

  const IS_CARBON_TAB_SELECTED = useMemo(
    () => dataBoardTab === '0',
    [dataBoardTab]
  );

  /**
   * Sorting controls setup and call of sorting function data fetch
   */
  const handleCarbonDataDisplayChange = useCallback(
    async (val: CarbonDataType) => {
      dispatch(setCarbonDataType(val));
      if (selectedPolygon) {
        await getPolygonCarbonAccountingData(
          COMMON_FEATURE_BODY_TYPE(selectedPolygon),
          val,
          carbonDisplayPer
        );
      }
    },
    [
      dispatch,
      selectedPolygon,
      getPolygonCarbonAccountingData,
      carbonDisplayPer,
    ]
  );

  const handleCarbonDisplayPerChange = useCallback(
    async (val: CarbonDisplayPerType) => {
      dispatch(setCarbonDisplayPer(val));
      if (selectedPolygon) {
        await getPolygonCarbonAccountingData(
          COMMON_FEATURE_BODY_TYPE(selectedPolygon),
          carbonDataType,
          val
        );
      }
    },
    [dispatch, selectedPolygon, getPolygonCarbonAccountingData, carbonDataType]
  );

  const CustomSelect = useCallback(
    ({ value, onChange, dataTestId, label, options }: CustomSelectProps) => (
      <Select
        value={value}
        onChange={onChange}
        dataTestId={dataTestId}
        label={String(t(label))}
        additionalPaperSx={{
          ul: {
            padding: 0,
          },
        }}
        options={options}
      />
    ),
    [t]
  );

  const renderCarbonControls = useCallback(() => {
    const unit =
      carbonDisplayPer === 'data'
        ? t('mton')
        : `${returnWeightByUnit(user?.settings.unit)} / ${
            user?.settings.unit as string
          }`;

    return (
      <>
        <CustomSelect
          value={carbonDisplayPer}
          onChange={handleCarbonDisplayPerChange}
          dataTestId={'data-board-carbon-data-display'}
          label={'Display'}
          options={[
            { value: 'data', label: t('Display per unit') },
            {
              value: 'data/area',
              label: t('Display per unit area stats'),
            },
          ]}
        />
        <CustomSelect
          value={carbonDataType}
          onChange={handleCarbonDataDisplayChange}
          dataTestId="carbon-controls-select"
          label={'Type'}
          optionWidth={'300px'}
          options={[
            {
              label: t('Sequestered Carbon ({{unit}})', { unit }),
              value: 'sequestered-carbon',
            },
            {
              label: t('Above Ground Biomass ({{unit}})', { unit }),
              value: 'above-ground-biomass',
            },
            {
              label: t('Change in Biomass ({{unit}})', { unit }),
              value: 'change-in-biomass',
            },
          ]}
        />
      </>
    );
  }, [
    carbonDisplayPer,
    t,
    user?.settings.unit,
    CustomSelect,
    handleCarbonDisplayPerChange,
    carbonDataType,
    handleCarbonDataDisplayChange,
  ]);

  const handleForestDataTypeChange = useCallback(
    async (val: ForestDataType) => {
      dispatch(setForestDataType(val));
      if (selectedPolygon) {
        await getPolygonForestCoverData(
          COMMON_FEATURE_BODY_TYPE(selectedPolygon),
          forestTemporalType,
          val
        );
      }
    },
    [dispatch, forestTemporalType, getPolygonForestCoverData, selectedPolygon]
  );

  const handleForestTemporalTypeChange = useCallback(
    async (val: ForestTemporalType) => {
      dispatch(setForestTemporalType(val));
      if (selectedPolygon) {
        await getPolygonForestCoverData(
          COMMON_FEATURE_BODY_TYPE(selectedPolygon),
          val,
          forestDataType
        );
      }
    },
    [dispatch, forestDataType, getPolygonForestCoverData, selectedPolygon]
  );
  const renderForestControls = useCallback(() => {
    const unit = user?.settings.unit;

    return (
      <>
        <CustomSelect
          value={forestTemporalType}
          onChange={handleForestTemporalTypeChange}
          dataTestId="forestCover-controls-select-type"
          label={'Display'}
          options={[
            {
              label: t('Forest cover ({{unit}})', { unit }),
              value: ForestTemporalType.LINEAR,
            },
            {
              label: t('YoY Deforestation ({{unit}})', { unit }),
              value: ForestTemporalType.YoY,
            },
          ]}
        />
        <CustomSelect
          value={forestDataType}
          onChange={handleForestDataTypeChange}
          dataTestId="forestCover-controls-select"
          label={'Type'}
          options={[
            {
              value: ForestDataType.PERCENTAGE,
              label: t('Percent'),
            },
            {
              value: ForestDataType.AREA,
              label: t('Area'),
            },
          ]}
        />
      </>
    );
  }, [
    user?.settings.unit,
    CustomSelect,
    forestTemporalType,
    handleForestTemporalTypeChange,
    t,
    forestDataType,
    handleForestDataTypeChange,
  ]);

  const renderControls = useCallback(() => {
    switch (IS_CARBON_TAB_SELECTED) {
      case true: {
        return renderCarbonControls();
      }
      case false: {
        return renderForestControls();
      }
      default: {
        return null;
      }
    }
  }, [IS_CARBON_TAB_SELECTED, renderCarbonControls, renderForestControls]);

  return renderControls() || null;
};

export default Controls;
