import { baseApi } from './index';

export const cacheApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    removeAllTilesCache: builder.mutation<undefined, void>({
      query: () => ({
        url: '/cache/tiles:all',
        method: 'DELETE',
      }),
    }),
    removeAllBiomassCache: builder.mutation<undefined, void>({
      query: () => ({
        url: '/cache/biomass:*',
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useRemoveAllTilesCacheMutation,
  useRemoveAllBiomassCacheMutation,
} = cacheApi;
