import { baseApi } from './index';

export const storageApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    deleteAllStorage: builder.mutation<undefined, void>({
      query: () => ({
        url: '/storage/all',
        method: 'DELETE',
      }),
    }),
    deleteFile: builder.mutation<undefined, string>({
      query: (key) => ({
        url: `/storage/${key}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const { useDeleteAllStorageMutation, useDeleteFileMutation } =
  storageApi;
