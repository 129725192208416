import area from '@turf/area';
import { useAppSelector } from '../../../../redux/hooks';
import {
  AreaUnitType,
  SQ_METER_TO_KILOMETER_RATIO,
} from '../../../../types/Geo';
import { convertSqKmTo } from '../../../../utils/units';
import { FolderItemProperty } from '../FolderList/style';

interface FolderRegionAreaProps {
  region: GeoJSON.Feature<GeoJSON.Polygon>;
}

const FolderRegionArea = ({ region }: FolderRegionAreaProps) => {
  const user = useAppSelector((state) => state.userState.user);

  const calculatedArea = Math.floor(
    convertSqKmTo(
      area(region.geometry) / SQ_METER_TO_KILOMETER_RATIO,
      user?.settings.unit
    )
  );

  if (!user || !calculatedArea) return null;

  return (
    <FolderItemProperty>
      {`${new Intl.NumberFormat('en', {
        maximumFractionDigits: 2,
      }).format(calculatedArea)} ${user?.settings.unit.replace('sq-', '')}${
        calculatedArea > 1 &&
        user?.settings.unit !== AreaUnitType.squareKilometre
          ? 's'
          : ''
      }`}
      {user?.settings.unit === AreaUnitType.squareKilometre ||
      user?.settings.unit === AreaUnitType.squareMile ? (
        <sup>2</sup>
      ) : null}
    </FolderItemProperty>
  );
};

export default FolderRegionArea;
