import { Page, Text } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import {
  CarbonAccountingData,
  ForestCoverData,
} from '../../../../context/Polygon/types';
import Footer from '../../Components/Footer';
import Table from '../../Components/Table';
import { globalStyles } from '../../style';

interface Props {
  carbonSequesteredData: CarbonAccountingData[];
  carbonSequesteredDataArea: CarbonAccountingData[];
  forestCoverArea: ForestCoverData[];
  areaUnit: string;
  regionName: string;
}

const TablePage = ({
  carbonSequesteredData,
  carbonSequesteredDataArea,
  forestCoverArea,
  areaUnit,
  regionName,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Page style={{ ...globalStyles.pageWrapper, paddingBottom: '70px' }}>
      <Text style={globalStyles.headlineBig} fixed>
        {t('Estimation of Above Ground Biomass')}
      </Text>
      <Text style={{ ...globalStyles.headlineBig, marginBottom: '40px' }} fixed>
        {t('& Change in Deforestation')}
      </Text>
      <Table
        carbonSequesteredData={carbonSequesteredData}
        carbonSequesteredDataArea={carbonSequesteredDataArea}
        forestCoverData={forestCoverArea}
        areaUnit={areaUnit}
      />
      <Footer regionName={regionName} />
    </Page>
  );
};
export default TablePage;
