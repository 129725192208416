import styled from 'styled-components';
import { IconWrapper } from '../../Common/Icon/style';
import { InputWrapper } from '../../Common/Input/style';

export const AccountModalSx = {
  minWidth: '100vw',
  backgroundColor: 'var(--blackI)',
  maxWidth: '100vw',
  height: 'calc(100vh - 72px)',
  maxHeight: '100vh',
  margin: '72px 0 0',
  padding: '40px 0 0',
  borderRadius: 0,
  zIndex: 99,
};

export const ModalDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 90px;
  margin-bottom: 50px;
`;

export const ModalDetailsBackToMap = styled.button`
  display: flex;
  border: none;
  font-size: 16px;
  line-height: 21px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 25px;
  background-color: transparent;
  padding: 0;
  transition: ${({ theme }) => theme.transition};
  cursor: pointer;

  &:hover {
    opacity: 0.7;
    transform: translateX(-10px);
  }

  ${IconWrapper} {
    transform: rotate(-90deg);
    margin-top: -2px;
    margin-right: 5px;
  }
`;

export const ModalTitle = styled.p`
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;
  color: ${({ theme }) => theme.colors.silver};
  height: 41px;
`;

export const ModalContent = styled.div`
  display: flex;

  .MuiTabPanel-root {
    flex-grow: 1;
    position: relative;
  }
`;

export const Title = styled.h3`
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 10px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.grey};
`;

export const Subtitle = styled.p`
  font-size: 20px;
  line-height: 26px;
  color: ${({ theme }) => theme.colors.white};
`;

export const Link = styled.a`
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.purple};
`;

export const TabsWrapperSx = {
  height: 'auto',
  maxWidth: '300px',
  width: '300px',

  '.MuiTabs-indicator': {
    left: 0,
    backgroundColor: 'var(--green)',
  },
};

export const TabContentWrapper = styled.div<{ withFooter?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1100px;
  flex-grow: 1;
  width: 100%;
  padding: 50px;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.colors.blackII};
  border-radius: ${({ withFooter }) => (withFooter ? '0 0 10px 10px' : '10px')};
`;

export const ProText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: ${({ theme }) => theme.colors.grey};
`;

export const UpdateProWrapper = styled.div`
  height: 690px;
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.black};
  border-radius: 10px;
`;

export const UpgradeProTitle = styled.p`
  font-weight: 600;
  font-size: 30px;
  margin-bottom: 24px;
  line-height: 36px;
  color: ${({ theme }) => theme.colors.white};
`;

export const UpgradeProText = styled.div`
  width: 300px;
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;
  color: ${({ theme }) => theme.colors.lightGrey};
`;

export const ContactFormWrapper = styled.div`
  height: 572px;
  width: 440px;
  padding: 23px 30px 30px;
  background-color: ${({ theme }) => theme.colors.navy};
  border-radius: 10px;

  ${InputWrapper} {
    margin-bottom: 14px;
  }
`;

export const ContactFormTitle = styled.p`
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 30px;
  line-height: 27px;
  text-align: left;
  color: ${({ theme }) => theme.colors.white};
`;

export const InputSx = {
  width: '380px',
};

export const APIKeyText = styled.p`
  color: ${({ theme }) => theme.colors.silver};
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  text-align: center;
`;

export const AccountSectionTitle = styled.h3`
  color: ${({ theme }) => theme.colors.silver};
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  margin-bottom: 30px;
`;

export const AccountFooterWrapper = styled.div`
  display: flex;
  padding: 20px 30px;
  border-radius: 0 0 10px 10px;
  position: absolute;
  bottom: -85px;
  left: 20px;
  width: calc(100% - 40px);
  background-color: ${({ theme }) => theme.colors.blackIII};
`;
