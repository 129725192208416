import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMap } from 'react-map-gl';
import { useLocation, useNavigate } from 'react-router-dom';
import { NAVIGATION_STEPS } from '../../../constants/joyride';
import { INITIAL_MAP_VIEW_STATE } from '../../../constants/map';
import { useMapContext } from '../../../context/Map';
import { usePolygonContext } from '../../../context/Polygon';
import useJoyride from '../../../hooks/useJoyride';
import { resetDrawSliceState } from '../../../redux/features/draw/draw-slice';
import { resetJoyrideState } from '../../../redux/features/joyride/joyride-slice';
import {
  closeModal,
  openModal,
} from '../../../redux/features/modal/modal-slice';
import {
  resetUIState,
  setShowNotifications,
} from '../../../redux/features/ui/ui-slice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { ContentWrapper } from '../../../styles/Global';
import Icon from '../../Common/Icon';
import Logo from '../../Common/Logo';
import Menu from './Menu';
import {
  MenuNavigationWrapper,
  NavigationItem,
  NavigationList,
  NavWrapper,
  NotificationsButton,
  NotificationsCounter,
  UserPlaceholderAvatar,
  Wrapper,
} from './style';

interface Props {
  version?: 'auth' | 'normal' | 'admin';
}

const Header = ({ version = 'normal' }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { mapRoot } = useMap();

  const { resetPolygonData } = usePolygonContext();
  const { removeMapSelection } = useMapContext();
  const navigate = useNavigate();
  const location = useLocation();

  const { user } = useAppSelector((state) => state.userState);
  const { messages } = useAppSelector((state) => state.socketState);
  const unreadMessages = useMemo(
    () => messages.filter((message) => !message.wasOpened),
    [messages]
  );

  const handleClickExplore = useCallback(() => {
    if (location.pathname.includes('/portfolio')) {
      navigate('/');
    }

    resetPolygonData();
    removeMapSelection();
    dispatch(resetUIState());
    dispatch(resetJoyrideState());
    dispatch(resetDrawSliceState());
    dispatch(closeModal());
    mapRoot?.flyTo(INITIAL_MAP_VIEW_STATE);
  }, [
    removeMapSelection,
    mapRoot,
    resetPolygonData,
    dispatch,
    navigate,
    location,
  ]);

  const handleOpenNotifications = useCallback(() => {
    handleClickExplore();
    dispatch(setShowNotifications(true));
  }, [handleClickExplore, dispatch]);

  const isFreeUser = useMemo(() => user?.role === 'free', [user]);

  useJoyride(NAVIGATION_STEPS(t));

  return (
    <Wrapper>
      <ContentWrapper>
        <NavWrapper>
          <Logo data-test-id={'header-logo'} />
          <NavigationList>
            <NavigationItem
              onClick={handleClickExplore}
              data-test-id={'header-item-explore'}
            >
              <span>{t('Explore')}</span>
            </NavigationItem>
            <NavigationItem
              onClick={
                !isFreeUser ? () => navigate('/portfolio/summary') : undefined
              }
              data-test-id={'header-item-portfolio'}
              disabled={isFreeUser}
              showProLabel={isFreeUser}
            >
              <span>{t('My Portfolio')}</span>
            </NavigationItem>
            <NavigationItem data-test-id={'header-item-about'}>
              <a
                href={'https://cyclops.ai'}
                target={'_blank'}
                rel={'noreferrer'}
              >
                {t('About')}
              </a>
            </NavigationItem>
            <NavigationItem
              onClick={() => dispatch(openModal('contactUs'))}
              data-test-id={'header-item-contact'}
            >
              <span>{t('Contact')}</span>
            </NavigationItem>
          </NavigationList>
        </NavWrapper>
        <MenuNavigationWrapper>
          {version === 'normal' && user ? (
            <NotificationsButton
              onClick={handleOpenNotifications}
              data-test-id={'header-item-notifications-button'}
            >
              <NotificationsCounter
                active={messages.length > 0 && unreadMessages.length > 0}
                data-test-id={'header-item-notifications-counter'}
              >
                {unreadMessages.length > 9 ? '9+' : unreadMessages.length}
              </NotificationsCounter>
              <Icon
                variant={
                  unreadMessages?.length > 0 ? 'BELL_FILLED' : 'BELL_OUTLINE'
                }
                size={40}
                color={unreadMessages?.length > 0 ? 'purple' : 'darkGray'}
              />
            </NotificationsButton>
          ) : null}
          {version !== 'auth' ? (
            <Menu />
          ) : (
            <UserPlaceholderAvatar>
              <Icon variant="USER" size={24} color="blackI" />
            </UserPlaceholderAvatar>
          )}
        </MenuNavigationWrapper>
      </ContentWrapper>
    </Wrapper>
  );
};

export default Header;
