import { Socket } from 'socket.io-client';

export interface ISocketContext {
  joinRoom: (socket: Socket) => void;
  emit: (event: string, data: any, socket: Socket) => void;
  disconnect: () => void;
}

export const defaultState: ISocketContext = {
  joinRoom: () => {},
  emit: () => {},
  disconnect: () => {},
};
