import styled from 'styled-components';

export const SearchBarSX = {
  '.MuiInput-root .MuiInput-input': {
    padding: '0px',
  },
  '.MuiInputBase-root::after': {
    borderBottom: '1px solid var(--green)',
  },
};

export const AutoCompleteSX = {
  marginBottom: '50px',
  '.MuiInput-root': {
    paddingBottom: '10px',
  },
};

export const InputSX = (hasValue: boolean) => {
  return {
    borderBottom: '1px solid var(--blackIV)',
    input: {
      paddingBottom: 0,
      height: '24px',
      fontSize: '16px',
      fontWeight: 500,
      color: hasValue ? 'var(--silver)' : 'var(--gray)',
    },
  };
};
export const SearchBarPaperSX = {
  background: 'var(--blackIII)',
  borderRadius: '8px',
  boxShadow:
    '0px 2.324px 5.701px 0px rgba(0, 0, 0, 0.03), 0px 5.586px 13.699px 0px rgba(0, 0, 0, 0.04), 0px 10.518px 25.794px 0px rgba(0, 0, 0, 0.05), 0px 18.762px 46.013px 0px rgba(0, 0, 0, 0.07), 0px 35.093px 86.062px 0px rgba(0, 0, 0, 0.08), 0px 84px 206px 0px rgba(0, 0, 0, 0.11)',
};

export const OptionItem = styled.button`
  background-color: transparent;
  border: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: ${({ theme }) => theme.colors.silver};
  cursor: pointer;
  text-align: left;
  width: 100%;
  padding: 11px 14px;

  &:hover {
    color: ${({ theme }) => theme.colors.green};
  }
`;
