import styled from 'styled-components';

export const AccountUpdateFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const ButtonsWrapper = styled.div`
  display: inline-grid;
  grid-template-columns: auto 1px auto;
  grid-gap: 30px;
  margin-top: 30px;
`;

export const ButtonSeparator = styled.div`
  background-color: ${({ theme }) => theme.colors.darkGray};
  width: 1px;
  height: 24px;
`;

export const SideBySideWrapper = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;
`;

export const MessageModalSx = {
  width: '456px',
};

export const MessageButtonSx = {
  width: '188px',
  height: '44px',
};

export const EmailConfirmationMessage = styled.p`
  color: ${({ theme }) => theme.colors.grey};
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
`;
