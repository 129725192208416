import { Image, Page, Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { ISelectedPolygon } from '../../../../types/API/Region';
import { AreaUnitType, IDeforestationResponse } from '../../../../types/Geo';
import { convertUnitToFullName } from '../../../../utils/units';
import Footer from '../../Components/Footer';
import { globalStyles } from '../../style';
import { styles } from './style';

interface Props {
  deforestationData: IDeforestationResponse | null;
  mapCanvasData: string | null;
  selectedPolygon: ISelectedPolygon;
  calculatedArea: number;
  areaUnit: AreaUnitType;
}

const RegionOverviewPage = ({
  deforestationData,
  mapCanvasData,
  selectedPolygon,
  calculatedArea,
  areaUnit,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Page style={globalStyles.pageWrapper}>
      <Text style={globalStyles.headlineBig}>{t('Region Overview')}</Text>
      <View style={styles.regionInfoWrapper}>
        {selectedPolygon.name ? (
          <View style={styles.regionInfoItemWrapper}>
            <Text style={styles.regionInfoTitle}>{t('Region name')}</Text>
            <Text style={styles.regionInfoText}>{selectedPolygon.name}</Text>
          </View>
        ) : null}
        <View style={styles.regionInfoItemWrapper}>
          <Text style={styles.regionInfoTitle}>{t('Region coordinates')}</Text>
          {selectedPolygon.geometry
            ? selectedPolygon.geometry.coordinates[0].map((el, index) =>
                index < 5 ? (
                  <Text
                    style={styles.regionInfoText}
                    key={`coordinate-${String(el[0])}-${String(el[1])}`}
                  >
                    {t(
                      `${index + 1}) Long: ${new Intl.NumberFormat('en', {
                        maximumFractionDigits: 4,
                      }).format(el[0])}, Lat: ${new Intl.NumberFormat('en', {
                        maximumFractionDigits: 4,
                      }).format(el[1])}`
                    )}
                  </Text>
                ) : null
              )
            : null}
          {selectedPolygon.geometry.coordinates[0].length > 5 ? (
            <Text style={styles.regionInfoText}>
              {t(
                `...${selectedPolygon.geometry.coordinates[0].length - 5} more`
              )}
            </Text>
          ) : null}
        </View>
        <View style={styles.regionInfoItemWrapper}>
          <Text style={styles.regionInfoTitle}>{t('Region area')}</Text>
          <Text style={styles.regionInfoText}>
            {`${new Intl.NumberFormat('en', {
              maximumFractionDigits: 2,
            }).format(calculatedArea)} ${convertUnitToFullName(areaUnit)}`}
          </Text>
        </View>
        <View style={styles.regionInfoItemWrapper}>
          <Text style={styles.regionInfoTitle}>
            {t('Subscribed to updates')}
          </Text>
          <Text style={styles.regionInfoText}>
            {selectedPolygon.isSubscribed ? t('Yes') : t('No')}
          </Text>
        </View>

        {selectedPolygon.updatedAt ? (
          <View style={styles.regionInfoItemWrapper}>
            <Text style={styles.regionInfoTitle}>{t('Last data update')}</Text>
            <Text style={styles.regionInfoText}>
              {new Date(selectedPolygon.updatedAt).toLocaleDateString()}
            </Text>
          </View>
        ) : null}
      </View>
      {deforestationData && mapCanvasData ? (
        <View style={styles.imagesWrapper}>
          <View style={styles.contentWrapper}>
            <View style={styles.deforestationImageWrapper}>
              <Image
                src={deforestationData.data[deforestationData.data.length - 1]}
                style={styles.deforestationImage}
              />
            </View>
            <Text
              style={{
                ...globalStyles.headlineTiny,
                ...globalStyles.noMarginLeft,
              }}
            >
              {t('Latest deforestation')}
            </Text>
          </View>
          <View style={styles.contentWrapper}>
            <View style={styles.mapCanvasWrapper}>
              <Image src={mapCanvasData} style={styles.mapCanvasImage} />
            </View>
            <Text
              style={{
                ...globalStyles.headlineTiny,
                ...globalStyles.noMarginLeft,
              }}
            >
              {t('Zoomed image region')}
            </Text>
          </View>
        </View>
      ) : null}
      <Footer regionName={selectedPolygon.name} />
    </Page>
  );
};

export default RegionOverviewPage;
