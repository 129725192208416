import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMap } from 'react-map-gl';
import { useParams, useSearchParams } from 'react-router-dom';
import { useReadLocalStorage } from 'usehooks-ts';
import Layout from '../components/Layout';
import Map from '../components/Map';
import Sidebar from '../components/Sidebar';
import { USER_SOURCE_NAME } from '../constants/map';
import { HINT_DISMISSED_LS } from '../constants/user';
import { useMapContext } from '../context/Map';
import { useSidebarContext } from '../context/Sidebar';
import { useGetSplitPolygonDataMutation } from '../redux/api/biomassApi';
import { useGetPublicProjectsWithBiomassDataQuery } from '../redux/api/publicProjectsApi';
import { useGetUserRegionsQuery } from '../redux/api/regionApi';
import { useGetTilesQuery } from '../redux/api/tilesApi';
import { openModal } from '../redux/features/modal/modal-slice';
import { setApiKey } from '../redux/features/user/user-slice';
import { useAppDispatch, useAppSelector } from '../redux/hooks';

const Explore = () => {
  useGetTilesQuery({
    returnArray: false,
  });
  useGetPublicProjectsWithBiomassDataQuery();
  const dispatch = useAppDispatch();
  const { isLoading } = useGetUserRegionsQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const { t } = useTranslation();
  const hintSeen = useReadLocalStorage(HINT_DISMISSED_LS);
  const { showSidebar } = useAppSelector((state) => state.uiState);
  const { userTiles } = useAppSelector((state) => state.regionState);
  const [verifySplitPolygon] = useGetSplitPolygonDataMutation();
  const { mapRoot } = useMap();
  const { handleTileClick } = useMapContext();

  const { generateSidebarContent } = useSidebarContext();
  const [apiKeyCleared, setApiKeyCleared] = useState(false);
  const { apiKey } = useParams();
  const [searchParams] = useSearchParams();
  const region = searchParams.get('region');

  useEffect(() => {
    if (apiKey && !apiKeyCleared) {
      dispatch(setApiKey(apiKey));
      dispatch(openModal('apiKey'));
      window.history.replaceState({}, '', '/');
      setApiKeyCleared(true);
    }
  }, [apiKey, apiKeyCleared, dispatch, hintSeen, t]);

  const handleOpenRegion = useCallback(async () => {
    const regionData = userTiles.find((tile) => tile.id === region);

    if (regionData) {
      const regionWithOriginalGeometry = {
        ...regionData,
        properties: {
          ...regionData.properties,
          originalGeometry: regionData.geometry,
        },
      };

      await verifySplitPolygon({
        feature: regionWithOriginalGeometry,
        omitCache: false,
        withStateSet: true,
      });
      handleTileClick(regionWithOriginalGeometry, USER_SOURCE_NAME);
      window.history.replaceState({}, '', '/');
    }
  }, [handleTileClick, region, userTiles, verifySplitPolygon]);

  useEffect(() => {
    if (region) {
      void handleOpenRegion();
    }
  }, [dispatch, handleOpenRegion, mapRoot, region, userTiles]);

  return (
    <Layout isLoading={isLoading}>
      <Sidebar
        isOpen={showSidebar}
        content={showSidebar ? generateSidebarContent()?.content : null}
        header={showSidebar ? generateSidebarContent()?.header : null}
        footer={showSidebar ? generateSidebarContent()?.footer : null}
      />
      <Map isInteractive={true} />
    </Layout>
  );
};

export default Explore;
