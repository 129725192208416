import { ForestTemporalType } from '../../../context/Polygon/types';
import { useAppSelector } from '../../../redux/hooks';
import AreaChart from '../../Chart/AreaChart';
import LineChart from '../../Chart/LineChart';

const ForestCover = () => {
  const { forestTemporalType, forestCoverChartData } = useAppSelector(
    (state) => state.regionState
  );

  return forestTemporalType !== ForestTemporalType.YoY ? (
    <AreaChart
      dataTestId="forest-cover-area-chart"
      data={forestCoverChartData}
    />
  ) : (
    <LineChart
      dataTestId="forest-cover-line-chart"
      data={forestCoverChartData}
    />
  );
};

export default ForestCover;
