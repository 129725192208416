import { MouseEvent, RefObject, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { openModal } from '../../../../redux/features/modal/modal-slice';
import {
  setRegionIdsToEditOrDelete,
  setRegionToEditOrDelete,
} from '../../../../redux/features/region/region-slice';
import { useAppDispatch } from '../../../../redux/hooks';
import { IFolderResponse } from '../../../../types/API/Folder';
import { IRegionResponse } from '../../../../types/API/Region';
import {
  DropdownMenu,
  DropdownToggle,
} from '../../../Admin/PublicProjects/style';
import Icon from '../../../Common/Icon';
import PolygonCanvas from '../../../Common/PolygonCanvas';
import MultiPolygonCanvas from '../../../Common/PolygonCanvas/MultipolygonCanvas';
import { ItemMap, MenuItem } from '../../../SavedRegions/style';
import FolderRegionArea from '../FolderRegionArea';
import {
  FolderItem,
  FolderItemDetails,
  FolderItemName,
  FolderItemProperties,
  FolderItemProperty,
  FolderListWrapper,
  FolderToggle,
} from './style';

interface FolderListProps {
  currentFolder: IFolderResponse;
  selectMode: boolean;
  selectedProjects: string[];
  handleSelectFolder: (id: IRegionResponse['id']) => void;
  menuToShow: number;
  menuRef: RefObject<HTMLButtonElement>;
  handleOpenMenu: (e: MouseEvent<HTMLButtonElement>, index: number) => void;
  handleCloseMenu: () => void;
}

const FolderList = ({
  currentFolder,
  selectMode,
  selectedProjects,
  handleSelectFolder,
  menuToShow,
  menuRef,
  handleOpenMenu,
  handleCloseMenu,
}: FolderListProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleLinkClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      handleCloseMenu();
    },
    [handleCloseMenu]
  );

  const handleDeleteRegion = useCallback(
    (e: MouseEvent<HTMLButtonElement>, region: IRegionResponse) => {
      handleLinkClick(e);
      dispatch(setRegionToEditOrDelete(region));
      dispatch(openModal('deleteRegion'));
    },
    [dispatch, handleLinkClick]
  );

  const handleEditRegion = useCallback(
    (e: MouseEvent<HTMLButtonElement>, region: IRegionResponse) => {
      handleLinkClick(e);
      dispatch(setRegionToEditOrDelete(region));
      dispatch(openModal('editRegion'));
    },
    [dispatch, handleLinkClick]
  );

  const handleMoveToFolder = useCallback(
    (e: MouseEvent<HTMLButtonElement>, region: IRegionResponse) => {
      handleLinkClick(e);
      if (region.id) {
        dispatch(setRegionIdsToEditOrDelete([String(region.id)]));
        dispatch(openModal('changeRegionsFolder'));
      }
    },
    [dispatch, handleLinkClick]
  );

  const handleViewOnMap = useCallback(
    (e: MouseEvent<HTMLButtonElement>, region: IRegionResponse) => {
      handleLinkClick(e);
      window.open(`/?region=${String(region.id)}`, '_blank');
    },
    [handleLinkClick]
  );

  return (
    <FolderListWrapper>
      {currentFolder.regions?.map((region, index) => (
        <FolderItem
          key={region.id}
          onClick={
            selectMode
              ? () => handleSelectFolder(region.id)
              : () =>
                  navigate(
                    `/portfolio/folder/${currentFolder.id}/project/${
                      region.id || ''
                    }`,
                    {
                      relative: 'path',
                    }
                  )
          }
        >
          <ItemMap
            style={{
              margin: 0,
            }}
          >
            {region.geometry.type === 'Polygon' ? (
              <PolygonCanvas
                // data-test-id={`saved-regions-region-polygon-canvas-${index}`}
                width={71}
                fillColor="rgba(20, 162, 241, 0.56)"
                strokeColor="rgba(20, 162, 241, 1)"
                height={71}
                polygon={region}
              />
            ) : (
              <MultiPolygonCanvas
                // data-test-id={`saved-regions-region-multipolygon-canvas-${index}`}
                fillColor="rgba(20, 162, 241, 0.56)"
                strokeColor="rgba(20, 162, 241, 1)"
                width={71}
                height={71}
                polygon={
                  region as unknown as GeoJSON.Feature<GeoJSON.MultiPolygon>
                }
              />
            )}
          </ItemMap>
          <FolderItemDetails>
            <FolderItemName>{region.name}</FolderItemName>
            <FolderItemProperties>
              {region.properties?.type ? (
                <FolderItemProperty>
                  {region.properties.type}
                </FolderItemProperty>
              ) : null}
              <FolderRegionArea region={region} />
              {region.properties?.registry ? (
                <FolderItemProperty>
                  {region.properties?.registry}
                </FolderItemProperty>
              ) : null}
            </FolderItemProperties>
          </FolderItemDetails>
          <FolderToggle ref={menuRef}>
            {selectMode ? (
              <div
                style={{
                  marginTop: selectedProjects.includes(String(region.id))
                    ? '-3px'
                    : 0,
                  marginRight: selectedProjects.includes(String(region.id))
                    ? '-3px'
                    : 0,
                }}
              >
                <Icon
                  variant={
                    selectedProjects.includes(String(region.id))
                      ? 'SELECT_CHECKED'
                      : 'SELECT'
                  }
                  size={selectedProjects.includes(String(region.id)) ? 24 : 18}
                  color={
                    selectedProjects.includes(String(region.id))
                      ? 'green'
                      : 'silver'
                  }
                  data-test-id="portfolio-folders-list-select"
                />
              </div>
            ) : (
              <>
                <DropdownToggle
                  onClick={(e) => handleOpenMenu(e, index)}
                  data-test-id="portfolio-folders-list-toggle"
                >
                  <Icon
                    variant="THREE_DOTS_HORIZONTAL"
                    size={24}
                    color="white"
                  />
                </DropdownToggle>
                <DropdownMenu
                  position="right"
                  active={menuToShow === index}
                  data-test-id="portfolio-folders-list-menu"
                  style={{
                    right: '25px',
                  }}
                >
                  <MenuItem
                    data-test-id="portfolio-folders-list-edit"
                    onClick={(e) => handleViewOnMap(e, region)}
                  >
                    {t('View on Map')}
                  </MenuItem>
                  <MenuItem
                    data-test-id="portfolio-folders-list-download"
                    onClick={(e) => handleEditRegion(e, region)}
                  >
                    {t('Edit Details')}
                  </MenuItem>
                  <MenuItem
                    data-test-id="portfolio-folders-list-move-to-folder"
                    onClick={(e) => handleMoveToFolder(e, region)}
                  >
                    {t('Move to Folder')}
                  </MenuItem>
                  <MenuItem
                    data-test-id="portfolio-folders-list-delete"
                    onClick={(e) => handleDeleteRegion(e, region)}
                  >
                    {t('Delete Project')}
                  </MenuItem>
                </DropdownMenu>
              </>
            )}
          </FolderToggle>
        </FolderItem>
      ))}
    </FolderListWrapper>
  );
};

export default FolderList;
