import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setDrawnFeatureName } from '../../../redux/features/draw/draw-slice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  NameInput,
  NameInputError,
  NameInputLabel,
  NameInputLabelsWrapper,
  Title,
} from './style';

const Header = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [nameError, setNameError] = useState('');

  const { drawnFeatureName } = useAppSelector((state) => state.drawState);

  useEffect(() => {
    window.addEventListener(
      'message',
      (
        e: MessageEvent<{
          type: string;
          payload: { message: string };
        }>
      ) => {
        // add typings for this
        if (e.data.type === 'region-name-error') {
          setNameError(e.data.payload.message);
        }
      }
    );
  }, []);

  // Name reset & remove event listener
  useEffect(() => {
    return () => {
      window.removeEventListener('message', () => {});
      dispatch(setDrawnFeatureName(''));
    };
  }, [dispatch]);

  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setNameError('');
      dispatch(setDrawnFeatureName(e.target.value));
    },
    [dispatch]
  );

  return (
    <>
      <Title>{t('Edit Region')}</Title>
      <NameInputLabelsWrapper>
        <NameInputLabel htmlFor="regionName">{t('Region Name')}</NameInputLabel>
        {nameError.length > 0 ? (
          <NameInputError data-test-id="edit-region-name-error">
            {nameError}
          </NameInputError>
        ) : null}
      </NameInputLabelsWrapper>
      <NameInput
        placeholder={String(t('Untitled'))}
        value={drawnFeatureName}
        id="regionName"
        onChange={handleOnChange}
        data-test-id="edit-region-name-input"
      />
    </>
  );
};

export default Header;
