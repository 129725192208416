import Dialog from '@mui/material/Dialog';
import { ReactNode } from 'react';
import {
  closeModal,
  ModalType,
} from '../../../redux/features/modal/modal-slice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import Icon from '../Icon';
import {
  CloseIcon,
  ModalSX,
  ModalTitle,
  TitlePositionVariations,
  TitleSizeVariations,
} from './style';

export interface Props {
  children: ReactNode;
  withCloseIcon?: boolean;
  title?: string;
  titleSize?: TitleSizeVariations;
  titlePosition?: TitlePositionVariations;
  additionalSx?: any;
  zIndex?: number;
  disableEscapeKeyDown?: boolean;
  disableBackdropClick?: boolean;
  dataTestId?: string;
  modalType?: ModalType;
  open?: boolean;
  onClose?: () => void;
  hideBackdrop?: boolean;
}

const Modal = ({
  withCloseIcon = true,
  children,
  title,
  titleSize = 'large',
  titlePosition = 'left',
  additionalSx,
  zIndex,
  disableEscapeKeyDown = false,
  disableBackdropClick = false,
  dataTestId,
  modalType,
  open,
  onClose,
  hideBackdrop = false,
}: Props) => {
  const { activeModal } = useAppSelector((state) => state.modalState);
  const dispatch = useAppDispatch();
  /**
   *
   * @param reason Prevents close was triggered by backdrop click and backdrop click is disabled
   * @returns
   */
  const handleOnClose = (_: any, reason: any) => {
    if (reason && reason === 'backdropClick' && disableBackdropClick) {
      return;
    }
    if (onClose) onClose();
    dispatch(closeModal());
  };

  const handleCloseIconClick = () => {
    if (onClose) onClose();
    dispatch(closeModal());
  };

  return (
    <Dialog
      open={modalType === activeModal || !!open}
      onClose={handleOnClose}
      data-test-id={dataTestId}
      sx={{
        zIndex: zIndex || undefined,
        '.MuiBackdrop-root': {
          backgroundColor: 'rgba(31, 42, 52, 0.8)',
        },
      }}
      disableEscapeKeyDown={disableEscapeKeyDown}
      hideBackdrop={hideBackdrop}
      PaperProps={{
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        sx: { ...ModalSX, ...additionalSx },
      }}
    >
      {withCloseIcon ? (
        <CloseIcon
          onClick={handleCloseIconClick}
          data-test-id={dataTestId && `${dataTestId}-close`}
        >
          <Icon variant="X_CIRCLE" color="grey" size={24} />{' '}
        </CloseIcon>
      ) : null}
      {title ? (
        <ModalTitle
          titleSize={titleSize}
          titlePosition={titlePosition}
          data-test-id={dataTestId && `${dataTestId}-title`}
        >
          {title}
        </ModalTitle>
      ) : null}
      {children}
    </Dialog>
  );
};

export default Modal;
