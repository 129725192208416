export interface IRegionContext {
  reportRegion: (
    polygon: GeoJSON.FeatureCollection,
    from: string
  ) => Promise<{ success: boolean }>;
}

export const defaultState: IRegionContext = {
  reportRegion: async () => Promise.resolve({ success: false }),
};
