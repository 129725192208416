import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { toast } from 'react-toastify';
import { io, Socket } from 'socket.io-client';
import { USER_SOURCE_NAME } from '../../constants/map';
import { useGetSplitPolygonDataMutation } from '../../redux/api/biomassApi';
import {
  setMessages,
  updateMessage,
} from '../../redux/features/socket/socket-slice';
import { resetUIState } from '../../redux/features/ui/ui-slice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { IRegionResponse } from '../../types/API/Region';
import { useMapContext } from '../Map';
import { usePolygonContext } from '../Polygon';
import { defaultState, ISocketContext } from './types';

const SocketContext = createContext<ISocketContext>(defaultState);

interface Props {
  children?: ReactNode;
}

export interface IMessage {
  createdAt: Date;
  id: string;
  ignore?: boolean;
  recipientUserId: string;
  payload: {
    message: string;
    type: string;
    region?: IRegionResponse;
  };
  wasOpened: boolean;
}

export const SocketProvider = ({ children }: Props) => {
  const dispatch = useAppDispatch();
  const socketRef = useRef<Socket | null>(null);

  const [verifySplitPolygon] = useGetSplitPolygonDataMutation();

  const { user } = useAppSelector((state: RootState) => state.userState);
  const { selectedPolygon } = useAppSelector((state) => state.regionState);
  const { messages } = useAppSelector((state) => state.socketState);

  const { handleTileClick } = useMapContext();
  const { resetPolygonData, getPolygonData } = usePolygonContext();

  const disconnect: ISocketContext['disconnect'] = useCallback(() => {
    socketRef.current?.removeAllListeners();
    socketRef.current?.disconnect();
  }, []);

  const joinRoom: ISocketContext['joinRoom'] = useCallback(
    (socket: Socket) => {
      socket.emit('room', user?.id);
    },
    [user?.id]
  );

  const emit: ISocketContext['emit'] = useCallback(
    (event, data, socket: Socket) => {
      socket.emit(event, data);
    },
    []
  );

  const handleDataReadyNotificationClick = useCallback(
    async (message: IMessage) => {
      resetPolygonData();

      const feature = message.payload.region;
      if (!feature) return;

      const newMessage = {
        ...message,
        wasOpened: true,
      };
      dispatch(updateMessage(newMessage));

      await verifySplitPolygon({
        feature,
        omitCache: false,
        withStateSet: true,
      });
      dispatch(resetUIState());
      handleTileClick(feature, USER_SOURCE_NAME);
    },
    [resetPolygonData, verifySplitPolygon, dispatch, handleTileClick]
  );

  const onEvent = useCallback(
    (message: IMessage) => {
      // Only add a message to state if it was sent to the current user
      // Could be extended in the future if something else is needed
      if (message.recipientUserId !== user?.id || message.ignore) return;
      // If the message is already in the state, don't add it again
      if (messages.some((msg) => msg.id === message.id)) return;

      // Add the message to state
      dispatch(setMessages([...messages, message]));

      // Show a toast message if the message is not ignored
      switch (message.payload.type) {
        case 'data-ready':
          {
            // public project doesn't have id,
            // so we need to check additionally if pdfUrl from properties matches
            if (
              selectedPolygon?.id === message.payload.region?.id ||
              selectedPolygon?.properties?.pdfUrl ===
                message.payload.region?.properties?.pdfUrl
            ) {
              getPolygonData();
            }

            toast.info(() => (
              <div onClick={() => handleDataReadyNotificationClick(message)}>
                {message.payload.message}
              </div>
            ));
          }
          break;
        default:
          toast.info(message.payload.message);
          break;
      }
    },
    [
      user?.id,
      messages,
      dispatch,
      selectedPolygon?.id,
      selectedPolygon?.properties?.pdfUrl,
      getPolygonData,
      handleDataReadyNotificationClick,
    ]
  );

  const attachListeners = useCallback(
    (socket: Socket) => {
      socket.on('event', onEvent);
    },
    [onEvent]
  );

  const createSocket = useCallback(() => {
    if (user?.id) {
      const socket = io(String(process.env.REACT_APP_API_URL), {
        withCredentials: true,
      });
      attachListeners(socket);
      joinRoom(socket);
      socketRef.current = socket;
    }
  }, [attachListeners, joinRoom, user?.id]);

  useEffect(() => {
    createSocket();
    return () => {
      disconnect();
    };
  }, [attachListeners, createSocket, disconnect]);

  return (
    <SocketContext.Provider
      value={{
        disconnect,
        emit,
        joinRoom,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export const useSocketContext = () => useContext(SocketContext);
