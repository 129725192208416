import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { resetJoyrideState } from '../../../redux/features/joyride/joyride-slice';
import { closeModal } from '../../../redux/features/modal/modal-slice';
import { useAppDispatch } from '../../../redux/hooks';
import Button from '../../Common/Button';
import Modal from '../../Common/Modal';
import Spacer from '../../Common/Spacer';
import { ModalSx } from '../DeleteRegionModal/style';
import { ButtonWrapper, Subtitle, Title } from '../ModalCommon.style';

const JoyrideWelcomeModal = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleClose = useCallback(() => {
    localStorage.setItem('welcomeJoyrideDisplayed', 'true');
    dispatch(closeModal());
  }, [dispatch]);

  const handleSkipEntireJoyride = useCallback(() => {
    localStorage.setItem('skipJoyride', 'true');
    localStorage.setItem('welcomeJoyrideDisplayed', 'true');
    dispatch(resetJoyrideState());
    dispatch(closeModal());
  }, [dispatch]);

  return (
    <Modal
      additionalSx={ModalSx}
      withCloseIcon={false}
      disableBackdropClick
      disableEscapeKeyDown
      dataTestId="joyride-welcome-modal"
      modalType="joyrideWelcome"
    >
      <Title data-test-id="joyride-welcome-modal-title">
        {t('Welcome to the CYCLOPS platform!')}
      </Title>
      <Subtitle data-test-id="joyride-welcome-modal-subtitle">
        {t(
          `If you're new to the application, follow the purple circles for a tour through the application.`
        )}
      </Subtitle>
      <Spacer size={'30px 0 0'} />
      <ButtonWrapper>
        <Button
          variant="text-only-purple"
          fullWidth
          onClick={handleSkipEntireJoyride}
          dataTestId="joyride-welcome-modal-disable-button"
          additionalSx={{
            padding: 0,
            justifyContent: 'center',
            fontSize: '14px',
            height: 'auto',
          }}
        >
          {t('Disable tutorial')}
        </Button>
        <Button
          variant="purple"
          additionalSx={{
            width: '190px',
          }}
          onClick={handleClose}
          dataTestId="joyride-welcome-modal-accept"
        >
          {t('Close')}
        </Button>
      </ButtonWrapper>
    </Modal>
  );
};

export default JoyrideWelcomeModal;
