/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import type { ControlPosition } from 'react-map-gl';
import { useControl } from 'react-map-gl';

export let drawRef: MapboxDraw | null = null;

type DrawControlProps = ConstructorParameters<typeof MapboxDraw>[0] & {
  position?: ControlPosition;
  onCreate?: (evt: { features: GeoJSON.Feature<GeoJSON.Polygon>[] }) => void;
  onModeChange?: (evt: { mode: string }) => void;
  onUpdate?: (evt: {
    features: GeoJSON.Feature<GeoJSON.Polygon>[];
    action: string;
  }) => void;
  setDraw?: any;
  onTrash?: (evt: { features: GeoJSON.Feature<GeoJSON.Polygon>[] }) => void;
};

interface IState {
  currentVertexPosition: number;
  polygon: {
    coordinates: number[][][];
    ctx: {
      api: {
        deleteFeature: (id: string, options: { silent: boolean }) => void;
        add: (feature: GeoJSON.Feature<GeoJSON.Polygon>) => void;
        changeMode: (mode: string) => void;
        combineFeatures: (featureIds: string[]) => void;
        deleteAll: () => void;
        delete: () => void;
      };
    };
    id: string;
    properties: {};
    type: 'Polygon';
    changed: () => void;
  };
}

MapboxDraw.modes.draw_polygon.onTrash = function onTrash(state: IState) {
  if (state.currentVertexPosition <= 1) {
    this.deleteFeature(String([state.polygon.id]), { silent: true });
    this.changeMode('draw_polygon');
  } else {
    const ring = state.polygon.coordinates[0];
    if (ring) {
      state.polygon.changed();
      state.currentVertexPosition -= 1;
      ring.splice(state.currentVertexPosition, 1);
    }
  }
};

MapboxDraw.modes.draw_polygon.onMouseDown = function onClick(state: IState) {
  const numberOfPoints = state.currentVertexPosition;
  window.postMessage(
    {
      type: 'numberOfPoints',
      numberOfPoints,
    },
    '*'
  );
};

export default function DrawControl(props: DrawControlProps) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  drawRef = useControl<MapboxDraw>(
    () => new MapboxDraw(props),
    ({ map }: { map: any }) => {
      map.on('draw.create', props.onCreate);
      map.on('draw.update', props.onUpdate);
      map.on('draw.modechange', props.onModeChange);
    },
    ({ map }: { map: any }) => {
      map.off('draw.create', props.onCreate);
      map.off('draw.update', props.onUpdate);
      map.off('draw.modechange', props.onModeChange);
    },
    {
      position: props.position,
    }
  );

  return null;
}
