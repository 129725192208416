import Button from '@mui/material/Button';
import { ColorVariant } from '../../../types/Theme';
import { ButtonSX } from './style';

export interface Props {
  onClick: () => void;
  color?: ColorVariant;
  disabled?: boolean;
  text: string;
  dataTestId?: string;
  additionalSx?: any;
}

const LinkButton = ({
  onClick,
  color = 'purple',
  text,
  dataTestId,
  additionalSx,
}: Props) => {
  return (
    <Button
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      sx={{
        ...ButtonSX,
        color: `var(--${color})`,
        ...additionalSx,
      }}
      data-test-id={dataTestId}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

export default LinkButton;
