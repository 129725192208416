import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMessage } from '../../../context/Socket';

interface SocketState {
  messages: IMessage[];
}

const initialMessagesFromLs = JSON.parse(
  localStorage.getItem('messages') || '[]'
) as IMessage[];

const initialState: SocketState = {
  messages: initialMessagesFromLs,
};

export const socketSlice = createSlice({
  name: 'socket',
  initialState,
  reducers: {
    setMessages: (state, action: PayloadAction<IMessage[]>) => {
      state.messages = action.payload;
      localStorage.setItem('messages', JSON.stringify(action.payload));
    },
    updateMessage: (state, action: PayloadAction<IMessage>) => {
      const messageIndex = state.messages.findIndex(
        (message) => message.id === action.payload.id
      );
      if (messageIndex !== -1) {
        state.messages[messageIndex] = action.payload;
        localStorage.setItem('messages', JSON.stringify(state.messages));
      }
    },
    resetSocketState: () => {
      localStorage.removeItem('messages');
      return initialState;
    },
  },
});

export default socketSlice.reducer;
export const { setMessages, updateMessage } = socketSlice.actions;
