import styled from 'styled-components';

export const Title = styled.h4`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.silver};
  margin-bottom: 24px;
  font-weight: 500;
`;

export const ItemsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const ItemMap = styled.div`
  border-radius: 4px;
  width: 92px;
  height: 92px;
  margin: 10px;
  background-image: url(/assets/tiles-map.png);
  image-rendering: pixelated;
  background-size: cover;
  position: relative;
  transition: ${({ theme }) => theme.transition};

  canvas {
    position: absolute;
    top: 10px;
    left: 10px;
  }
`;

export const Item = styled.div<{ isNew?: boolean; isHovered?: boolean }>`
  display: flex;
  width: 100%;
  position: relative;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.blackIII};
  cursor: pointer;
  transition: ${({ theme }) => theme.transition};
  box-shadow: ${({ isNew, isHovered, theme }) =>
    isNew || isHovered ? `inset 0 0 0 1px ${theme.colors.green}` : 'none'};

  &:hover {
    box-shadow: ${({ theme }) => `inset 0 0 0 1px ${theme.colors.green}`};
  }
`;

export const ItemContentWrapper = styled.div`
  padding: 30px 20px;
  flex: 1;
`;

export const ItemSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ItemTitle = styled.p`
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 5px;
`;

export const ItemText = styled.p<{ isNew?: boolean }>`
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme, isNew }) =>
    isNew ? theme.colors.green : theme.colors.gray};
  transition: ${({ theme }) => theme.transition};

  sup {
    font-size: 75%;
    margin-left: 2px;
    margin-top: -4px;
  }
`;

export const MenuToggle = styled.div`
  position: relative;
  z-index: 1;
`;

export const MenuOptions = styled.div<{ isOpen?: boolean }>`
  position: absolute;
  top: 30px;
  right: 15px;
  z-index: 3;
  background-color: rgba(18, 22, 36, 0.8);
  border-radius: 10px;
  padding: 30px;
  display: flex;
  pointer-events: ${({ isOpen }) => (isOpen ? 'all' : 'none')};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  flex-direction: column;
  gap: 20px;
  transition: ${({ theme }) => theme.transition};
  backdrop-filter: blur(5px);
`;

export const MenuItem = styled.button`
  background-color: transparent;
  border: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: ${({ theme }) => theme.colors.silver};
  cursor: pointer;
  transition: ${({ theme }) => theme.transition};
  text-align: left;

  &:hover {
    opacity: 0.6;
    transform: translateX(-5px);
  }
  &:disabled {
    opacity: 0.2;
    cursor: not-allowed;

    &:hover {
      transform: none;
      opacity: 0.2;
    }
  }
`;

export const NewBadge = styled.div<{ isNew?: boolean }>`
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  color: #114a3c;
  text-transform: uppercase;
  padding: 4px 8px;
  background: ${({ theme }) => theme.colors.green};
  border-radius: 30px;
  position: absolute;
  bottom: -10px;
  right: 24px;
  transition: ${({ theme }) => theme.transition};
  opacity: ${({ isNew }) => (isNew ? 1 : 0)};
  transform: ${({ isNew }) => (isNew ? 'translateY(0)' : 'translateY(10px)')};
  pointer-events: none;
`;
