import {
  IBatchResponse,
  IExtractRegionResponse,
  IRegionRequest,
  IRegionResponse,
  IRegionUpdateRequest,
} from '../../types/API/Region';
import { setIsDrawing } from '../features/draw/draw-slice';
import { setUserTiles } from '../features/region/region-slice';
import { setMessages } from '../features/socket/socket-slice';
import { resetUIState } from '../features/ui/ui-slice';
import { RootState } from '../store';
import { baseApi } from './index';

export const regionApi = baseApi
  .enhanceEndpoints({
    addTagTypes: ['SavedRegions'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      addRegion: builder.mutation<IRegionResponse, IRegionRequest>({
        query(body) {
          return {
            url: '/regions/add',
            method: 'POST',
            body,
          };
        },
        invalidatesTags: ['SavedRegions'],
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          await queryFulfilled;
          dispatch(setIsDrawing(false));
        },
      }),
      batchAddRegions: builder.mutation<IBatchResponse, IRegionRequest[]>({
        query(body) {
          return {
            url: '/regions/add/batch',
            method: 'POST',
            body,
          };
        },
        invalidatesTags: ['SavedRegions'],
      }),
      updateRegion: builder.mutation<
        IRegionResponse,
        {
          id: string;
          body: IRegionUpdateRequest;
        }
      >({
        query({ body, id }) {
          return {
            url: `/regions/update/${id}`,
            method: 'PATCH',
            body,
          };
        },
        invalidatesTags: ['SavedRegions'],
      }),
      updateMultipleRegions: builder.mutation<
        IBatchResponse,
        {
          body: {
            regionIds: string[];
            folderId: string | null;
          };
        }
      >({
        query({ body }) {
          return {
            url: '/regions/batchUpdate',
            method: 'PATCH',
            body,
          };
        },
        invalidatesTags: ['SavedRegions'],
      }),
      removeRegion: builder.mutation<{ success: boolean }, { id: string }>({
        query({ id }) {
          return {
            url: `/regions/remove/${id}`,
            method: 'DELETE',
          };
        },
        async onQueryStarted({ id }, { queryFulfilled, dispatch, getState }) {
          await queryFulfilled;
          const newMessages = (
            getState() as RootState
          ).socketState.messages.filter(
            (message) => message?.payload?.region?.id !== id
          );
          dispatch(setMessages(newMessages));
        },
        invalidatesTags: ['SavedRegions'],
      }),
      removeMultipleRegions: builder.mutation<
        { success: boolean },
        { ids: string[] }
      >({
        query({ ids }) {
          return {
            url: '/regions/batchRemove',
            method: 'DELETE',
            body: ids,
          };
        },
        async onQueryStarted({ ids }, { queryFulfilled, dispatch, getState }) {
          await queryFulfilled;
          const newMessages = (
            getState() as RootState
          ).socketState.messages.filter((message) =>
            // go through all the ids and remove all the messages that have the same region id
            ids.every((id) => message?.payload?.region?.id !== id)
          );
          dispatch(setMessages(newMessages));
        },
        invalidatesTags: ['SavedRegions'],
      }),
      getUserRegions: builder.query<
        IRegionResponse[],
        {
          skipCallback?: boolean;
          filter?: 'unassigned' | undefined;
          sorting?: string;
          sortingDirection?: 'asc' | 'desc';
        }
      >({
        query({ filter, sorting, sortingDirection }) {
          return {
            url: '/regions/user',
            method: 'GET',
            params: {
              filter,
              sorting,
              sortingDirection,
            },
          };
        },
        providesTags: ['SavedRegions'],
        async onQueryStarted(args, { dispatch, queryFulfilled }) {
          if (args.skipCallback) return;
          const response = await queryFulfilled;
          dispatch(setUserTiles(response.data));

          if (response.data.length === 0) {
            dispatch(resetUIState());
          }
        },
      }),

      extractPolygonsFromFile: builder.mutation<
        IExtractRegionResponse,
        FormData
      >({
        query(body) {
          return {
            url: '/regions/extract-polygons',
            method: 'POST',
            body,
          };
        },
      }),
      deleteDocument: builder.mutation<
        { success: boolean },
        { regionId: string; filename: string }
      >({
        query({ regionId, filename }) {
          return {
            url: `/regions/${regionId}/delete-document/${filename}`,
            method: 'DELETE',
          };
        },
        invalidatesTags: ['SavedRegions'],
      }),
      renameDocument: builder.mutation<
        IRegionResponse,
        {
          regionId: string;
          prevName: string;
          newName: string;
        }
      >({
        query({ regionId, prevName, newName }) {
          return {
            url: `/regions/${regionId}/rename-document`,
            method: 'PATCH',
            body: { prevName, newName },
          };
        },
        invalidatesTags: ['SavedRegions'],
      }),
      uploadDocument: builder.mutation<
        IRegionResponse,
        {
          regionId: string;
          file: FormData;
        }
      >({
        query({ regionId, file }) {
          return {
            url: `/regions/${regionId}/upload-document`,
            method: 'POST',
            body: file,
          };
        },
        invalidatesTags: ['SavedRegions'],
      }),
      signDocumentUrl: builder.mutation<
        { signedUrl: string },
        { regionId: string; filename: string }
      >({
        query({ regionId, filename }) {
          return {
            url: `/regions/${regionId}/sign-document-url/${filename}`,
            method: 'POST',
          };
        },
      }),
    }),
  });

export const {
  useAddRegionMutation,
  useUpdateMultipleRegionsMutation,
  useUpdateRegionMutation,
  useRemoveRegionMutation,
  useRemoveMultipleRegionsMutation,
  useGetUserRegionsQuery,
  useExtractPolygonsFromFileMutation,
  useDeleteDocumentMutation,
  useRenameDocumentMutation,
  useUploadDocumentMutation,
  useSignDocumentUrlMutation,
} = regionApi;
