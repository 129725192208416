import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { openModal } from '../../../../redux/features/modal/modal-slice';
import { setRegionIdsToEditOrDelete } from '../../../../redux/features/region/region-slice';
import { useAppDispatch } from '../../../../redux/hooks';
import { ContentWrapper } from '../../../../styles/Global';
import Button from '../../../Common/Button';
import Icon from '../../../Common/Icon';
import Spacer from '../../../Common/Spacer';
import { FolderSelectActionsWrapper } from './style';

interface FloatingActionsBarProps {
  active: boolean;
  ids: string[];
}

const FloatingActionsBar = ({ active, ids = [] }: FloatingActionsBarProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const commonAction = useCallback(
    (action: 'delete' | 'move') => {
      // handle delete regions and move to folder modal
      dispatch(setRegionIdsToEditOrDelete(ids));
      dispatch(
        openModal(action === 'delete' ? 'deleteRegions' : 'changeRegionsFolder')
      );
    },
    [dispatch, ids]
  );

  return (
    <FolderSelectActionsWrapper active={active}>
      <ContentWrapper>
        <Button
          additionalSx={{
            width: '207px',
            marginLeft: 'auto',
            marginRight: '16px',
          }}
          variant={'purple'}
          onClick={() => commonAction('move')}
        >
          <Icon color={'blackI'} variant="FOLDER" size={24} />
          {t('Move to folder')}
        </Button>
        <Button
          additionalSx={{
            width: '207px',
          }}
          variant={'purple'}
          onClick={() => commonAction('delete')}
        >
          <Icon color={'blackI'} variant="TRASH" size={24} />
          {t('Delete')}
        </Button>
        <Spacer size={'30px 0 0'} />
      </ContentWrapper>
    </FolderSelectActionsWrapper>
  );
};

export default FloatingActionsBar;
