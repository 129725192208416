import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useGenerateApiKeyMutation } from '../../../../redux/api/authApi';
import Button from '../../../Common/Button';
import CopyPasteInput from '../../../Common/CopyPasteInput';
import Icon from '../../../Common/Icon';
import Spacer from '../../../Common/Spacer';
import { IconCircleWrapper } from '../../../Layout/AuthLayout/style';
import { AccountSectionTitle, APIKeyText } from '../style';

const AccountAPIKey = () => {
  const { t } = useTranslation();

  const [getKey, { isLoading, isSuccess, data }] = useGenerateApiKeyMutation();

  const handleGenerateApiKey = useCallback(async () => {
    await getKey(undefined);
  }, [getKey]);

  return isSuccess ? (
    <>
      <IconCircleWrapper data-test-id="account-api-key-success-icon">
        <Icon color="gray" variant="KEY" size={24} />
      </IconCircleWrapper>
      <AccountSectionTitle data-test-id="account-api-key-success-title">
        {t('New API key generated')}
      </AccountSectionTitle>
      <APIKeyText data-test-id="account-api-key-success-text">
        {t('Please copy your unique API key and save it in a safe place.')}
      </APIKeyText>
      <APIKeyText data-test-id="account-api-key-success-text-2">
        {t('You will not be able to retrieve it later.')}
      </APIKeyText>
      <Spacer size="30px 0 0" />
      <CopyPasteInput
        dataTestId="account-api-key--success-copy-input"
        width="400px"
        value={data?.apiKey || ''}
        visible={false}
        label={String(t('Click to copy'))}
      />
    </>
  ) : (
    <>
      <AccountSectionTitle>{t('API Key')}</AccountSectionTitle>
      <APIKeyText data-test-id="account-api-key-text">
        {t('Click below to generate a new API key')}
      </APIKeyText>
      <APIKeyText data-test-id="account-api-key-text2">
        {t('Please copy your unique API key and save it in a safe place.')}
      </APIKeyText>
      <APIKeyText data-test-id="account-api-key-text3">
        {t('You will not be able to retrieve it again.')}
      </APIKeyText>
      <Spacer size="30px 0 0" />
      <Button
        variant="purple"
        onClick={handleGenerateApiKey}
        disabled={isLoading}
        dataTestId="account-api-key-generate-button"
        additionalSx={{
          width: '190px',
          margin: '0 auto 44px',
        }}
      >
        {t('Generate new key')}
      </Button>
    </>
  );
};

export default AccountAPIKey;
