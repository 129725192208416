import Skeleton from '../Common/Skeleton';
import Spacer from '../Common/Spacer';
import { ContentSkeletonNavWrapper, ContentSkeletonWrapper } from './style';

export const HeaderSkeletonComponent = () => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          paddingBottom: '48px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginRight: '48px',
            gap: '8px',
          }}
        >
          <Skeleton width={41} height={17} />
          <Skeleton width={120} height={28} />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
          }}
        >
          <Skeleton width={37} height={17} />
          <Skeleton width={102} height={28} />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          marginBottom: '-32px',
          gap: '4px',
        }}
      >
        <Skeleton width={48} height={32} />
        <Spacer size="0 10px" />
        <Skeleton width={48} height={32} />
      </div>
    </>
  );
};

const SkeletonComponent = () => {
  return (
    <>
      <ContentSkeletonWrapper data-test-id="data-board-skeleton">
        <ContentSkeletonNavWrapper>
          <Skeleton
            width={120}
            height={36}
            sx={{
              marginRight: '20px',
            }}
            dataTestId="data-board-skeleton-tab-1"
          />
          <Skeleton
            width={120}
            height={36}
            sx={{
              marginRight: '20px',
            }}
            dataTestId="data-board-skeleton-tab-2"
          />
          <Skeleton
            width={120}
            height={36}
            sx={{
              marginRight: '20px',
            }}
            dataTestId="data-board-skeleton-tab-3"
          />
        </ContentSkeletonNavWrapper>
      </ContentSkeletonWrapper>
      <Skeleton
        width={440}
        height={438}
        sx={{
          marginBottom: '20px',
        }}
      />
    </>
  );
};

export default SkeletonComponent;
