import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  container: {
    display: 'flex',
    width: '100%',
    margin: '20px 0',
  },
  colors: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    transform: 'rotate(180deg)',
  },
  numbers: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    flexGrow: 1,
    marginTop: '5px',
  },
  number: {
    display: 'flex',
    width: '1%',
    height: '10px',
  },
  numbersText: {
    fontFamily: 'Manrope',
    fontSize: '11px',
    color: '#030818',
  },
});
