export const ButtonSX = {
  backgroundColor: 'transparent',
  fontSize: '14px',
  color: 'var(--purple)',
  justifyContent: 'flex-start',
  textTransform: 'none',
  marginTop: '20px',
  padding: 0,
  fontWeight: 700,

  '*': {
    display: 'none',
  },

  '&:hover': {
    backgroundColor: 'transparent',
  },
};
