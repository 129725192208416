import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useRemoveMultipleRegionsMutation } from '../../../redux/api/regionApi';
import { closeModal } from '../../../redux/features/modal/modal-slice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import Button from '../../Common/Button';
import Modal from '../../Common/Modal';
import Spacer from '../../Common/Spacer';
import { ModalSx } from '../DeleteRegionModal/style';
import { ButtonWrapper, Subtitle, Title } from '../ModalCommon.style';

const DeleteRegionsModal = () => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const [deleteRegions, { isLoading, error, reset }] =
    useRemoveMultipleRegionsMutation();
  const { regionIdsToEditOrDelete } = useAppSelector(
    (state) => state.regionState
  );

  const handleDeleteRegions = useCallback(async () => {
    const data = await deleteRegions({
      ids: regionIdsToEditOrDelete,
    });

    if (!('error' in data)) {
      toast.success(t('Regions deleted successfully'), {
        autoClose: 3000,
        toastId: 'delete-regions-toast',
      });

      window.postMessage('regionUpdated', '*');

      dispatch(closeModal());
    }
  }, [deleteRegions, regionIdsToEditOrDelete, t, dispatch]);

  useEffect(() => {
    if (error && 'data' in error) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
      toast.error((error.data as any).message, {
        toastId: 'delete-regions-modal-error',
        autoClose: 3000,
      });
      reset();
    }
  }, [error, reset]);

  return (
    <Modal
      additionalSx={ModalSx}
      withCloseIcon={false}
      disableBackdropClick
      disableEscapeKeyDown
      dataTestId="delete-regions-modal"
      modalType="deleteRegions"
    >
      <Title data-test-id="delete-regions-modal-title">
        {t('Delete Regions ({{count}})', {
          count: regionIdsToEditOrDelete.length,
        })}
      </Title>
      <Subtitle data-test-id="delete-regions-modal-subtitle">
        {t(
          `Are you sure you want to delete ({{count}}) regions? This action cannot be undone`,
          {
            count: regionIdsToEditOrDelete.length,
          }
        )}
      </Subtitle>
      <Spacer size={'30px 0 0'} />
      <ButtonWrapper>
        <Button
          variant="text-only-purple"
          fullWidth
          onClick={() => dispatch(closeModal())}
          dataTestId="delete-regions-modal-cancel-button"
          additionalSx={{
            padding: 0,
            justifyContent: 'flex-start',
            fontSize: '14px',
            height: 'auto',
          }}
        >
          {t('Cancel')}
        </Button>
        <Button
          variant="red"
          additionalSx={{
            width: '190px',
          }}
          onClick={handleDeleteRegions}
          disabled={isLoading}
          dataTestId="delete-regions-modal-delete-button"
        >
          {t('Yes, Delete')}
        </Button>
      </ButtonWrapper>
    </Modal>
  );
};

export default DeleteRegionsModal;
