import { MouseEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { IItem } from '..';
import Icon from '../../../Common/Icon';
import {
  IncorrectIndicator,
  ItemIconWrapper,
  UploadItem,
  UploadItemDeleteButton,
  UploadItemFileName,
  UploadItemSection,
} from './style';

interface Props {
  region: IItem;
  onRemove: (region: IItem) => void;
  isIncorrect?: boolean;
}

const UploadItemComponent = ({ region, onRemove, isIncorrect }: Props) => {
  const { t } = useTranslation();
  const handleRemove = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      onRemove(region);
    },
    [onRemove, region]
  );

  return (
    <UploadItem>
      <UploadItemSection>
        <ItemIconWrapper isIncorrect={isIncorrect}>
          <Icon
            color={isIncorrect ? 'error' : 'green'}
            variant={isIncorrect ? 'X' : 'CHECK'}
            size={24}
          />
        </ItemIconWrapper>
        <UploadItemFileName data-test-id="upload-modal-upload-item-filename">
          {region.filename}
        </UploadItemFileName>
      </UploadItemSection>
      <UploadItemSection>
        <UploadItemDeleteButton
          onClick={handleRemove}
          data-test-id="upload-modal-upload-item-delete-button"
        >
          <Icon color={'grey'} variant={'TRASH'} size={28} />
        </UploadItemDeleteButton>
      </UploadItemSection>
      <IncorrectIndicator visible={isIncorrect}>
        {t('Missing data')}
      </IncorrectIndicator>
    </UploadItem>
  );
};

export default UploadItemComponent;
