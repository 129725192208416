import * as i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { i18nextPlugin } from 'translation-check';

void i18n
  .use(detector) // detects browser language
  .use(backend) // check for translations on public/locales/
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(i18nextPlugin)
  .init({
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    fallbackLng: 'en', // use en if detected lng is not available
    returnEmptyString: false, // with this turned off we don't need to translate english texts
    returnNull: false,
  });

export default i18n;
