import FormLabel from '@mui/material/FormLabel';
import { useTranslation } from 'react-i18next';
import { DateRangePicker } from 'rsuite';
import { EnumProjectType } from '../../../../types/API/PublicProjects';
import Icon from '../../../Common/Icon';
import Input from '../../../Common/Input';
import { LabelSx } from '../../../Common/Input/style';
import Select from '../../../Common/Select';
import { projectTypeOptions } from '../../AdminModals/CreatePublicProjectModal/MetadataStep';

import { setRegionToEditOrDelete } from '../../../../redux/features/region/region-slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import {
  DatePickerWrapper,
  TotalProjectedLabel,
  TotalProjectedWrapper,
} from '../../UploadModal/EditRegion/Details/style';

const Details = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { regionToEditOrDelete } = useAppSelector((state) => state.regionState);

  if (!regionToEditOrDelete) return null;

  return (
    <form>
      <Select
        value={regionToEditOrDelete.properties?.projectType || 'empty'}
        onChange={(value: EnumProjectType) =>
          dispatch(
            setRegionToEditOrDelete({
              ...regionToEditOrDelete,
              properties: {
                ...regionToEditOrDelete.properties,
                projectType: value,
              },
            })
          )
        }
        label={String(t('Project type (optional)'))}
        options={projectTypeOptions}
        fullWidth
        autoWidth={false}
        additionalPaperSx={{
          ul: {
            padding: 0,
          },
        }}
      />
      <Input
        value={regionToEditOrDelete.properties?.registry || ''}
        onChange={(value) =>
          dispatch(
            setRegionToEditOrDelete({
              ...regionToEditOrDelete,
              properties: {
                ...regionToEditOrDelete.properties,
                registry: value,
              },
            })
          )
        }
        label={String(t('Registry (optional)'))}
        omitPlaceholder
      />
      <Input
        value={regionToEditOrDelete.properties?.registryStandard || ''}
        onChange={(value) =>
          dispatch(
            setRegionToEditOrDelete({
              ...regionToEditOrDelete,
              properties: {
                ...regionToEditOrDelete.properties,
                registryStandard: value,
              },
            })
          )
        }
        label={String(t('Standard (optional)'))}
        omitPlaceholder
      />
      <Input
        value={regionToEditOrDelete.properties?.methodology || ''}
        onChange={(value) =>
          dispatch(
            setRegionToEditOrDelete({
              ...regionToEditOrDelete,
              properties: {
                ...regionToEditOrDelete.properties,
                methodology: value,
              },
            })
          )
        }
        label={String(t('Methodology (optional)'))}
        omitPlaceholder
      />
      <Input
        value={regionToEditOrDelete.properties?.certification || ''}
        onChange={(value) =>
          dispatch(
            setRegionToEditOrDelete({
              ...regionToEditOrDelete,
              properties: {
                ...regionToEditOrDelete.properties,
                certification: value,
              },
            })
          )
        }
        label={String(t('Certification (optional)'))}
        omitPlaceholder
      />
      <Input
        value={
          regionToEditOrDelete.properties?.sustainableDevelopmentGoals || ''
        }
        onChange={(value) =>
          dispatch(
            setRegionToEditOrDelete({
              ...regionToEditOrDelete,
              properties: {
                ...regionToEditOrDelete.properties,
                sustainableDevelopmentGoals: value,
              },
            })
          )
        }
        label={String(t('Sustainable Development Goals (optional)'))}
        omitPlaceholder
      />
      <DatePickerWrapper>
        <FormLabel htmlFor={'creditingPeriod'} sx={LabelSx}>
          {t('Crediting Period (optional)')}
        </FormLabel>
        <Icon color={'gray'} variant={'CALENDAR'} size={24} />
        <DateRangePicker
          id={'creditingPeriod'}
          name={'creditingPeriod'}
          placeholder={t('Select...')}
          character={'  -  '}
          placement={'topStart'}
          ranges={[
            {
              label: t('Reset'),
              value: [new Date(-1), new Date(1)],
            },
          ]}
          value={
            regionToEditOrDelete.properties?.creditingPeriodStart &&
            regionToEditOrDelete.properties?.creditingPeriodEnd
              ? [
                  new Date(
                    regionToEditOrDelete.properties.creditingPeriodStart
                  ),
                  new Date(regionToEditOrDelete.properties.creditingPeriodEnd),
                ]
              : null
          }
          onOk={(value) => {
            dispatch(
              setRegionToEditOrDelete({
                ...regionToEditOrDelete,
                properties: {
                  ...regionToEditOrDelete.properties,
                  creditingPeriodStart: value[0],
                  creditingPeriodEnd: value[1],
                },
              })
            );
          }}
          onShortcutClick={() => {
            dispatch(
              setRegionToEditOrDelete({
                ...regionToEditOrDelete,
                properties: {
                  ...regionToEditOrDelete.properties,
                  creditingPeriodStart: undefined,
                  creditingPeriodEnd: undefined,
                },
              })
            );
          }}
        />
      </DatePickerWrapper>
      <TotalProjectedWrapper>
        <Input
          value={
            regionToEditOrDelete.properties?.totalProjectedEmissionReduction ||
            ''
          }
          type="number"
          withReset={false}
          onChange={(value) =>
            dispatch(
              setRegionToEditOrDelete({
                ...regionToEditOrDelete,
                properties: {
                  ...regionToEditOrDelete.properties,
                  totalProjectedEmissionReduction: value
                    ? Number(value)
                    : undefined,
                },
              })
            )
          }
          label={String(t('Total Projected Emission Reductions (optional)'))}
          omitPlaceholder
        />
        <TotalProjectedLabel>
          CO<small>2</small>
        </TotalProjectedLabel>
      </TotalProjectedWrapper>
    </form>
  );
};

export default Details;
