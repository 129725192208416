import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropZone } from '../../../hooks/useDropZone/useDropZone';
import {
  FileInfoSubtitle,
  FileInfoTitle,
  FileInfoWrapper,
  FileResetButton,
} from '../AdminModals/CreatePublicProjectModal/style';

const useDocumentUpload = () => {
  const { t } = useTranslation();
  const prepareFormData = useCallback((files: FileList) => {
    const data = new FormData();
    data.append('file', files[0]);
    return data;
  }, []);

  const {
    renderUploadSection,
    formData,
    setFormData,
    filename,
    setFilename,
    inputRef,
  } = useDropZone({
    prepareData: prepareFormData,
    accept: '.pdf,.docx,.png,.jpg,.jpeg',
  });

  const renderContent = () =>
    filename ? (
      <FileInfoWrapper>
        <FileResetButton
          onClick={() => {
            if (inputRef.current) inputRef.current.value = '';
            setFilename('');
          }}
        >
          {t('Reset')}
        </FileResetButton>
        <FileInfoTitle>{filename.split('.')[0]}</FileInfoTitle>
        <FileInfoSubtitle>
          {t('File name:')} {filename.toLocaleLowerCase()}
        </FileInfoSubtitle>
      </FileInfoWrapper>
    ) : (
      renderUploadSection()
    );

  return {
    renderUploadStep: renderContent,
    formData,
    setFormData,
    filename,
    setFilename,
    fileInputRef: inputRef,
  };
};

export default useDocumentUpload;
