import { useCallback, useState } from 'react';
import { PortfolioSortTypes } from '../components/Portfolio/types';

export const useSorting = (
  initialSorting: string,
  initialDirection: 'asc' | 'desc'
) => {
  const [sorting, setSorting] = useState(initialSorting);
  const [sortingDirection, setSortingDirection] = useState(initialDirection);

  const handleSorting = useCallback(
    (field: string) => {
      if (sorting === field) {
        setSortingDirection(sortingDirection === 'asc' ? 'desc' : 'asc');
      } else {
        setSorting(field);
        setSortingDirection('asc');
      }
    },
    [sorting, sortingDirection]
  );

  const handlePortfolioSorting = useCallback((field: string) => {
    if (field === PortfolioSortTypes.ALPHABETICAL) {
      setSorting('name');
      setSortingDirection('asc');
    } else if (field === PortfolioSortTypes.RECENT) {
      setSorting('createdAt');
      setSortingDirection('desc');
    } else if (field === PortfolioSortTypes.OLDEST) {
      setSorting('createdAt');
      setSortingDirection('asc');
    }
  }, []);

  return { sorting, sortingDirection, handleSorting, handlePortfolioSorting };
};
