import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLogoutMutation } from '../../../redux/api/authApi';
import { Props as ModalProps } from '../../../types/Modal';
import Button from '../../Common/Button';
import Icon from '../../Common/Icon';
import Message from '../../Common/Message';
import Modal from '../../Common/Modal';
import Spacer from '../../Common/Spacer';
import {
  EmailConfirmationMessage,
  MessageButtonSx,
  MessageModalSx,
  SideBySideWrapper,
} from '../AccountModal/Details/style';

interface Props extends ModalProps {
  onSubmit: () => Promise<void>;
  isEmailChanged: boolean;
  loading: boolean;
}

const EmailChangeModal = ({
  onSubmit,
  isEmailChanged,
  loading,
  open,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const [logout, { isLoading }] = useLogoutMutation();

  const handleLogout = useCallback(async () => {
    await logout({ isAdmin: false });
  }, [logout]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={String(t('Change Email Address'))}
      titleSize="small"
      titlePosition="center"
      withCloseIcon={false}
      additionalSx={MessageModalSx}
      dataTestId="email-change-modal"
    >
      {isEmailChanged ? (
        <>
          <Spacer size="20px 0 0" />
          <Message
            text={String(t('Email changed'))}
            dataTestId="email-change-modal-message-success"
            details={String(
              t(
                'You will be logged out and need to log in with your new email.'
              )
            )}
          >
            <Icon variant="TICK_CIRCLE" color="green" size={28} />
          </Message>
          <Spacer size="20px 0 0" />
          <Button
            fullWidth
            onClick={handleLogout}
            variant="outline-purple"
            dataTestId="email-change-modal-done-button"
            disabled={isLoading}
          >
            {t('Done')}
          </Button>
        </>
      ) : (
        <>
          <EmailConfirmationMessage data-test-id="email-change-modal-message-confirm">
            {t(
              `Are you sure you want to change your email? We'll email you to verify your new email address.`
            )}
          </EmailConfirmationMessage>
          <SideBySideWrapper>
            <Button
              fullWidth
              onClick={onClose}
              variant="text-only-purple"
              additionalSx={{
                ...MessageButtonSx,
                fontSize: '14px',
              }}
              dataTestId="email-change-modal-cancel-button"
            >
              {t('Cancel')}
            </Button>
            <Button
              fullWidth
              disabled={loading}
              onClick={onSubmit}
              variant="purple"
              additionalSx={MessageButtonSx}
              dataTestId="email-change-modal-continue-button"
            >
              {t('Continue')}
            </Button>
          </SideBySideWrapper>
        </>
      )}
    </Modal>
  );
};

export default EmailChangeModal;
