import { regexes } from '../constants/validation';

/**
 * Case-insensitive
 * @param email
 * @returns True if validation pass, false if it doesn't
 */
export const validateEmail = (email: string): boolean => {
  return regexes.email.test(String(email).toLowerCase());
};

/**
 * Checks if password matches given policy
 * @param password
 * @returns True if validation pass, false if it doesn't
 */
export const validatePassword = (password: string): boolean => {
  // at least a symbol, upper and lower case letters and a number
  return regexes.password.test(password);
};

/**
 * Checks if string is alpha characters only
 * @param value
 * @returns True if validation pass, false if it doesn't
 */
export const validateLettersOnly = (value: string): boolean => {
  return regexes.alphaOnly.test(value);
};

/**
 * Checks if string is alpha characters or spaces only
 * @param value
 */
export const validateLettersSpace = (value: string): boolean => {
  return regexes.lettersSpacesOnly.test(value);
};

export const validateLettersSpacePlus = (value: string): boolean => {
  return regexes.lettersSpacesPlusOnly.test(value);
};

/**
 * Checks if string is alpha characters or spaces only, plus certain characters (", ', -, _, +)
 * @param value
 */
export const validateLetterSpaceCertainCharacters = (
  value: string
): boolean => {
  return regexes.letterSpaceCertainChars.test(value);
};

/**
 * Checks if string is alpha characters or spaces only
 * @param value
 */
export const validateAlphaNumericSpace = (value: string): boolean => {
  return regexes.alphaNumericSpace.test(value);
};
