import styled from 'styled-components';
import { IconWrapper } from '../../../Common/Icon/style';

export const FolderActionsWrapper = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 48px;
`;

export const FolderAction = styled.button<{
  active?: boolean;
}>`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  background-color: transparent;
  border: 0;
  color: ${({ active, theme }) =>
    active ? theme.colors.green : theme.colors.silver};
  transition: ${({ theme }) => theme.transition};
  text-transform: capitalize;

  &:hover {
    color: ${({ theme }) => theme.colors.green};
    cursor: pointer;
  }

  &:hover > ${IconWrapper} {
    background-color: ${({ theme }) => theme.colors.green};
    transition: ${({ theme }) => theme.transition};
  }

  ${IconWrapper} {
    margin-right: 5px;
  }

  div:last-child {
    left: 0;
  }
`;
