import styled from 'styled-components';
import { COMMON_ELEMENT_WIDTH, InputVariantMap } from '../../../constants/UI';
import { InputVariant } from '../../../types/Theme';

export const InputWrapper = styled.div<{
  hasValue: boolean;
  hasError: boolean;
  fullWidth: boolean;
  variant: InputVariant;
  noMargin: boolean;
  width?: string;
}>`
  display: flex;
  flex-direction: column;
  width: ${({ fullWidth, width }) =>
    width ? width : fullWidth ? '100%' : COMMON_ELEMENT_WIDTH};
  .PhoneInput {
    width: ${({ fullWidth, width }) =>
      width ? width : fullWidth ? '100%' : COMMON_ELEMENT_WIDTH};
  }

  .PhoneInput input {
    background-color: ${({ variant }) =>
      InputVariantMap[variant].backgroundColor};
    color: ${({ variant }) => InputVariantMap[variant].color};
    width: ${({ fullWidth, width }) =>
      width ? width : fullWidth ? '100%' : COMMON_ELEMENT_WIDTH};
    height: 48px;
    border-radius: ${({ theme }) => theme.borderRadius};
    font-size: 15px;
    line-height: 18px;
    padding: ${({ hasValue }) => (hasValue ? '14px 36px 14px 14px' : '14px')};
    transition: ${({ theme }) => theme.transition};
    border: 1px solid
      ${({ hasError }) => (hasError ? 'var(--errorRed)' : 'transparent')};

    &::placeholder {
      color: rgba(255, 255, 255, 0.5);
      font-weight: 500;
    }

    &:focus {
      outline: none;
    }
  }

  .PhoneInputCountry {
    display: none;
  }
`;
