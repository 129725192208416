import styled from 'styled-components';

export const ReportModalSx = {
  minWidth: '100vw',
  backgroundColor: 'var(--black)',
  maxWidth: '100vw',
  height: '100vh',
  maxHeight: '100vh',
  margin: 0,
  padding: '40px 0 0',
};

export const ModalTitle = styled.h1`
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  color: ${({ theme }) => theme.colors.white};
  width: 1000px;
  margin: 0 auto;
`;
