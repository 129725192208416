import FormLabel from '@mui/material/FormLabel';
import Input from '@mui/material/Input';
import {
  ChangeEvent,
  HTMLInputTypeAttribute,
  InputHTMLAttributes,
  useCallback,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { COMMON_ELEMENT_WIDTH } from '../../../constants/UI';
import { InputVariant } from '../../../types/Theme';
import Icon from '../Icon';
import {
  ErrorMsg,
  InputRelativeContainer,
  InputSX,
  InputWrapper,
  LabelSx,
  ValueResetter,
} from './style';

export interface Props
  extends Omit<
    InputHTMLAttributes<HTMLInputElement>,
    'placeholder | toggle' | 'onChange'
  > {
  variant?: InputVariant;
  onChange?: (val: string) => void;
  id?: string;
  name?: string;
  label?: string;
  type?: HTMLInputTypeAttribute;
  required?: boolean;
  color?: never;
  size?: never;
  error?: string;
  width?: string;
  fullWidth?: boolean;
  toggle?: boolean;
  noMargin?: boolean;
  placeholder?: string;
  dataTestId?: string;
  omitPlaceholder?: boolean;
  withReset?: boolean;
}

const InputComponent = ({
  variant = 'navy',
  value,
  onChange,
  id,
  name,
  label,
  type = 'text',
  required = false,
  error,
  width = COMMON_ELEMENT_WIDTH,
  fullWidth = false,
  toggle = true,
  noMargin = false,
  placeholder,
  dataTestId,
  maxLength,
  omitPlaceholder,
  readOnly,
  withReset = true,
  ...rest
}: Props) => {
  const { t } = useTranslation();

  const SX = useMemo(
    () =>
      InputSX(
        variant,
        error && typeof value === 'string' ? value?.length > 0 : false,
        typeof value === 'string' ? value.length > 0 : true,
        fullWidth ? '100%' : width
      ),
    [variant, error, value, fullWidth, width]
  );

  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (onChange) onChange(event.currentTarget.value);
    },
    [onChange]
  );

  const handleOnReset = useCallback(() => {
    if (onChange) onChange('');
  }, [onChange]);

  return (
    <InputWrapper noMargin={noMargin}>
      {label ? (
        <FormLabel
          htmlFor={id}
          sx={LabelSx}
          data-test-id={dataTestId && `${dataTestId}-label`}
        >
          {label}
        </FormLabel>
      ) : null}
      <InputRelativeContainer width={fullWidth ? '100%' : width}>
        <Input
          value={value}
          onChange={handleOnChange}
          id={id}
          name={name}
          type={type}
          required={required}
          inputProps={{
            'data-test-id': dataTestId,
            maxLength,
            ':placeholder': {
              color: 'var(--darkGray)',
            },
          }}
          placeholder={
            !omitPlaceholder
              ? placeholder
                ? placeholder
                : required
                ? String(t('Required'))
                : String(t('Optional'))
              : undefined
          }
          fullWidth={fullWidth}
          sx={SX}
          {...rest}
        />
        {!readOnly && withReset ? (
          <ValueResetter
            onClick={handleOnReset}
            visible={typeof value === 'string' && value.length > 0}
            type="button"
            data-test-id={dataTestId && `${dataTestId}-reset`}
            tabIndex={-1}
          >
            {toggle && <Icon size={20} color="graphite" variant="X_CIRCLE" />}
          </ValueResetter>
        ) : null}
      </InputRelativeContainer>
      {error ? (
        <ErrorMsg data-test-id={dataTestId && `${dataTestId}-error`}>
          {error}
        </ErrorMsg>
      ) : null}
    </InputWrapper>
  );
};

export default InputComponent;
