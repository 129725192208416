import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { Placement } from 'react-joyride';

export type JoyrideStep = {
  target: string;
  content: string;
  title?: string;
  disableBeacon?: boolean;
  placement?: Placement;
};

interface JoyrideSlice {
  steps: JoyrideStep[];
  completedSteps?: JoyrideStep[];
  run?: boolean;
}

const initialState: JoyrideSlice = {
  steps: [],
  completedSteps: JSON.parse(
    localStorage.getItem('completedSteps') || '[]'
  ) as JoyrideStep[],
  run: false,
};

export const joyrideSlide = createSlice({
  name: 'joyride',
  initialState,
  reducers: {
    addSteps: (state, action: PayloadAction<JoyrideSlice['steps']>) => {
      if (localStorage.getItem('skipJoyride')) {
        state.run = false;
        return;
      }
      const stepSet = new Set(state.steps.map((step) => step.target));
      const filteredSteps = action.payload.filter(
        (step) => !stepSet.has(step.target)
      );
      const newSteps = [...state.steps, ...filteredSteps];
      if (newSteps.length > 0) {
        state.steps = newSteps;
        state.run = true;
      }
    },

    addToCompletedSteps: (state, action: PayloadAction<JoyrideStep>) => {
      state.completedSteps = [...(state.completedSteps || []), action.payload];
    },
    resetJoyrideState: (state) => {
      state.steps = initialState.steps;
      state.run = initialState.run;
    },
  },
});

export default joyrideSlide.reducer;
export const { addSteps, addToCompletedSteps, resetJoyrideState } =
  joyrideSlide.actions;
