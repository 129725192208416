import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    gap: '20px',
  },
  imageWrapper: {
    flexDirection: 'column',
    alignItems: 'center',
    width: 100,
  },
  image: {
    width: 100,
    height: 100,
    margin: 10,
    transform: 'scaleY(-1)',
    borderRadius: '8px',
  },
  imageText: {
    fontSize: '8px',
    fontFamily: 'Manrope',
    color: '#030818',
  },
});
