import { useTranslation } from 'react-i18next';
import ReactPDFChart from 'react-pdf-charts';
import { Line, LineChart, XAxis, YAxis } from 'recharts';
import {
  ForestCoverData,
  ForestDataType,
} from '../../../../context/Polygon/types';
import { theme } from '../../../../theme/Theme';
import { CHART_LINE_COMMON_PROPS } from '../../../Chart/common';

type GraphProps = {
  width?: number;
  height?: number;
  data: ForestCoverData[];
  type: ForestDataType;
  assignRef?: (ref: any) => void;
};

const DeforestationYoYChart = ({ width, height, data, type }: GraphProps) => {
  const { t } = useTranslation();

  return (
    <ReactPDFChart>
      <LineChart data={data} width={width} height={height}>
        <XAxis
          id="report-deforestation-yoy-axis-x-tick"
          dataKey="name"
          tick={{
            fontSize: '10px',
          }}
        />
        <YAxis
          tickFormatter={(_value: number) =>
            type !== ForestDataType.PERCENTAGE
              ? new Intl.NumberFormat('en-US', {
                  notation: 'compact',
                  maximumFractionDigits: 2,
                }).format(_value)
              : new Intl.NumberFormat('en-US', {
                  notation: 'compact',
                  maximumFractionDigits: 2,
                }).format(_value)
          }
          id="report-deforestation-yoy-axis-y-tick"
          padding={{
            top: 20,
            bottom: 20,
          }}
          tick={{
            fontSize: '10px',
          }}
        />
        <Line
          isAnimationActive={false} // Note: Essential to work
          dataKey="deforestation"
          name={String(t('Change in forest cover'))}
          stroke={theme.colors.green}
          type="monotone"
          id="report-deforestation-yoy-line"
          {...CHART_LINE_COMMON_PROPS}
        />
      </LineChart>
    </ReactPDFChart>
  );
};

export default DeforestationYoYChart;
