import { useTranslation } from 'react-i18next';
import Modal, { Props } from '../../Common/Modal';
import PasswordChangeForm from './PasswordChangeForm';

const ChangePasswordModal = ({
  onClose,
  open,
}: Pick<Props, 'onClose' | 'open'>) => {
  const { t } = useTranslation();

  return (
    <Modal
      onClose={onClose}
      open={open}
      title={String(t('Change Password'))}
      titleSize="small"
      titlePosition="center"
      withCloseIcon={false}
      dataTestId="change-password-modal"
    >
      {onClose && <PasswordChangeForm onClose={onClose} />}
    </Modal>
  );
};

export default ChangePasswordModal;
