import { MapProvider as ReactMapGLProvider } from 'react-map-gl';
import { Navigate, Outlet } from 'react-router-dom';
import Compose from '../../context/combineComponents';
import { MapProvider } from '../../context/Map';
import { ModalProvider } from '../../context/Modal';
import { PolygonProvider } from '../../context/Polygon';
import { RegionProvider } from '../../context/Region';
import { SidebarProvider } from '../../context/Sidebar';
import { SocketProvider } from '../../context/Socket';
import { getCookie } from '../../utils/helpers';

const PrivateRoutes = () => {
  const isAuthenticated = getCookie('authorized');

  if (isAuthenticated === 'true') {
    return (
      <Compose
        components={[
          ReactMapGLProvider,
          RegionProvider,
          PolygonProvider,
          MapProvider,
          SidebarProvider,
          ModalProvider,
          SocketProvider,
        ]}
      >
        <Outlet />
      </Compose>
    );
  }
  return <Navigate to="/login" />;
};

export default PrivateRoutes;
