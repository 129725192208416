import styled from 'styled-components';

export const PolicyContentBox = styled.div`
  display: grid;
  grid-template-columns: 300px 1fr;
  border-radius: inherit;
  position: relative;
`;

export const PolicySidebar = styled.div`
  display: flex;
  margin-top: -30px;
  margin-left: -30px;
  background-color: ${({ theme }) => theme.colors.blue};
  padding: 0 30px;
  border-top-left-radius: inherit;
  width: 300px;
`;

export const PolicySidebarList = styled.ol`
  display: flex;
  flex-direction: column;
  padding: 30px;
  margin: 0;
`;

export const PolicyContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 30px 30px;
  position: relative;
  max-height: calc(100vh - 243px);
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0;
  }
`;

export const PolicyGradient = styled.div`
  width: 700px;
  height: 60px;
  position: fixed;
  bottom: 0;
  right: calc((50vw - 350px) - 165px);
  background: linear-gradient(
    181.35deg,
    rgba(22, 30, 37, 0) 0,
    rgb(22, 30, 37) 100%
  );
  pointer-events: none;
`;

export const PolicySidebarListItem = styled.li<{ active?: boolean }>`
  margin-bottom: 16px;
  opacity: ${({ active }) => (active ? 1 : 0.1)};
  transition: ${({ theme }) => theme.transition};
  color: ${({ theme, active }) =>
    active ? theme.colors.purple : theme.colors.white};
  a {
    color: inherit;
    text-decoration: none;
  }
`;

export const PolicyContentItem = styled.div`
  margin-bottom: 20px;
`;

export const PolicyContentTitle = styled.h3`
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
  margin-bottom: 20px;
  font-size: 22px;
  line-height: 27px;
`;

export const PolicyContentDescription = styled.p`
  color: ${({ theme }) => theme.colors.grey};
  margin-bottom: 20px;
  font-style: italic;
  font-size: 18px;
  line-height: 22px;
`;

export const PolicyContentParagraph = styled.p`
  color: ${({ theme }) => theme.colors.lightGrey};
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 22px;
`;

export const PolicyContentProgressBar = styled.div<{ progress: number }>`
  display: flex;
  width: 700px;
  height: 3px;
  position: fixed;
  bottom: 0;
  right: calc(100vw / 2 - 350px - 165px);
  transition: ${({ theme }) => theme.transition};
  background-color: ${({ theme }) => theme.colors.darkGrey};
  overflow: hidden;

  &:after {
    content: '';
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.purple};
    position: absolute;
    left: 0;
    top: 0;
    transition: ${({ theme }) => theme.transition};
    transform: translate3d(-${({ progress }) => 100 - progress}%, 0, 0);
  }
`;
