import { MapboxGeoJSONFeature } from 'mapbox-gl';

export interface IMapContext {
  removeMapSelection: () => void;
  handleTileClick: (
    feature: GeoJSON.Feature<GeoJSON.Polygon>,
    source?: string,
    originalFeature?: MapboxGeoJSONFeature
  ) => void;
}

export const defaultState = {
  removeMapSelection: () => false,
  handleTileClick: () => null,
};
