import FormLabel from '@mui/material/FormLabel';
import Input from '@mui/material/Input';
import { ChangeEvent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { COMMON_ELEMENT_WIDTH } from '../../../constants/UI';
import Icon from '../Icon';
import { Props as InputProps } from '../Input';
import {
  ErrorMsg,
  InputRelativeContainer,
  InputSX,
  InputWrapper,
  LabelSx,
  ValueResetter,
} from '../Input/style';

export interface Props extends Omit<InputProps, 'type'> {
  minRows?: number;
  maxRows?: number;
  rows?: number;
}

const TextareaComponent = ({
  variant = 'navy',
  value,
  onChange,
  id,
  name,
  label,
  required = false,
  error,
  width = COMMON_ELEMENT_WIDTH,
  fullWidth = false,
  toggle = true,
  noMargin = false,
  dataTestId,
  omitPlaceholder,
  ...rest
}: Props) => {
  const { t } = useTranslation();

  const SX = useMemo(
    () => ({
      ...InputSX(
        variant,
        error && typeof value === 'string' ? value?.length > 0 : false,
        typeof value === 'string' ? value.length > 0 : true,
        fullWidth ? '100%' : width
      ),
      height: 'auto',
    }),
    [variant, error, value, fullWidth, width]
  );

  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (onChange) onChange(event.currentTarget.value);
    },
    [onChange]
  );

  const handleOnReset = useCallback(() => {
    if (onChange) onChange('');
  }, [onChange]);

  return (
    <InputWrapper noMargin={noMargin}>
      <FormLabel
        htmlFor={id}
        sx={LabelSx}
        data-test-id={dataTestId && `${dataTestId}-label`}
      >
        {label}
      </FormLabel>
      <InputRelativeContainer width={fullWidth ? '100%' : width}>
        <Input
          value={value}
          onChange={handleOnChange}
          id={id}
          name={name}
          required={required}
          placeholder={
            !omitPlaceholder
              ? required
                ? String(t('Required'))
                : String(t('Optional'))
              : undefined
          }
          fullWidth={fullWidth}
          sx={SX}
          multiline
          inputProps={{
            'data-test-id': dataTestId,
          }}
          {...rest}
        />
        <ValueResetter
          onClick={handleOnReset}
          visible={typeof value === 'string' ? value.length > 0 : true}
          type="button"
          data-test-id={dataTestId && `${dataTestId}-reset`}
        >
          {toggle && <Icon size={20} color="graphite" variant="X_CIRCLE" />}
        </ValueResetter>
      </InputRelativeContainer>
      {error ? (
        <ErrorMsg data-test-id={dataTestId && `${dataTestId}-error`}>
          {error}
        </ErrorMsg>
      ) : null}
    </InputWrapper>
  );
};

export default TextareaComponent;
