export const TabsSx = {
  marginBottom: '28px',
  minHeight: 44,
  height: 44,
  transition: 'var(--transition)',

  '.MuiTabs-indicator': {
    backgroundColor: 'var(--green) ',
    transition: 'var(--transition)',
    height: '4px',
  },
};

export const TabSx = {
  textTransform: 'none',
  color: 'var(--grey)',
  fontSize: '20px',
  fontWeight: 500,
  minHeight: 44,
  height: 44,
  lineHeight: '27px',
  transition: 'var(--transition)',
  paddingTop: 0,

  '&.Mui-selected': {
    color: 'var(--green) ',
  },

  '&.Mui-disabled': {
    color: 'var(--grey)',
    opacity: 0.5,
  },
};
