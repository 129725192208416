import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useMapContext } from '../../../context/Map';
import { useAddRegionMutation } from '../../../redux/api/regionApi';
import { closeModal } from '../../../redux/features/modal/modal-slice';
import { setSelectedPolygon } from '../../../redux/features/region/region-slice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { IRegionRequest } from '../../../types/API/Region';
import Button from '../../Common/Button';
import Input from '../../Common/Input';
import Modal from '../../Common/Modal';
import Spacer from '../../Common/Spacer';
import { ButtonWrapper, Title } from '../ModalCommon.style';
import { ModalSx } from './style';

const SaveRegionModal = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [name, setName] = useState('');
  const { selectedPolygon } = useAppSelector((state) => state.regionState);

  const [addRegion, { isLoading, isError, reset }] = useAddRegionMutation();
  const { splitDrawnPolygon } = useAppSelector((state) => state.drawState);
  const { removeMapSelection } = useMapContext();

  const handleNameChange = useCallback((newName: string) => {
    setName(newName);
  }, []);

  const handleOnSave = useCallback(async () => {
    if (selectedPolygon) {
      const regionData: IRegionRequest = {
        name,
        polygon: {
          type: selectedPolygon.type,
          geometry: selectedPolygon._geometry || selectedPolygon.geometry,
          properties:
            selectedPolygon.properties as IRegionRequest['polygon']['properties'],
        },
      };
      const dates = splitDrawnPolygon?.drawnDataOnTile.features
        .filter((el) => el.properties?.last_modified)
        .map((el) => new Date(String(el.properties?.last_modified)));

      if (dates) {
        regionData.dataUpdatedAt = dates.reduce((a, b) => (a > b ? a : b));
      }

      const data = await addRegion(regionData);

      if (!('error' in data)) {
        toast.success(t('Region saved successfully'), {
          autoClose: 3000,
          toastId: 'save-region-toast',
        });

        setName('');
        removeMapSelection();

        dispatch(
          setSelectedPolygon({
            ...data.data,
            omitRefetch: true,
          })
        );
        dispatch(closeModal());

        reset();
      }
    }
  }, [
    selectedPolygon,
    name,
    splitDrawnPolygon?.drawnDataOnTile.features,
    addRegion,
    t,
    removeMapSelection,
    dispatch,
    reset,
  ]);

  useEffect(() => {
    if (isError) {
      toast.error(
        t('We were unable to save the region. Please try again later'),
        {
          autoClose: 3000,
          toastId: 'save-region-error-toast',
        }
      );
      reset();
    }
  }, [isError, reset, t]);

  return (
    <Modal
      modalType="saveRegion"
      additionalSx={ModalSx}
      withCloseIcon={false}
      disableBackdropClick
      disableEscapeKeyDown
      dataTestId="save-region-modal"
    >
      <Title data-test-id="save-region-modal-title">{t('Save Region')}</Title>
      <Spacer size="30px 0 0" />
      <Input
        dataTestId="save-region-modal-name-input"
        value={name}
        required
        label={String(t('Name the region'))}
        name="region-name"
        id="region-name"
        onChange={handleNameChange}
        noMargin
        fullWidth
      />
      <Spacer size="30px 0 0" />
      <ButtonWrapper>
        <Button
          variant="text-only-purple"
          fullWidth
          onClick={() => dispatch(closeModal())}
          disabled={isLoading}
          dataTestId="save-region-modal-cancel-button"
          additionalSx={{
            justifyContent: 'flex-start',
            paddingLeft: 0,
            fontSize: '14px',
          }}
        >
          {t('Cancel')}
        </Button>
        <Button
          dataTestId="save-region-modal-save-button"
          variant="purple"
          fullWidth
          disabled={name.length === 0 || isLoading}
          onClick={handleOnSave}
        >
          {t('Save')}
        </Button>
      </ButtonWrapper>
    </Modal>
  );
};

export default SaveRegionModal;
