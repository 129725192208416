import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useRenameDocumentMutation } from '../../../redux/api/regionApi';
import { closeModal } from '../../../redux/features/modal/modal-slice';
import { setDocumentToEditOrDelete } from '../../../redux/features/region/region-slice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import Button from '../../Common/Button';
import Input from '../../Common/Input';
import Modal from '../../Common/Modal';
import Spacer from '../../Common/Spacer';
import { ButtonWrapper, Title } from '../ModalCommon.style';

const RenameDocumentModal = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { documentToEditOrDelete, selectedPolygon } = useAppSelector(
    (state) => state.regionState
  );
  const [name, setName] = useState(
    documentToEditOrDelete?.name?.split('.')?.[0] || ''
  );

  const [renameDocument, { isLoading, error, reset }] =
    useRenameDocumentMutation();

  const handleNameChange = useCallback((newName: string) => {
    setName(newName.replace(/\s/g, '_'));
  }, []);
  const handleOnSave = useCallback(async () => {
    if (!documentToEditOrDelete) return;

    const prevName = documentToEditOrDelete.name;
    const newName = `${name}.${documentToEditOrDelete.type}`;

    if (newName === prevName) {
      dispatch(closeModal());
      return;
    }

    const data = await renameDocument({
      regionId: String(selectedPolygon?.id),
      prevName,
      newName,
    });

    if (!('error' in data)) {
      toast.success(t('Document updated successfully'), {
        autoClose: 3000,
        toastId: 'save-document-toast',
      });

      window.postMessage('documentDeletedUpdated', '*');

      dispatch(closeModal());
    }
  }, [
    documentToEditOrDelete,
    name,
    selectedPolygon,
    renameDocument,
    dispatch,
    t,
  ]);

  useEffect(() => {
    if (error && 'data' in error) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
      toast.error((error.data as any).message, {
        toastId: 'rename-document-error',
        autoClose: 3000,
      });
      reset();
    }
  }, [error, reset, t]);

  if (!documentToEditOrDelete) return null;

  return (
    <Modal
      withCloseIcon={false}
      dataTestId="rename-document-modal"
      modalType="renameDocument"
      onClose={() => dispatch(setDocumentToEditOrDelete(null))}
    >
      <Title data-test-id="rename-document-modal-title">
        {t('Rename document')}
      </Title>
      <Spacer size="30px 0 0" />
      <Input
        dataTestId="rename-document-modal-name-input"
        value={name}
        required
        label={String(t('Document name'))}
        name="document-name"
        id="document-name"
        onChange={handleNameChange}
        noMargin
        fullWidth
      />
      <Spacer size="30px 0 0" />
      <ButtonWrapper>
        <Button
          variant="text-only-purple"
          fullWidth
          onClick={() => dispatch(closeModal())}
          disabled={isLoading}
          dataTestId="rename-document-modal-cancel-button"
          additionalSx={{
            justifyContent: 'center',
            paddingLeft: 0,
            fontSize: '14px',
          }}
        >
          {t('Cancel')}
        </Button>
        <Button
          dataTestId="rename-document-modal-save-button"
          variant="purple"
          fullWidth
          disabled={documentToEditOrDelete.name.length === 0 || isLoading}
          onClick={handleOnSave}
        >
          {t('Update')}
        </Button>
      </ButtonWrapper>
    </Modal>
  );
};

export default RenameDocumentModal;
