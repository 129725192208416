import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UPLOAD_MODAL_EDIT_STEPS } from '../../../../constants/joyride';
import useJoyride from '../../../../hooks/useJoyride';
import { IRegionRequest } from '../../../../types/API/Region';
import Tabs from '../../../Common/Tabs';
import Tab from '../../../Common/Tabs/Tab';
import Details from './Details';
import Overview from './Overview';
import { Wrapper } from './style';

export interface IProps {
  submitData: IRegionRequest | null;
  setSubmitData: Dispatch<SetStateAction<IRegionRequest | null>>;
}

const EditRegion = ({ submitData, setSubmitData }: IProps) => {
  const { t } = useTranslation();
  const [editRegionTab, setEditRegionTab] = useState('0');

  useJoyride(UPLOAD_MODAL_EDIT_STEPS(t));

  return (
    <Wrapper id="upload-modal-edit-step">
      <Tabs
        color="green"
        value={editRegionTab}
        setValue={setEditRegionTab}
        centered
      >
        <Tab title={t('Overview')}>
          <Overview submitData={submitData} setSubmitData={setSubmitData} />
        </Tab>
        <Tab title={t('Details')}>
          <Details submitData={submitData} setSubmitData={setSubmitData} />
        </Tab>
      </Tabs>
    </Wrapper>
  );
};

export default EditRegion;
