import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'usehooks-ts';
import { useFindUsersQuery } from '../../../../redux/api/userApi';
import { openModal } from '../../../../redux/features/modal/modal-slice';
import { setAdminUserPickedToEdit } from '../../../../redux/features/user/user-slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { IUser } from '../../../../types/User';
import Input from '../../../Common/Input';
import Modal from '../../../Common/Modal';
import Spacer from '../../../Common/Spacer';
import { Title } from '../../ModalCommon.style';
import {
  EditButton,
  InputInfo,
  InputWrapper,
  ModalSx,
  UserDetails,
  UserEmail,
  UserListItem,
  UserName,
  UsersListWrapper,
} from './style';

const FindUserModal = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { user } = useAppSelector((state) => state.userState);
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce<string>(query, 500);
  const { data } = useFindUsersQuery(
    { query: debouncedQuery, userId: user?.id || '' },
    {
      skip: debouncedQuery === '',
    }
  );

  const handleSetQuery = useCallback((val: string) => {
    setQuery(val);
  }, []);

  const handleOnClick = useCallback(
    (clickedUser: IUser) => {
      dispatch(setAdminUserPickedToEdit(clickedUser));
      dispatch(openModal('adminEditUser'));
      setQuery('');
    },
    [dispatch]
  );

  const handleOnClose = useCallback(() => {
    setQuery('');
  }, []);

  return (
    <Modal
      modalType="adminFindUser"
      onClose={handleOnClose}
      additionalSx={ModalSx}
      dataTestId="admin-find-user-modal"
    >
      <Title
        data-test-id="admin-find-user-modal-title"
        style={{ textAlign: 'left' }}
      >
        {t('Find user')}
      </Title>
      <InputWrapper>
        <Input
          value={query}
          onChange={handleSetQuery}
          dataTestId="admin-find-user-modal-input"
        />
        <Spacer size={'30px 0 0'} />
        <InputInfo data-test-id="admin-find-user-modal-info">
          {t('Find by name, surname, email or company')}
        </InputInfo>
      </InputWrapper>
      <UsersListWrapper data-test-id="admin-find-user-modal-user-list">
        {data?.map((appUser) => (
          <UserListItem
            key={appUser.id}
            onClick={() => handleOnClick(appUser)}
            data-test-id="admin-find-user-modal-user-list-item"
          >
            <UserDetails>
              <UserName data-test-id="admin-find-user-modal-user-list-item-name">{`${
                appUser.firstName || String(t('User'))
              } ${appUser.lastName || String(t('Name'))}`}</UserName>
              <UserEmail data-test-id="admin-find-user-modal-user-list-item-email">
                {appUser.email}
              </UserEmail>
            </UserDetails>
            <EditButton data-test-id="admin-find-user-modal-user-list-item-edit-button">
              {t('Edit')}
            </EditButton>
          </UserListItem>
        ))}
      </UsersListWrapper>
    </Modal>
  );
};

export default FindUserModal;
