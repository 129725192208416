import mapboxgl from 'mapbox-gl';
import styled, { css } from 'styled-components';
import { theme as appTheme } from '../../theme/Theme';

const controlsOnShrink = css`
  .mapboxgl-ctrl-bottom-right {
    right: 316px;
  }
  .style-change-container {
    right: 336px;
  }
`;
export const MapContainer = styled.div<{
  isInteractive?: boolean;
  loading?: boolean;
  shouldShrink?: boolean;
}>`
  width: 100vw;
  height: 100vh;
  z-index: ${({ isInteractive }) => (isInteractive ? '0' : '-1')};
  pointer-events: ${({ isInteractive }) => (isInteractive ? 'all' : 'none')};
  transition: ${({ theme }) => theme.transition};
  transform: translate3d(
    ${({ shouldShrink }) => (shouldShrink ? '-316px' : 0)},
    0,
    0
  );

  ${({ shouldShrink }) => (shouldShrink ? controlsOnShrink : '')};
`;

export const FillPaint: mapboxgl.FillPaint = {
  'fill-color': [
    'case',
    ['boolean', ['feature-state', 'clicked'], false],
    'rgba(179, 241, 241, 0.56)',
    ['boolean', ['feature-state', 'hover'], false],
    'rgba(135, 240, 203, 0.3)',
    'rgba(88, 223, 175, 0.2)',
  ],
};

export const LinePaint: mapboxgl.LinePaint = {
  'line-color': [
    'case',
    ['boolean', ['feature-state', 'clicked'], false],
    'rgba(199, 255, 255, 1)',
    ['boolean', ['feature-state', 'hover'], false],
    'rgba(199, 255, 255, 0.6)',
    'rgba(88, 223, 175, 0.6)',
  ],
  'line-width': [
    'case',
    ['boolean', ['feature-state', 'clicked'], false],
    3,
    2,
  ],
};

export const UserFillPaint: mapboxgl.FillPaint = {
  'fill-color': [
    'case',
    ['boolean', ['feature-state', 'clicked'], false],
    'rgba(179, 241, 241, 0.56)',
    ['boolean', ['feature-state', 'hover'], false],
    'rgba(94, 183, 233, 0.56)',
    'rgba(20, 162, 241, 0.56)',
  ],
};

export const UserLinePaint: mapboxgl.LinePaint = {
  'line-color': [
    'case',
    ['boolean', ['feature-state', 'clicked'], false],
    'rgba(199, 255, 255, 1)',
    ['boolean', ['feature-state', 'hover'], false],
    'rgba(94, 183, 233, 1)',
    'rgba(20, 162, 241, 1)',
  ],
  'line-width': [
    'case',
    ['boolean', ['feature-state', 'clicked'], false],
    3,
    2,
  ],
};

export const PublicProjectsFillPaint: mapboxgl.FillPaint = {
  'fill-color': [
    'case',
    ['boolean', ['feature-state', 'clicked'], false],
    'rgba(179, 241, 241, 0.56)',
    ['boolean', ['feature-state', 'hover'], false],
    'rgba(135, 139, 235, 0.5)',
    'rgba(135, 139, 235, 0.75)',
  ],
};

export const PublicProjectsLinePaint: mapboxgl.LinePaint = {
  'line-color': [
    'case',
    ['boolean', ['feature-state', 'clicked'], false],
    'rgba(199, 255, 255, 1)',
    ['boolean', ['feature-state', 'hover'], false],
    'rgba(183, 187, 218, 0.8)',
    'rgba(187, 187, 218, 1)',
  ],
  'line-width': [
    'case',
    ['boolean', ['feature-state', 'clicked'], false],
    3,
    2,
  ],
};

export const DrawInsideTileLinePaint: mapboxgl.LinePaint = {
  'line-color': 'rgba(199, 255, 255, 1)',
  'line-width': 3,
};

export const DrawInsideTileFillPaint: mapboxgl.FillPaint = {
  'fill-color': 'rgba(179, 241, 241, 0.56)',
};

export const DrawOutsideTileLinePaint: mapboxgl.LinePaint = {
  'line-color': appTheme.colors.red,
  'line-width': 3,
};

export const DrawOutsideTileFillPaint: mapboxgl.FillPaint = {
  'fill-color': appTheme.colors.red,
  'fill-opacity': 0.2,
};

export const ZoomButtonProps = {
  '.mapboxgl-ctrl-icon': {
    width: '40px',
    height: '40px',
    backgroundColor: 'var(--grey)',
  },
};

export const DrawStyles = [
  // ACTIVE (being drawn)
  // line stroke
  {
    id: 'gl-draw-line',
    type: 'line',
    filter: ['all', ['==', '$type', 'LineString'], ['!=', 'mode', 'static']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': appTheme.colors.white,
      'line-dasharray': [0.2, 2],
      'line-width': 2,
    },
  },
  // polygon fill
  {
    id: 'gl-draw-polygon-fill',
    type: 'fill',
    filter: ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
    paint: {
      'fill-color': appTheme.colors.white,
      'fill-outline-color': appTheme.colors.white,
      'fill-opacity': 0.2,
    },
  },
  // polygon mid points
  {
    id: 'gl-draw-polygon-midpoint',
    type: 'circle',
    filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint']],
    paint: {
      'circle-radius': 3,
      'circle-color': appTheme.colors.white,
    },
  },
  // polygon outline stroke
  // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
  {
    id: 'gl-draw-polygon-stroke-active',
    type: 'line',
    filter: ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': appTheme.colors.white,
      'line-width': 2,
    },
  },
  // vertex point halos
  {
    id: 'gl-draw-polygon-and-line-vertex-halo-active',
    type: 'circle',
    filter: [
      'all',
      ['==', 'meta', 'vertex'],
      ['==', '$type', 'Point'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 5,
      'circle-color': appTheme.colors.white,
    },
  },
  // vertex points
  {
    id: 'gl-draw-polygon-and-line-vertex-active',
    type: 'circle',
    filter: [
      'all',
      ['==', 'meta', 'vertex'],
      ['==', '$type', 'Point'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 3,
      'circle-color': appTheme.colors.white,
    },
  },
  // INACTIVE (static, already drawn)
  // line stroke
  {
    id: 'gl-draw-line-static',
    type: 'line',
    filter: ['all', ['==', '$type', 'LineString'], ['==', 'mode', 'static']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': appTheme.colors.black,
      'line-width': 3,
    },
  },
  // polygon fill
  {
    id: 'gl-draw-polygon-fill-static',
    type: 'fill',
    filter: ['all', ['==', '$type', 'Polygon'], ['==', 'mode', 'static']],
    paint: {
      'fill-color': appTheme.colors.black,
      'fill-outline-color': appTheme.colors.black,
      'fill-opacity': 0.1,
    },
  },
  // polygon outline
  {
    id: 'gl-draw-polygon-stroke-static',
    type: 'line',
    filter: ['all', ['==', '$type', 'Polygon'], ['==', 'mode', 'static']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': appTheme.colors.black,
      'line-width': 3,
    },
  },
];
