import { useTranslation } from 'react-i18next';
import {
  Area,
  AreaChart,
  Legend,
  ReferenceArea,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import { ForestDataType } from '../../../context/Polygon/types';
import { useAppSelector } from '../../../redux/hooks';
import { theme } from '../../../theme/Theme';
import { NameType, Props, ValueType } from '../../../types/Chart';
import { convertUnitToFullName } from '../../../utils/units';
import {
  CHART_AXIS_COMMON_PROPS,
  CHART_COMMON_LABEL_PROPS,
  CHART_COMMON_PROPS,
  CHART_X_AXIS_COMMON_PROPS,
  renderLegend,
  renderTooltip,
} from '../common';

const AreaChartComponent = ({ data, dataTestId }: Props) => {
  const { t } = useTranslation();

  const { user } = useAppSelector((state) => state.userState);
  const { forestDataType, calculatedArea } = useAppSelector(
    (state) => state.regionState
  );

  // custom tooltip component
  const CustomTooltip = ({
    active,
    payload,
  }: TooltipProps<ValueType, NameType>) => {
    return renderTooltip({ active, payload, user, forestDataType });
  };

  return (
    <AreaChart data={data} {...CHART_COMMON_PROPS}>
      <XAxis
        id={dataTestId && `${dataTestId}-axis-x-tick`} // for E2E testing
        {...CHART_X_AXIS_COMMON_PROPS}
        ticks={[
          data[0].name && data[0].name,
          data[data.length - 1].name && data[data.length - 1].name,
        ]}
      />
      <YAxis
        id={dataTestId && `${dataTestId}-axis-y-tick`} // for E2E testing
        label={{
          ...CHART_COMMON_LABEL_PROPS,
          value:
            forestDataType !== ForestDataType.PERCENTAGE && user?.settings.unit
              ? convertUnitToFullName(user?.settings.unit)
              : t('% forested by area'),
        }}
        padding={{
          top: 20,
          bottom: 0,
        }}
        tickFormatter={(_value: number) =>
          forestDataType !== ForestDataType.PERCENTAGE
            ? new Intl.NumberFormat('en-US', {
                notation: 'compact',
                maximumFractionDigits: 2,
              }).format(_value)
            : t('{{value, number}}', {
                value: _value,
                maximumFractionDigits: 2,
              })
        }
        {...CHART_AXIS_COMMON_PROPS}
      />
      <Tooltip content={<CustomTooltip />} />

      <ReferenceArea
        fill={theme.colors.purple}
        fillOpacity={1}
        id={dataTestId && `${dataTestId}-ref-area`} // for E2E testing
        ifOverflow="extendDomain"
        stroke={theme.colors.purple}
        y1={0}
        y2={forestDataType === ForestDataType.PERCENTAGE ? 100 : calculatedArea}
        opacity={0.5}
      />
      <Area
        dataKey="forest"
        fill={theme.colors.green}
        fillOpacity={1}
        id={dataTestId && `${dataTestId}-area`}
        name={String(t('Forested area'))}
        stroke={theme.colors.green}
        opacity={0.5}
      />
      <Legend
        content={(props) => renderLegend(props)}
        data-test-id={dataTestId && `${dataTestId}-legend`} // for E2E testing
        iconType="circle"
      />
    </AreaChart>
  );
};

export default AreaChartComponent;
