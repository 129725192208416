import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import {
  MouseEvent,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../../components/Common/Icon';
import { toggleDeforestationComponent } from '../../../redux/features/ui/ui-slice';
import { useAppDispatch } from '../../../redux/hooks';
import { IDeforestationResponse } from '../../../types/Geo';
import DeforestationImage from './DeforestationImage';
import {
  SliderButtonsWrapper,
  SliderControlButton,
  SliderCTA,
  SliderNavigationWrapper,
  SliderSlide,
  SliderSlides,
  SliderWrapper,
} from './style';

interface Props {
  visible?: boolean;
  dataTestId?: string;
  deforestationData: IDeforestationResponse;
  hideCloseButton?: boolean;
}

const Deforestation = ({
  visible,
  dataTestId,
  deforestationData,
  hideCloseButton = false,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [activeIndex, setActiveIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const sliderRef: RefObject<HTMLDivElement> = useRef(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [imageReadyStates, setImageReadyStates] = useState<boolean[]>(
    // start loading the last (more recent) image
    deforestationData.data.map(
      (_, index) => index === deforestationData.data.length - 1
    )
  );

  const isAllImagesReady = useMemo(
    () => imageReadyStates.every((el) => el),
    [imageReadyStates]
  );

  const handleOnClose = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      dispatch(toggleDeforestationComponent());
    },
    [dispatch]
  );

  const downloadImages = useCallback(async () => {
    const zip = new JSZip();
    const images = sliderRef.current?.querySelectorAll('img');

    if (images) {
      images.forEach((image, index) => {
        const rotatedCanvas = document.createElement('canvas');
        rotatedCanvas.width = image.naturalWidth;
        rotatedCanvas.height = image.naturalHeight;
        const context = rotatedCanvas.getContext('2d');
        if (context) {
          // Move the context origin to the center
          context.translate(image.naturalWidth / 2, image.naturalHeight / 2);
          context.scale(1, -1); // Flip the image vertically
          context.drawImage(
            image,
            -image.naturalWidth / 2,
            -image.naturalHeight / 2
          );

          const rotatedBlob = rotatedCanvas.toDataURL('image/png');
          zip.file(`image-${index}.png`, rotatedBlob.split(',')[1], {
            base64: true,
          });
        }
      });
      await zip.generateAsync({ type: 'blob' }).then((content) => {
        saveAs(content, 'deforestation-images.zip');
      });
    }
  }, []);

  const scrollToActiveElement = useCallback(() => {
    const container = sliderRef.current;
    if (container) {
      const activeElement = container.querySelector('.active');
      if (activeElement) {
        const containerRect = container.getBoundingClientRect();
        const activeRect = activeElement.getBoundingClientRect();
        const isFullyVisible =
          activeRect.left >= containerRect.left &&
          activeRect.right <= containerRect.right;
        if (!isFullyVisible) {
          let scrollPosition = container.scrollLeft;
          if (activeRect.left < containerRect.left) {
            scrollPosition += activeRect.left - containerRect.left;
          } else if (activeRect.right > containerRect.right) {
            scrollPosition += activeRect.right - containerRect.right;
          }
          container.scrollTo({
            left: scrollPosition,
            behavior: 'smooth',
          });
        }
      }
    }
  }, []);

  const handleNextClick = useCallback(() => {
    setIsPlaying(false);
    if (deforestationData) {
      const lastIndex = deforestationData.data.length - 1;
      setActiveIndex((prevIndex) =>
        prevIndex === lastIndex ? 0 : prevIndex + 1
      );
      scrollToActiveElement();
    }
  }, [deforestationData, scrollToActiveElement]);

  const handlePrevClick = useCallback(() => {
    setIsPlaying(false);
    if (deforestationData) {
      const lastIndex = deforestationData.data.length - 1;
      setActiveIndex((prevIndex) =>
        prevIndex === 0 ? lastIndex : prevIndex - 1
      );
      scrollToActiveElement();
    }
  }, [deforestationData, scrollToActiveElement]);

  const handleFirstClick = useCallback(() => {
    setIsPlaying(false);
    setActiveIndex(0);
  }, []);

  const handleLastClick = useCallback(() => {
    setIsPlaying(false);
    if (deforestationData) {
      setActiveIndex(deforestationData.data.length - 1);
    }
  }, [deforestationData]);

  const handleSlideClick = useCallback((index: number) => {
    setIsPlaying(false);
    setActiveIndex(index);
  }, []);

  const handlePlayClick = useCallback(() => {
    setIsPlaying((wasPlaying) => !wasPlaying);
  }, []);

  useEffect(() => {
    let intervalId: NodeJS.Timer | undefined;
    if (isPlaying && deforestationData) {
      intervalId = setInterval(() => {
        if (activeIndex < deforestationData?.data.length - 1) {
          setActiveIndex((prevIndex) => prevIndex + 1);
        } else {
          setActiveIndex(0);
        }
      }, 5000);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [isPlaying, activeIndex, deforestationData, scrollToActiveElement]);

  useEffect(() => {
    scrollToActiveElement();
  }, [activeIndex, scrollToActiveElement]);

  // initial focus set to last, more recent, image
  useEffect(() => {
    setActiveIndex(deforestationData.data.length - 1);
  }, [deforestationData, setActiveIndex]);

  const handleCanvasReady = useCallback(
    (index: number) => {
      const haveAnyFalse = imageReadyStates.some((el) => !el);
      const lastWithTrue = imageReadyStates.indexOf(true);

      if (haveAnyFalse && index === lastWithTrue) {
        if (!isUpdating) {
          setIsUpdating(true);

          setTimeout(() => {
            const newImageReadyStates = [...imageReadyStates];
            if (!newImageReadyStates[index - 1]) {
              newImageReadyStates[index - 1] = true;
              setImageReadyStates(newImageReadyStates);
            }

            setIsUpdating(false);
          }, 0);
        }
      }
    },
    [imageReadyStates, isUpdating]
  );

  const renderedSlides = useMemo(
    () =>
      deforestationData.data.map((_, index) => (
        <SliderSlide
          className={activeIndex === index ? 'active' : ''}
          timelineText={
            deforestationData
              ? new Date(deforestationData.times[index]).toLocaleDateString()
              : ''
          }
          data-test-id={dataTestId && `${dataTestId}-slide-${index}`}
          active={activeIndex === index}
          key={`Deforestation-image-${index}`}
          onClick={
            !isAllImagesReady ? undefined : () => handleSlideClick(index)
          }
          isLoading={!imageReadyStates[index]}
        >
          {imageReadyStates[index] ? (
            <DeforestationImage
              id={`Deforestation-image-${index}`}
              dataTestId={dataTestId && `${dataTestId}-image-${index}`}
              onReady={
                !isAllImagesReady ? () => handleCanvasReady(index) : undefined
              }
              data={deforestationData?.data[index]}
            />
          ) : null}
        </SliderSlide>
      )),
    [
      activeIndex,
      dataTestId,
      deforestationData,
      handleCanvasReady,
      handleSlideClick,
      imageReadyStates,
      isAllImagesReady,
    ]
  );

  return (
    <SliderWrapper active={visible} data-test-id={dataTestId}>
      <SliderSlides
        ref={sliderRef}
        onScroll={() => (!isPlaying ? setIsPlaying(false) : undefined)}
      >
        {renderedSlides}
      </SliderSlides>
      <SliderNavigationWrapper active={visible} disabled={!isAllImagesReady}>
        {!hideCloseButton ? (
          <SliderCTA onClick={handleOnClose}>
            <Icon variant="DRAW" color="white" size={18} />
            {t('Close')}
          </SliderCTA>
        ) : null}
        <SliderButtonsWrapper>
          <SliderControlButton rotateIcon onClick={handleFirstClick}>
            <Icon variant="ARROW_RIGHT_SKIP" size={13} color="white" />
          </SliderControlButton>
          <SliderControlButton rotateIcon onClick={handlePrevClick}>
            <Icon variant="ARROW_RIGHT" size={13} color="white" />
          </SliderControlButton>
          <SliderControlButton isPlay={!isPlaying} onClick={handlePlayClick}>
            <Icon
              variant={isPlaying ? 'PAUSE' : 'PLAY'}
              size={isPlaying ? 20 : 16}
              color="white"
            />
          </SliderControlButton>
          <SliderControlButton onClick={handleNextClick}>
            <Icon variant="ARROW_RIGHT" size={13} color="white" />
          </SliderControlButton>
          <SliderControlButton onClick={handleLastClick}>
            <Icon variant="ARROW_RIGHT_SKIP" size={13} color="white" />
          </SliderControlButton>
        </SliderButtonsWrapper>
        <SliderCTA onClick={downloadImages}>
          <Icon variant="DRAW" color="white" size={18} />
          {t('Download Images')}
        </SliderCTA>
      </SliderNavigationWrapper>
    </SliderWrapper>
  );
};

export default Deforestation;
