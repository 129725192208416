import { styled as muiStyled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import styled from 'styled-components';

export const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px 0 20px;
`;

export const Title = styled.h1`
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.white};
`;

export const EditUser = styled.span`
  font-weight: 700;
  font-size: 15px;
  cursor: pointer;
  line-height: 17px;
  transition: ${({ theme }) => theme.transition};
  color: ${({ theme }) => theme.colors.purple};

  &:hover {
    opacity: 0.6;
  }
`;

export const StyledTableCell = muiStyled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'var(--navy)',
    color: 'var(--purple)',
    borderBottom: 0,
    ['&:first-of-type']: {
      borderTopLeftRadius: '8px',
    },
    ['&:last-of-type']: {
      borderTopRightRadius: '8px',
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const StyledTableRow = muiStyled(TableRow, {
  shouldForwardProp: (prop) => prop !== 'isMe' && prop !== 'isActive',
})<{ isMe?: boolean; isActive?: boolean }>(({ isMe, isActive }) => {
  return {
    borderBottom: '1px solid var(--navy)',
    cursor: 'pointer',
    transition: 'all 300ms ease',
    '&:hover': {
      opacity: 0.6,
    },
    '&:first-of-type': {
      borderTop: 0,
    },
    '&:last-of-type': {
      borderBottom: 0,
    },
    '&:nth-of-type(even)': {
      backgroundColor: 'rgba(255,255,255,0.05)',
    },
    td: {
      color: isMe ? 'var(--purple)' : isActive ? 'var(--white)' : 'var(--grey)',
      border: 0,
    },
    th: {
      color: isMe ? 'var(--purple)' : isActive ? 'var(--white)' : 'var(--grey)',
      border: 0,
    },
  };
});
