import area from '@turf/area';
import { POLYGON_MAX_SQ_KM_SIZE } from '../constants/profile';
import { SQ_METER_TO_KILOMETER_RATIO } from '../types/Geo';
import { IRole } from '../types/User';

export const getCookie = (cookieName: string) => {
  const name = `${cookieName}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }

  return '';
};

export const formatTitleByQuarters = (val: string) => {
  const values = val.split('-');
  if (+values[1] === 3) {
    return `${values[0]} Q1`;
  }
  if (+values[1] === 6) {
    return `${values[0]} Q2`;
  }
  if (+values[1] === 9) {
    return `${values[0]} Q3`;
  }
  return `${values[0]} Q4`;
};

export const convertAsyncToDummyFloatingPromise = async (
  fn: Promise<any>
): Promise<void> => {
  return fn.then(() => {}).catch(() => {});
};

export const checkPolygonIntersection = (
  coordinates: GeoJSON.Position[]
): boolean => {
  const intersect = (
    A: GeoJSON.Position,
    B: GeoJSON.Position,
    C: GeoJSON.Position,
    D: GeoJSON.Position
  ): boolean => {
    const ccw = (
      E: GeoJSON.Position,
      F: GeoJSON.Position,
      G: GeoJSON.Position
    ): number => (G[1] - E[1]) * (F[0] - E[0]) - (F[1] - E[1]) * (G[0] - E[0]);

    return ccw(A, C, D) * ccw(B, C, D) < 0 && ccw(A, B, C) * ccw(A, B, D) < 0;
  };

  const numVertices = coordinates.length;
  for (let i = 0; i < numVertices - 1; i++) {
    for (let j = i + 1; j < numVertices - 1; j++) {
      if (
        intersect(
          coordinates[i],
          coordinates[i + 1],
          coordinates[j],
          coordinates[j + 1]
        )
      ) {
        return true;
      }
    }
  }

  return false;
};

export const checkRegionSize = (el: GeoJSON.Feature, role: IRole) => {
  const calculatedArea = area(el) / SQ_METER_TO_KILOMETER_RATIO;
  return calculatedArea <= POLYGON_MAX_SQ_KM_SIZE[role];
};

export const replaceTextInQuotes = (
  message: string,
  newText: string
): string => {
  const start = message.indexOf('"');
  const end = message.indexOf('"', start + 1);

  return start !== -1 && end !== -1
    ? `${message.substring(0, start + 1)}${newText}${message.substring(end)}`
    : message;
};
