import { HINT_DISMISSED_LS, MESSAGES_LS } from '../../constants/user';
import {
  IActivateBody,
  IChangePasswordBody,
  IForgotPasswordBody,
  ILoginBody,
  IResetPasswordBody,
  ISignupBody,
} from '../../types/API/Auth';
import {
  IApiKeyResponse,
  IGenericResponse,
  ISignupResponse,
  IUserResponse,
} from '../../types/API/User';
import { baseApi } from './index';
import { userApi } from './userApi';

export const authApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    register: builder.mutation<ISignupResponse, ISignupBody>({
      query(body) {
        return {
          url: '/auth/register',
          method: 'POST',
          body,
        };
      },
      async onQueryStarted(_, { queryFulfilled }) {
        const { data } = await queryFulfilled;
        localStorage.setItem(
          'apiKey',
          JSON.stringify({
            key: data.apiKey,
            userId: data.user?.id,
          })
        );
      },
    }),
    activateAccount: builder.mutation<IGenericResponse, IActivateBody>({
      query(body) {
        return {
          url: '/auth/activate',
          method: 'POST',
          body,
        };
      },
    }),
    login: builder.mutation<IUserResponse, ILoginBody>({
      query(body) {
        return {
          url: '/auth/login',
          method: 'POST',
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        await dispatch(userApi.endpoints?.getFromCookie.initiate());
      },
    }),
    refreshToken: builder.mutation<IGenericResponse, any>({
      query() {
        return {
          url: '/auth/refresh',
          method: 'POST',
        };
      },
    }),
    logout: builder.mutation<IGenericResponse, { isAdmin?: boolean }>({
      query() {
        return {
          url: '/auth/logout',
          method: 'POST',
          credentials: 'include',
        };
      },
      async onQueryStarted({ isAdmin = false }, { queryFulfilled }) {
        await queryFulfilled;
        localStorage.removeItem(HINT_DISMISSED_LS);
        localStorage.removeItem(String(MESSAGES_LS));
        window.location.href = isAdmin ? '/login/admin' : '/login';
      },
    }),
    forgotPassword: builder.mutation<IGenericResponse, IForgotPasswordBody>({
      query(body) {
        return {
          url: '/auth/forgot-password',
          method: 'POST',
          body,
        };
      },
    }),
    resetPassword: builder.mutation<IGenericResponse, IResetPasswordBody>({
      query(body) {
        return {
          url: '/auth/reset-password',
          method: 'POST',
          body,
        };
      },
    }),
    changePassword: builder.mutation<IGenericResponse, IChangePasswordBody>({
      query(body) {
        return {
          url: '/auth/change-password',
          method: 'POST',
          body,
        };
      },
    }),
    generateApiKey: builder.mutation<IApiKeyResponse, undefined>({
      query() {
        return {
          url: '/auth/api-key',
          method: 'POST',
        };
      },
    }),
  }),
});

export const {
  useRegisterMutation,
  useActivateAccountMutation,
  useLoginMutation,
  useLogoutMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useChangePasswordMutation,
  useGenerateApiKeyMutation,
} = authApi;
