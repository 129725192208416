import styled, { css } from 'styled-components';

interface IActive {
  active?: boolean;
}

export const ContentWrapper = styled.div<IActive>`
  background-color: #eff6f6;
  width: 311px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 64px;
  right: 25px;
  opacity: ${({ active }) => (active ? 1 : 0)};
  pointer-events: ${({ active }) => (active ? 'all' : 'none')};
  z-index: 9999999;
  transition: ${({ theme }) => theme.transition};
  transform: ${({ active }) =>
    active ? 'translateY(0)' : 'translateY(-10px)'};

  &:before {
    content: '';
    position: absolute;
    top: -8px;
    right: 48px;
    width: 22px;
    height: 22px;
    transform: rotate(45deg);
    background-color: #eff6f6;
    border-radius: 4px;
    z-index: -1;
  }
`;

export const ToggleWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 27px;
  right: 68px;
  cursor: pointer;
  transition: ${({ theme }) => theme.transition};

  &:hover {
    opacity: 0.6;
  }
`;

export const Section = styled.div<{ isSubscribe?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;

  ${({ isSubscribe }) => isSubscribe && 'padding: 16px 16px 8px 16px;'}
  // first and second of type
  &:not(:last-of-type) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
`;

export const CommonText = css`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.blue};
`;

export const SubscribeDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
`;

export const SubscribeTitle = styled.p`
  ${CommonText};
  margin-bottom: 8px;
  white-space: nowrap;
`;

export const SubscribeDescription = styled.p`
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.blue};
`;

export const Button = styled.button<{ isRed?: boolean }>`
  transition: ${({ theme }) => theme.transition};
  border-radius: 4px;
  width: 100%;
  background-color: transparent;
  padding: 8px;
  ${CommonText};
  text-align: left;
  cursor: pointer;
  border: 0;
  color: ${({ isRed, theme }) => (isRed ? theme.colors.red : '1F2A34')};

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;
