import styled from 'styled-components';

export const CheckboxContainer = styled.div<{
  filledColor?: string;
  unfilledColor?: string;
}>`
  margin-bottom: 5px;
  &:last-of-type {
    margin-bottom: 0;
  }

  .Mui-disabled {
    opacity: 0.5 !important;
  }
  label {
    display: flex;
    align-items: flex-start;

    .MuiCheckbox-root {
      padding: 0;
      margin: 0 16px 0 0;

      svg {
        transition: ${({ theme }) => theme.transition};
        fill: ${({ unfilledColor }) =>
          unfilledColor || 'rgba(208, 247, 255, 0.75)'} !important;
      }

      &.Mui-checked {
        svg {
          fill: ${({ theme, filledColor }) =>
            filledColor || theme.colors.green} !important;
        }
      }
    }

    .MuiFormControlLabel-label {
      font-size: 16px;
      color: ${({ theme }) => theme.colors.white};
      font-weight: 500;

      a {
        color: ${({ theme }) => theme.colors.purple};
      }

      &.Mui-disabled {
        color: ${({ theme }) => theme.colors.white} !important;
      }
    }
  }
`;
