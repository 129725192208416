import styled from 'styled-components';

export const PolicyLayoutWrapper = styled.div`
  width: 100vw;
  max-width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.black};
  overflow-x: hidden;
`;

export const PageContentWrapper = styled.div`
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const PolicyHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0 50px;
`;

export const PolicyLinks = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const PolicyLinkItem = styled.li<{ active?: boolean }>`
  &:last-of-type {
    margin-right: 0;
  }

  &:first-of-type {
    margin-left: 0;
  }

  a {
    text-decoration: none;
    margin: 0 10px;
    font-size: 15px;
    color: ${({ theme, active }) =>
      active ? theme.colors.green : theme.colors.white};
    transition: ${({ theme }) => theme.transition};
  }
`;

export const PolicyTitle = styled.h1`
  font-size: 30px;
  line-height: 36px;
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 4px;
  font-weight: 600;
  text-align: center;
`;

export const PolicyLastUpdated = styled.p`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.white};
  opacity: 0.5;
  margin-bottom: 50px;
  text-align: center;
`;

export const ContentWrapper = styled.div`
  padding: 30px 30px 0;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.navy};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  flex-grow: 1;
  border: 1px solid ${({ theme }) => theme.colors.darkGrey};
  border-bottom: 0;
`;
