import center from '@turf/center';
import { useTranslation } from 'react-i18next';
import { useMap } from 'react-map-gl';
import { INITIAL_MAP_VIEW_STATE } from '../../../constants/map';
import { setSources } from '../../../redux/features/map/map-slice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import Modal from '../../Common/Modal';
import Spacer from '../../Common/Spacer';
import PDFReport from '../../Report';
import { ModalTitle, ReportModalSx } from './style';

const ReportModal = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { mapRoot } = useMap();

  const { selectedPolygon } = useAppSelector((state) => state.regionState);

  const handleOnClose = () => {
    dispatch(setSources(['user', 'default', 'public']));

    if (selectedPolygon) {
      const centerPoint = center(selectedPolygon?.geometry);
      mapRoot?.flyTo({
        center: [
          centerPoint.geometry.coordinates[0],
          centerPoint.geometry.coordinates[1],
        ],
        zoom: INITIAL_MAP_VIEW_STATE.zoom + 2,
        duration: 0,
      });
    }
  };

  return (
    <Modal
      onClose={handleOnClose}
      modalType="pdfReport"
      additionalSx={ReportModalSx}
      dataTestId="report-modal"
    >
      <ModalTitle data-test-id="report-modal-title">{t('Report')}</ModalTitle>
      <Spacer size="20px 0 0" />
      <PDFReport />
    </Modal>
  );
};

export default ReportModal;
