import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ChangeEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMenu } from '../../../hooks/useMenu';
import { useSorting } from '../../../hooks/useSorting';
import { useGetPublicProjectsQuery } from '../../../redux/api/publicProjectsApi';
import { openModal } from '../../../redux/features/modal/modal-slice';
import { setProjectToEditOrDelete } from '../../../redux/features/public-projects/public-projects-slice';
import { useAppDispatch } from '../../../redux/hooks';
import { PublicProject } from '../../../types/API/PublicProjects';
import { downloadPDF } from '../../../utils/pdf';
import Icon from '../../Common/Icon';
import Pagination from '../../Common/Pagination';
import { MenuItem } from '../../SavedRegions/style';
import {
  DropdownContainer,
  DropdownMenu,
  DropdownToggle,
  EditUser,
  StyledTableCell,
  StyledTableRow,
  Title,
  TopWrapper,
} from './style';

const PublicProjects = () => {
  const dispatch = useAppDispatch();
  const { sorting, sortingDirection, handleSorting } = useSorting(
    'name',
    'asc'
  );
  const [page, setPage] = useState(1);
  const { t } = useTranslation();

  const {
    menuToShow: pdfMenuToShow,
    menuRef: pdfMenuRef,
    handleOpenMenu: handleOpenPDFMenu,
    handleCloseMenu: handleClosePdfMenu,
  } = useMenu();

  const {
    menuToShow: actionsMenuToShow,
    menuRef: actionsMenuRef,
    handleOpenMenu: handleOpenActionsMenu,
    handleCloseMenu: handleCloseActionsMenu,
  } = useMenu();

  const { data } = useGetPublicProjectsQuery({
    page,
    itemsPerPage: 10,
    sorting,
    sortingDirection,
  });

  const handleChangePage = useCallback(
    (_: ChangeEvent<unknown>, value: number) => {
      setPage(value);
    },
    []
  );

  const handleDownloadPDF = useCallback(
    (url: string) => {
      handleClosePdfMenu();
      downloadPDF(url);
    },
    [handleClosePdfMenu]
  );

  const handleEditProject = useCallback(
    (project: PublicProject) => {
      handleCloseActionsMenu();
      dispatch(setProjectToEditOrDelete(project));
      dispatch(openModal('editPublicProject'));
    },
    [dispatch, handleCloseActionsMenu]
  );

  const handleDeleteProject = useCallback(
    (project: PublicProject) => {
      handleCloseActionsMenu();
      dispatch(setProjectToEditOrDelete(project));
      dispatch(openModal('deletePublicProject'));
    },
    [dispatch, handleCloseActionsMenu]
  );

  const handleDeleteProjectPDF = useCallback(
    (project: PublicProject) => {
      handleClosePdfMenu();
      dispatch(setProjectToEditOrDelete(project));
      dispatch(openModal('deletePublicProjectPDF'));
    },
    [dispatch, handleClosePdfMenu]
  );

  const handleEditProjectPDF = useCallback(
    (project: PublicProject) => {
      handleClosePdfMenu();
      dispatch(setProjectToEditOrDelete(project));
      dispatch(openModal('editPublicProjectPDF'));
    },
    [dispatch, handleClosePdfMenu]
  );

  return (
    <>
      <TopWrapper>
        <Title data-test-id="admin-public-projects-title">
          {t('Projects list')}
        </Title>
        <EditUser
          data-test-id="admin-public-projects-add-new"
          onClick={() => {
            dispatch(openModal('createPublicProject'));
          }}
        >
          {t('Add new project')}
        </EditUser>
      </TopWrapper>
      <TableContainer
        component={Paper}
        sx={{
          backgroundColor: 'var(--black)',
          borderRadius: '8px',
          border: '1px solid var(--blue)',
          overflow: 'visible',
        }}
      >
        <Table sx={{ minWidth: 1000 }} aria-label="Projects List">
          <TableHead>
            <TableRow>
              <StyledTableCell
                data-test-id="admin-public-projects-table-head-cell-project-name"
                itemType="th"
                isSortable
                isSortedByThis={sorting === 'name'}
                onClick={() => handleSorting('name')}
                sortingDirection={sortingDirection}
              >
                {t('Project Name')}
                <Icon color={'white'} variant={'CHEVRON_DOWN'} size={24} />
              </StyledTableCell>
              <StyledTableCell
                data-test-id="admin-public-projects-table-head-cell-continent"
                align="left"
                itemType="th"
              >
                {t('Continent')}
              </StyledTableCell>
              <StyledTableCell
                data-test-id="admin-public-projects-table-head-cell-country"
                itemType="th"
              >
                {t('Country')}
              </StyledTableCell>
              <StyledTableCell
                data-test-id="admin-public-projects-table-head-cell-biomass-data"
                align="center"
                itemType="th"
                isSortable
                isSortedByThis={sorting === 'hasBiomassData'}
                onClick={() => handleSorting('hasBiomassData')}
                sortingDirection={sortingDirection}
              >
                {t('Biomass Data')}
                <Icon
                  color={sorting === 'hasBiomassData' ? 'white' : 'purple'}
                  variant={'CHEVRON_DOWN'}
                  size={24}
                />
              </StyledTableCell>
              <StyledTableCell
                data-test-id="admin-public-projects-table-head-cell-pdf"
                align="center"
                itemType="th"
              >
                {t('PDF')}
              </StyledTableCell>
              <StyledTableCell
                data-test-id="admin-public-projects-table-head-cell-actions"
                align="center"
                itemType="th"
              >
                {t('Actions')}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.data.map((row: PublicProject, index) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell
                  data-test-id="admin-public-projects-table-body-cell-project-name"
                  style={{
                    maxWidth: '200px',
                    wordWrap: 'break-word',
                  }}
                >
                  {row.name}
                </StyledTableCell>
                <StyledTableCell
                  data-test-id="admin-public-projects-table-body-cell-continent"
                  align="left"
                >
                  {row.properties.continent}
                </StyledTableCell>
                <StyledTableCell data-test-id="admin-public-projects-table-body-cell-country">
                  {row.properties.country}
                </StyledTableCell>
                <StyledTableCell
                  data-test-id="admin-public-projects-table-body-cell-biomass-data"
                  align="center"
                  style={{
                    color: row.hasBiomassData ? 'var(--green)' : 'var(--red)',
                    fontWeight: 600,
                    textTransform: 'uppercase',
                  }}
                >
                  {row.hasBiomassData ? t('Yes') : t('No')}
                </StyledTableCell>
                <StyledTableCell
                  data-test-id="admin-public-projects-table-body-cell-pdf"
                  align="center"
                >
                  <DropdownContainer ref={pdfMenuRef}>
                    <DropdownToggle
                      onClick={(e) => handleOpenPDFMenu(e, index)}
                      data-test-id="admin-public-projects-table-body-cell-pdf-toggle"
                    >
                      <Icon
                        variant="THREE_DOTS_HORIZONTAL"
                        size={24}
                        color="white"
                      />
                    </DropdownToggle>
                    <DropdownMenu
                      position="right"
                      active={pdfMenuToShow === index}
                      data-test-id="admin-public-projects-table-body-cell-pdf-menu"
                      style={{
                        right: '25px',
                      }}
                    >
                      <MenuItem
                        data-test-id="admin-public-projects-table-body-cell-pdf-edit"
                        onClick={() => {
                          handleEditProjectPDF(row);
                        }}
                      >
                        {!row.properties.pdfUrl ? t('Add PDF') : t('Edit PDF')}
                      </MenuItem>
                      <MenuItem
                        data-test-id="admin-public-projects-table-body-cell-pdf-download"
                        onClick={() => {
                          handleDownloadPDF(row.properties.pdfUrl);
                        }}
                        disabled={!row.properties.pdfUrl}
                      >
                        {t('Download PDF')}
                      </MenuItem>
                      <MenuItem
                        data-test-id="admin-public-projects-table-body-cell-pdf-delete"
                        onClick={() => {
                          handleDeleteProjectPDF(row);
                        }}
                        disabled={!row.properties.pdfUrl}
                      >
                        {t('Delete PDF')}
                      </MenuItem>
                    </DropdownMenu>
                  </DropdownContainer>
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  data-test-id="admin-public-projects-table-body-cell-actions"
                >
                  <DropdownContainer ref={actionsMenuRef}>
                    <DropdownToggle
                      onClick={(e) => handleOpenActionsMenu(e, index)}
                      data-test-id="admin-public-projects-table-body-cell-actions-toggle"
                    >
                      <Icon
                        variant="THREE_DOTS_HORIZONTAL"
                        size={24}
                        color="white"
                      />
                    </DropdownToggle>
                    <DropdownMenu
                      position="right"
                      active={actionsMenuToShow === index}
                      data-test-id="admin-public-projects-table-body-cell-actions-menu"
                    >
                      <MenuItem
                        data-test-id="admin-public-projects-table-body-cell-project-edit"
                        onClick={() => handleEditProject(row)}
                      >
                        {t('Edit')}
                      </MenuItem>
                      <MenuItem
                        data-test-id="admin-public-projects-table-body-cell-project-delete"
                        onClick={() => handleDeleteProject(row)}
                      >
                        {t('Delete')}
                      </MenuItem>
                    </DropdownMenu>
                  </DropdownContainer>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {data?.total && Math.ceil(data.total / 10) > 1 ? (
        <Pagination
          page={page}
          count={Math.ceil(data.total / 10)}
          boundaryCount={1}
          onChange={handleChangePage}
          showFirstButton
          showLastButton
        />
      ) : null}
    </>
  );
};

export default PublicProjects;
